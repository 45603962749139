import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from 'qs';

export const MAX_FILE_SIZE_MB = 5;
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * Math.pow(1000, 2);

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

const params = () => {
  const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : ''
  return {
    auth_string: config.security_string,
    auth_crc: convertText,
    app_ver: config.app_ver,
    auth_key: auth_key
  }
}

export default class LoadsAPI {

  static async GetLoadsData(pagination) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/loads`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      throw error
    });
  }

  static async SearchLoad(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/loads/search`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...data
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async GetDictionaryData() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/common/dictionary`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status === 401) {
        console.log('error',error)
      }
    });
  }


  static async CreateLoad(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/loads`, qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async DeleteLoad(id) {
    return basicAxios.delete(`/loads/${id}`,{
      params: params()
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async GetLoad(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/loads/${id}`,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        id
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async GetLoadsCars(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/loads/${id}/car`,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        load_id: id
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async UpdateLoad(data) {
    return basicAxios.put(`/loads/${data.load.id}`, qs.stringify(data),{
      params: params()
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async GetLoadsGeo(options = {}) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    let data = {
      lat_lt: 90,
      lon_rb: 180,
      lat_rb: -90,
      lon_lt: -180,
      ...options
    }
    return basicAxios.post(`/loads/geo`,qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static GetDownloadPDF(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';
    return `${config.serverUrl}/loads/get-pdf/${id}?auth_string=${config.security_string}&auth_crc=${convertText}&app_ver=${config.app_ver}&auth_key=${auth_key}`
  }

  static async UploadPDF(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    const formdata = new FormData();
    formdata.append('filePdf', data.filePdf);
    formdata.append('fileType', data.fileType);


    return basicAxios.post(`/loads/parse-pdf`, formdata,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }


  static async SaveLoadPriority(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`${config.serverUrl}/loads/${data.id}/priority`, qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async SaveLoadNote(data) {
    return basicAxios.put(`${config.serverUrl}/loads/${data.id}/update-note`, qs.stringify(data),{
      params: params(),
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    })
  }

  static async UploadReleaseFile(loadId, file) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    const formData = new FormData();
    formData.append('fileContent', file);
    formData.append('fileName', file.name);

    return basicAxios.post(`/loads/${loadId}/release`, formData, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response;
    }).catch((error) => {
      console.error('[LoadsAPI] - UploadReleaseFile', error);
      return {};
    });
  }

  static async DeleteReleaseFile(loadId, fileName) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.delete(`/loads/${loadId}/release`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        fileName: fileName,
      }
    }).then((response) => {
      return response;
    }).catch((error) => {
      console.error('[LoadsAPI] - DeleteReleaseFile', error);
      return {};
    });
  }

  static async ListReleaseFiles(loadId) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/loads/${loadId}/releases`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response;
    }).catch((error) => {
      console.error('[LoadsAPI] - ListReleaseFiles', error);
      return {};
    });
  }

  static async OpenReleaseFile(loadId, fileName) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    console.debug('[LoadsAPI] - openReleaseFile', fileName);
    window.open(`${config.serverUrl}/loads/${loadId}/release?auth_string=${config.security_string}&auth_crc=${convertText}&app_ver=${config.app_ver}&auth_key=${auth_key}&fileName=${encodeURIComponent(fileName)}`);
  }

  static async DeleteInvite(id) {
    return basicAxios.delete(`/opportunities/${id}`,{
      params: params()
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) { // TODO: check status for 403
        console.error('[LoadsAPI] - DeleteInvite',error)
        return {}
      }
    });
  }
}