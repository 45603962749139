import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import "./style.css";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import LoadsAPI from "../../../api/loadsAPI";
import ReactPaginate from "react-paginate";
import { Button } from "reactstrap";
import Map from "../Map";
import Select from "react-select";
import TransportersAPI from "../../../api/transportersAPI";
import downloadCsv from "../../../utils/ExportCSV";
import OpportunitiesAPI from "../../../api/opportunitiesApi";
import moment from "moment";
import DocumentsAPI from "../../../api/documentsAPI";
import { withSnackbar } from "notistack";
import { showNotification } from "../../../helpers/showNotification";
import { sortByStatus } from "../../../helpers/sortByStatus";
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { showLoadCreated } from "../../../helpers/CheckSubscription";

class Loads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loads: [],
      page: 1,
      initialPage: 0,
      limit: 10,
      total: 0,
      status: "active",
      popupOpen: false,
      popupItemId: "",
      popupHasDriver: false,
      popupLoadId: "",
      delayTimer: "",
      commentMode: null,
      commentText: "",
      delayed: false,
      transporters: [],
      defaultSelectValue: { label: "All transporters", value: "" },
      itemOpen: false,
      itemId: null,
      opportunities: [],
      loading: false,
      awaitForRender: false,
      bubbleCreateLoadOpen: false,
    };
  }

  setStateLoads = (data) => {
    this.setState({ loads: data.data.data, total: data.data.data_total_count });
  };

  getOpportunities = async (load_id) => {
    this.setState({ itemOpen: true, itemId: load_id });
  };

  closeOpportunityList = () => {
    this.setState({ itemOpen: false, itemId: null });
  };

  shallItemOpen = (itemId, offersNumber, isNotConfirmed) => {
    return (this.state.itemOpen && this.state.itemId === itemId) || (/*!this.state.itemOpen && */isNotConfirmed && offersNumber > 0);
  };

  getLoads = async () => {
    const { page, limit, status } = this.state;
    const data = await LoadsAPI.GetLoadsData({ page, limit, status });

    const offerRequest = data.data.data.map((load) =>
      OpportunitiesAPI.getOffers(load.id)
    );

    const offers = await Promise.allSettled(offerRequest);

    const opportunitiesWithOffers = data.data.data.map((load, index) => {
      return {
        ...load,
        offers: (offers[index].value && offers[index].value.data).filter(offer => offer.parent_id === null),
      };
    });

    this.setState({
      loads: opportunitiesWithOffers,
      total: data.data.data_total_count,
    });
  };

  getLoadsWithFilter = async (page) => {
    const { limit, status, delayed, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(driver_id ? { driver_id } : {}),
    });
    const offerRequest = data.data.data.map((load) =>
      OpportunitiesAPI.getOffers(load.id)
    );

    const offers = await Promise.allSettled(offerRequest);

    return data.data.data.map((load, index) => {
      return {
        ...load,
        offers: (offers[index].value && offers[index].value.data).filter(offer => offer.parent_id === null),
      };
    });
  };

  async componentDidMount() {
    this.getLoads();
    const data = await TransportersAPI.GetTransportersStatWithLoad();
    const transportersStatusFilter = data.data.data.filter(
      (item) => item.status !== "invited"
    );
    const transporters = transportersStatusFilter.map((item) => {
      return { value: item.id, label: `${item.name}`, id: item.id };
    });
    transporters.unshift({ label: "All transporters", value: "" });
    this.setState(prevState => ({ 
      transporters,
      awaitForRender: true,
      bubbleCreateLoadOpen: showLoadCreated(prevState.total),
     }));
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.total !== this.state.total) {
      this.setState({ bubbleCreateLoadOpen: showLoadCreated(this.state.total) });
    }
  }

  handlePageChange = async (page) => {
    const data = await this.getLoadsWithFilter(page.selected + 1);
    this.setState({
      page: page.selected + 1,
      loads: data,
      initialPage: page.selected,
    });
  };

  searching = async (value) => {
    let { delayTimer, status } = this.state;
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value,
      status,
    };

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {
      if (value.length >= 3) {
        const data = await LoadsAPI.SearchLoad(paginationSearch);
        this.setState({
          loads: data.data.data,
          total: data.data.data_total_count,
        });
      } else {
        const data = await LoadsAPI.GetLoadsData({
          page: 1,
          limit: 10,
          status,
        });
        this.setState({
          loads: data.data.data,
          total: data.data.data_total_count,
        });
      }
    }, 1500);

    this.setState({ delayTimer });
  };

  // openPopup = (id) => {
  //   this.setState({ popupOpen: true, popupItemId: id });
  // };
  
  openPopup = (itemId, hasDriver, loadId) => {
    this.setState({ popupOpen: true, popupItemId: itemId, popupHasDriver: hasDriver, popupLoadId: loadId});
  };

  handleDelayed = async (e) => {
    const {
      target: { checked },
    } = e;
    const { limit, status, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page: 1,
      limit,
      status,
      ...(checked ? { delayed: checked } : {}),
      ...(driver_id ? { driver_id } : {}),
    });
    this.setState({ delayed: !!checked, initialPage: 0 });
    this.setStateLoads(data);
  };

  handleDriver = async (value) => {
    const { limit, status, delayed } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page: 1,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(value.id ? { driver_id: value.id } : {}),
    });
    this.setState({ driver_id: value.id || null, initialPage: 0 });
    this.setStateLoads(data);
  };

  deleteLoad = async () => {
    const { popupItemId, page, limit, status, driver_id } = this.state;
    const load = this.state.loads.find(item => item.id === popupItemId);

    console.warn("LoadsAPI.load", popupItemId, load);

    if (load.dispatcher_id || (load.driver_data && !load.driver_id)) {
      const { data: offers } = await OpportunitiesAPI.getOffer(popupItemId);
      console.warn("LoadsAPI.offers", offers);
      const offer = offers.find(item => item.driver_id || (item.dispatcher_id === load.dispatcher_id && item.confirmed === 1));
      console.warn("LoadsAPI.DeleteInvite", offer.id);
      await LoadsAPI.DeleteInvite(offer.id);
    } else {
      console.warn("LoadsAPI.DeleteLoad", { id: driver_id }, load, !load.driver_id);
      await LoadsAPI.DeleteLoad(popupItemId);
    }

    const data = await LoadsAPI.GetLoadsData({ page, limit, status });

    this.setState({
      loads: data.data.data,
      total: data.data.data_total_count,
      popupOpen: false,
      popupItemId: "",
      popupHasDriver: false,
      popupLoadId: ""
    });
  };

  handleComment = async (id) => {
    const { limit, page, commentText, status } = this.state;

    const res = await LoadsAPI.SaveLoadPriority({ id, priority: commentText });
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoadsData({ page, limit, status });
      this.setState({
        commentMode: null,
        loads: data.data.data,
        total: data.data.data_total_count,
      });
    } else {
      this.setState({ commentMode: null });
    }
  };

  deleteComment = async (id) => {
    const { limit, page, status } = this.state;

    const res = await LoadsAPI.SaveLoadPriority({ id, priority: 0 });
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoadsData({ page, limit, status });
      this.setState({
        commentMode: null,
        loads: data.data.data,
        total: data.data.data_total_count,
      });
    } else {
      this.setState({ commentMode: null });
    }
  };

  handleExportCSV = async () => {
    const { page, limit, status, delayed, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(driver_id ? { driver_id } : {}),
      to_csv: true,
    });
    downloadCsv(data.data, "exportedLoads");
  };

  confirmOffer = async (offer_id, offers) => {
    const dealerID = JSON.parse(localStorage.getItem("userInfo")).id;

    this.setState({ loads: [], loading: true });

    let currentOffer = null;

    if (offers) {
      const offerRejectRequest = offers.map((offer) => {
        console.log("offer", offer.id);
          currentOffer = offer.id === offer_id && offer;
          if (offer.author_id === dealerID) {
            return OpportunitiesAPI.deleteOffer(offer.id);
          } else if (offer.id !== offer_id) {
            return OpportunitiesAPI.rejectOfferByOwner(offer.id);
          }

        return null;
      });
      await Promise.allSettled(offerRejectRequest);
    }

    const MESSAGE = currentOffer.driver_id
      ? `${currentOffer.driver.first_name} ${currentOffer.driver.last_name}`
      : `${currentOffer.dispatcher.company.name}`;

    const FULL_MESSAGE = `You accepted ${MESSAGE} to transport load ${currentOffer.load_id}`;

    const _showNotification = (variant, message) => {
      showNotification(message, variant, this.props);
    };

    await OpportunitiesAPI.confirmOfferByOwner(
      offer_id,
      _showNotification,
      FULL_MESSAGE
    );

    await this.getLoads();
    this.setState({ loading: false, itemOpen: false });
  };

  rejectOffer = async (offer_id) => {
    this.setState({ loads: [], loading: true });
    await OpportunitiesAPI.rejectOfferByOwner(offer_id);
    await this.getLoads();
    this.setState({ loading: false, itemOpen: false });
  };

  isEnclosedTrailer = (value) => {
    if (value === null) {
      return "-";
    }

    return value === 1 ? <span>Yes</span> : <span className="warning">No</span>;
  };

  downloadFiles = (files) => {
    files.forEach((file) => {
      const hash = file.split("documents/")[1];
      DocumentsAPI.download(hash);
    });
  };

  render() {
    const {
      loads,
      limit,
      total,
      popupOpen,
      popupHasDriver,
      popupLoadId,
      transporters,
      defaultSelectValue,
      delayed,
      driver_id,
      initialPage,
      bubbleCreateLoadOpen,
      awaitForRender
    } = this.state;

    const dealerID = JSON.parse(localStorage.getItem("userInfo")).id;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={"loads"} />
            <CustomTopBar />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleCreateLoadOpen} 
              speechMode={"loadCreated"}
              closeCallBack={() => 
                this.setState({
                  bubbleTransporterOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">
                      Active Orders
                    </div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Active Orders</li>
                    </ol>
                    <div className="main-content-header-search">
                      <input
                        className="form-control"
                        type="search"
                        id="example-text-input"
                        placeholder="Search by order number, pick up date, delivery date, pick up address, origin address..."
                        maxLength="100"
                        onChange={(e) => this.searching(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="main-content-header-right">
                    <Link to="/load">
                      <button className="btn custom-btn main-content-header-btn">
                        Create Order
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="people-table">
                    <div className="people-table-header">
                      <ul className="load-table-header header-first">
                        <li>
                          <div className="people-table-header-title">
                            Active Orders
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <span className="custom-form-control-wrap">
                                  <Select
                                    className="custom-form-control-select-drivers _filters"
                                    defaultValue={defaultSelectValue}
                                    onChange={this.handleDriver}
                                    options={transporters}
                                  />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <input
                                  className={"delayed_checkbox"}
                                  defaultChecked={delayed}
                                  ref="delayed"
                                  type={"checkbox"}
                                  onChange={this.handleDelayed}
                                />{" "}
                                show delayed loads only
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className={"exportCSV"}>
                          <div type="submit" onClick={this.handleExportCSV}>
                            <img
                              className={"export-csv-image"}
                              src="assets/images/exportCSV.png"
                              alt="Download CSV"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <table>
                      <thead>
                        <tr className="table-border">
                          <th>Order number</th>
                          <th>Year, Model</th>
                          <th>Pick up date</th>
                          <th>Delivery date</th>
                          <th>Status</th>
                          <th>Driver</th>
                          <th>Price</th>
                          <th>Origin</th>
                          <th>Origin Address</th>
                          <th>Destination</th>
                          <th>Destination Address</th>
                          <th>{""}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.loading &&
                          sortByStatus(loads).map((item) => {
                            const pickUpDateDivided =
                              moment(item.pick_up_date).format("YYYY-MM-DD") ||
                              "-";
                            const deliveryDateDivided =
                              moment(item.delivery_date).format("YYYY-MM-DD") ||
                              "-";

                            const isNotConfirmed =
                              !item.dispatcher_id && !item.driver_id;

                            const renderCarInfo = () => {
                              const { cars } = item;

                              if (!item.cars || !item.cars.length) {
                                return "-";
                              }

                              return cars.map(({ year, model_title }) => (
                                <div>
                                  {year || "-"}, {model_title || "-"}
                                </div>
                              ));
                            };

                            const isPriseDiffers = (value) => {
                              if (!item.offers) {
                                return null;
                              }
                              const offerWithDiffers = item.offers.filter(
                                (offer) => {
                                  if (
                                    dealerID !== offer.author_id &&
                                    offer.confirmed !== -1
                                  ) {
                                    return offer[value] !== item[value];
                                  }
                                  return null;
                                }
                              );
                              return !!offerWithDiffers.length;
                            };

                            const offersNumber = (item.offers &&
                              item.offers.filter(
                                (offer) =>
                                  offer.confirmed !== -1 &&
                                  dealerID !== offer.author_id
                              ).length) || 0;

                            return (
                              <>
                                <tr
                                  key={item.id}
                                  className={
                                    this.shallItemOpen(item.id, offersNumber, isNotConfirmed)
                                      ? ""
                                      : "table-border"
                                  }
                                >
                                  <td style={{ padding: '14px 10px 14px 14px' }}>

                                    <div className="flex-container">
                                      <span style={ item.priority && item.priority.toLowerCase() === 'rush' ? { position: 'absolute', paddingLeft: '10px', width: '70px', wordWrap: 'normal' } : { paddingLeft: '10px' }}>{item.load_id || "-"}</span>

                                      {item.priority && item.priority.toLowerCase() === 'rush' && ( 
                                        <span style={{ paddingLeft: '10px' }}>
                                          <i className="label-text mdi mdi-flash"/>
                                        </span>
                                      )}

                                      <div className="flex-container">
                                        {isNotConfirmed && offersNumber > 0 && (
                                          <div className="car-counter">
                                            {offersNumber}
                                          </div>
                                        )}
                                        {this.shallItemOpen(item.id, offersNumber, isNotConfirmed) ? (
                                          <div
                                            className={isNotConfirmed && offersNumber > 0 ? "" : "fas fa-chevron-up action-icon"}
                                            onClick={() =>
                                              this.closeOpportunityList()
                                            }
                                          />
                                        ) : (
                                          <div
                                            className={isNotConfirmed && offersNumber > 0 ? "" : "fas fa-chevron-down action-icon"}
                                            onClick={() =>
                                              this.getOpportunities(item.id)
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{renderCarInfo()}</td>
                                  <td
                                    className={
                                      isPriseDiffers("pick_up_date")
                                        ? "warning"
                                        : ""
                                    }
                                  >
                                    {pickUpDateDivided}
                                  </td>
                                  <td
                                    className={
                                      isPriseDiffers("delivery_date")
                                        ? "warning"
                                        : ""
                                    }
                                  >
                                    {deliveryDateDivided}
                                  </td>
                                  <td style={{ minWidth: '20px' }}>
                                    {item.driver_id === null &&
                                      item.dispatcher_id && (
                                        <div className="people-table-status-dispatched">
                                          Dispatched
                                        </div>
                                      )}
                                    {item.driver_id === null &&
                                      !item.dispatcher_id && (
                                        <div className="people-table-status-active">
                                          New
                                        </div>
                                      )}
                                    {item.driver_id !== null &&
                                      item.origin_sign_date === null && (
                                        <div className="people-table-status-alert">
                                          Assigned
                                        </div>
                                      )}
                                    {item.origin_sign_date !== null && (
                                      <div className="people-table-status-picked">
                                        Picked
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {item.driver_data
                                      ? `${item.driver_data.first_name} ${item.driver_data.last_name}`
                                      : "-"}
                                  </td>
                                  <td
                                    className={
                                      isPriseDiffers("hauling") ? "warning" : ""
                                    }
                                  >
                                    ${item.hauling || "-"}
                                  </td>
                                  <td>{item.origin_data.name || "-"}</td>
                                  <td>{item.origin_data.address}</td>
                                  <td>{item.destination_data.name || "-"}</td>
                                  <td>{item.destination_data.address}</td>
                                  <td>
                                    <div className="people-table-btn-group">
                                      <Link
                                        to={`/load/${item.id}/view`}
                                        className="people-table-btn-group-icon fas fa-eye"
                                        title="View"
                                      />
                                      {item.origin_sign_date ||
                                      (item.driver_id !== null &&
                                        item.origin_sign_date === null) ? (
                                        <div
                                          className="people-table-btn-group-icon far fa-edit people-table-btn-edit-lock"
                                          title="Edit"
                                        />
                                      ) : (
                                        <Link
                                          to={`/load/${item.id}`}
                                          className="people-table-btn-group-icon far fa-edit"
                                          title="Edit"
                                        />
                                      )}
                                      {item.origin_sign_date ? (
                                        <div
                                          className={ item.dispatcher_id || (item.driver_data && !item.driver_id) ? "people-table-btn-group-icon fas fa-user-minus people-table-btn-delete-lock" : "people-table-btn-group-icon fas fa-trash people-table-btn-delete-lock" }
                                          title="Delete"
                                        />
                                      ) : (
                                        <div
                                        className={ item.dispatcher_id || (item.driver_data && !item.driver_id) ? "people-table-btn-group-icon fas fa-user-minus" : "people-table-btn-group-icon fas fa-trash" }
                                        title="Delete"
                                        onClick={() => this.openPopup(item.id, item.dispatcher_id || (item.driver_data && !item.driver_id), item.load_id)}
                                      />
                                      )}
                                      
                                      {/* </div> */}
                                    </div>
                                  </td>
                                </tr>

                                {this.shallItemOpen(item.id, offersNumber, isNotConfirmed) && (
                                  <tr className="order-table">
                                    <td colSpan={12}>
                                      <div className="table-head">
                                        <div style={{ maxWidth: '50px' }}>Photo</div>
                                        <div>Name</div>
                                        <div>Cell</div>
                                        <div className="current-company">
                                          Current company
                                        </div>
                                        <div>DOT</div>
                                        <div>W9 / Insurance</div>
                                        <div className="unique-flex">
                                          Number of trucks
                                        </div>
                                        <div>Transported cars</div>
                                        <div>Delayed loads %</div>
                                        <div>Enclosed trailer</div>
                                        <div>Pick up date</div>
                                        <div>Delivery date</div>
                                        <div className="unique-flex">
                                          Offered Price
                                        </div>
                                        {isNotConfirmed && (
                                          <div>Confirm/Deny</div>
                                        )}
                                      </div>
                                      {item.offers &&
                                        item.offers.map((opportunity) => {
                                          const {
                                            dispatcher,
                                            pick_up_date,
                                            delivery_date,
                                            hauling,
                                            author_id,
                                            id,
                                            confirmed,
                                            driver,
                                          } = opportunity;

                                          const pickUpDate =
                                            moment(pick_up_date).format(
                                              "YYYY-MM-DD"
                                            ) || "-";
                                          const deliveryDate =
                                            moment(delivery_date).format(
                                              "YYYY-MM-DD"
                                            ) || "-";

                                          return (
                                            dealerID !== author_id &&
                                            confirmed !== -1 && (
                                              <div
                                                key={id}
                                                className="people-table-car-info-row"
                                              >
                                                {dispatcher ? (
                                                  <>
                                                    <div style={{ maxWidth: '50px' }}>
                                                      {dispatcher.company
                                                        .photo ? (
                                                        <img
                                                          className="logo"
                                                          src={
                                                            dispatcher.company
                                                              .photo
                                                          }
                                                          alt="Nofile"
                                                        />
                                                      ) : (
                                                        <div className="no-logo" />
                                                      )}
                                                    </div>
                                                    <div>
                                                      {dispatcher.first_name ||
                                                        driver.first_name}{" "}
                                                      {dispatcher.last_name}
                                                    </div>
                                                    <div className="cell">
                                                      {dispatcher.phone || "-"}
                                                    </div>
                                                    <div className="current-company">
                                                      {dispatcher.company
                                                        .name || "-"}
                                                    </div>
                                                    <div>
                                                      {dispatcher.dot_num ||
                                                        "-"}
                                                    </div>
                                                    <div
                                                      className="download-link"
                                                      onClick={() =>
                                                        this.downloadFiles(
                                                          dispatcher.documents
                                                        )
                                                      }
                                                    >
                                                      {dispatcher.documents
                                                        .length ? (
                                                        <span>Download</span>
                                                      ) : (
                                                        "No files"
                                                      )}
                                                    </div>
                                                    <div className="unique-flex">
                                                      {dispatcher.company
                                                        .drivers_have || 0}
                                                    </div>
                                                    <div>
                                                      {
                                                        dispatcher.company
                                                          .transported_cars
                                                      }
                                                    </div>
                                                    <div>
                                                      {
                                                        dispatcher.company
                                                          .delayed_loads
                                                      }
                                                      %
                                                    </div>
                                                    <div>
                                                      {this.isEnclosedTrailer(
                                                        dispatcher.enclosed_trailer
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        pickUpDateDivided ===
                                                        pickUpDate
                                                          ? ""
                                                          : "warning"
                                                      }
                                                    >
                                                      {(pick_up_date &&
                                                        pickUpDate) ||
                                                        "-"}
                                                    </div>
                                                    <div
                                                      className={
                                                        deliveryDateDivided ===
                                                        deliveryDate
                                                          ? ""
                                                          : "warning"
                                                      }
                                                    >
                                                      {(delivery_date &&
                                                        deliveryDate) ||
                                                        "-"}
                                                    </div>
                                                    <div
                                                      className={
                                                        item.hauling === hauling
                                                          ? "unique-flex"
                                                          : "unique-flex warning"
                                                      }
                                                    >
                                                      ${hauling}
                                                    </div>
                                                    {isNotConfirmed && (
                                                      <div className="action-buttons-container">
                                                        <div
                                                          className="action-button"
                                                          onClick={() =>
                                                            this.confirmOffer(
                                                              id,
                                                              item.offers
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-check" />
                                                        </div>
                                                        <div
                                                          className="action-button delete-button"
                                                          onClick={() =>
                                                            this.rejectOffer(id)
                                                          }
                                                        >
                                                          <i className="fas fa-times" />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div>
                                                      <div className="logo" />
                                                    </div>
                                                    <div>
                                                      {driver.first_name}{" "}
                                                      {driver.last_name}
                                                    </div>
                                                    <div className="cell">
                                                      {driver.phone || "-"}
                                                    </div>
                                                    <div className="current-company">
                                                      {"-"}
                                                    </div>
                                                    <div>
                                                      {driver.dot_num || "-"}
                                                    </div>
                                                    <div
                                                      className="download-link"
                                                      onClick={() =>
                                                        this.downloadFiles(
                                                          driver.documents
                                                        )
                                                      }
                                                    >
                                                      {driver.documents
                                                        .length ? (
                                                        <span>Download</span>
                                                      ) : (
                                                        "No files"
                                                      )}
                                                    </div>
                                                    <div className="unique-flex">
                                                      {"-"}
                                                    </div>
                                                    <div>{"-"}</div>
                                                    <div>{"-"}</div>
                                                    <div>
                                                      {this.isEnclosedTrailer(
                                                        driver.enclosed_trailer
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        pickUpDateDivided ===
                                                        pickUpDate
                                                          ? ""
                                                          : "warning"
                                                      }
                                                    >
                                                      {(pick_up_date &&
                                                        pickUpDate) ||
                                                        "-"}
                                                    </div>
                                                    <div
                                                      className={
                                                        deliveryDateDivided ===
                                                        deliveryDate
                                                          ? ""
                                                          : "warning"
                                                      }
                                                    >
                                                      {(delivery_date &&
                                                        deliveryDate) ||
                                                        "-"}
                                                    </div>
                                                    <div className="unique-flex">
                                                      ${hauling}
                                                    </div>
                                                    {isNotConfirmed && (
                                                      <div className="action-buttons-container">
                                                        <div
                                                          className="action-button"
                                                          onClick={() =>
                                                            this.confirmOffer(
                                                              id,
                                                              item.offers
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-check" />
                                                        </div>
                                                        <div
                                                          className="action-button delete-button"
                                                          onClick={() =>
                                                            this.rejectOffer(id)
                                                          }
                                                        >
                                                          <i className="fas fa-times" />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            )
                                          );
                                        })}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </tbody>
                    </table>

                    {/*<Toaster*/}
                    {/*  isOpen={isOpenToaster}*/}
                    {/*  setIsOpenToaster={this.setIsOpenToaster}*/}
                    {/*/>*/}

                    <ReactPaginate
                      forceSelected={initialPage}
                      forcePage={initialPage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      marginPagesDisplayed={2}
                      containerClassName={"pagination custom-pagination"}
                      subContainerClassName={"pages pagination "}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item disabled"}
                      breakLinkClassName={"page-link disabled"}
                      onPageChange={this.handlePageChange}
                      pageCount={Math.ceil(total / limit)}
                    />
                  </div>

                  <div className="loads-map">
                    <div className="loads-map-header">
                      <div className="loads-map-title">Map</div>
                    </div>
                    <Map
                      driverId={driver_id}
                      delayed={delayed}
                      setIsOpenToaster={this.setIsOpenToaster}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)",
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                    { popupHasDriver ? "Edit Load" : "Delete Load"} - { popupLoadId }
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                    { popupHasDriver ? "This will remove driver and dispatcher from the load." : "This will delete the load."}
                      {/* This will remove driver from the load or completely delete
                      the load if status is NEW. <br />
                      Proceed? */}
                      <br /> Proceed?
                    </p>
                    <Button
                      className="btn btn-primary w-md custom-btn"
                      onClick={this.deleteLoad}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn w-md"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </AUX>
    );
  }
}

export default withSnackbar(Loads);
