import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from "qs";

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export const SUBSCRIPTION_PRICE = 59;
export const SUBSCRIPTION_DAYS_MONTH = 30;

export default class SubscriptionAPI {

  static async Subscribe(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/stripes/subscription`,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
      return error.response
    });
  }

  static async GetSubscribeData(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/stripes/subscription`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
      return {data: {status: 404}}
    });
  }

  static async UpdateSubscribe(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/stripes/subscription`,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
    });
  }

  static async DeleteSubscribe() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.delete(`/stripes/subscription`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
    });
  }




  static async CreateUserCard() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/stripes/card`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
    });
  }

  static async GetUserCard() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/stripes/card`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response.data.data
    }).catch((error) => {
      console.log('error', error)
    });
  }

  static async UpdateUserCard(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/stripes/card`, qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,


      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error.response.data.message
    });
  }
}
