import React, { Component } from "react";

class InfoPopup extends Component {
    render() {
        const { popupOpen, popupClose, message } = this.props;

        return (
            popupOpen && (
                <div
                    className="modal fade bs-example-modal-lg custom-modal show "
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{
                        paddingRight: "15px",
                        display: "block",
                        background: "rgba(0,0,0,0.6)",
                    }}
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                    Information
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => popupClose()}
                                >
                                    ×
                                </button>
                            </div>
                            <div className="modal-body modal-body-delete">
                                <p className="modal-body-delete-text">
                                    {message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default InfoPopup;
