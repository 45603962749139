import config from '../config';
import CryptoJS from 'crypto-js'

const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

const downloadFile = (url) => {
  const iframe = document.createElement("iframe");
  iframe.src = url;
  iframe.style.display = "none";
  document.body.appendChild(iframe);
}

export default class DocumentsAPI {
  static download(hash) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : ''
    const url = `${config.serverUrl}/documents/download/${hash}?&auth_string=${config.security_string}&auth_crc=${convertText}&app_ver=${config.app_ver}&auth_key=${auth_key}`
    downloadFile(url)
  }
}
