import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import uuidv4 from "uuid";
import CarTypes from '../../../utils/carTypes'
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import LoadAPI from "../../../api/loadsAPI";
import CarAPI from "../../../api/carsAPI";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, Tooltip} from "reactstrap";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import TransportersAPI from "../../../api/transportersAPI";
import ClientsAPI from "../../../api/clientsAPI";
import MapAPI from "../../../api/mapAPI";
import UsersAPI from "../../../api/userApi";
import PopupSubscription from "../PopupSubscription/PopupSubscription";
import {checkSubscription} from "../../../helpers/CheckSubscription";
import ReleaseFiles from '../ReleaseFiles/ReleaseFiles';
import { MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from "../../../api/loadsAPI";
import VehicleAPI from "../../../api/vehicleAPI"
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { showCreateLoad } from '../../../helpers/CheckSubscription';

class Load extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      carsForm: false,
      error: false,
      carsFields: {
        makerOption: '',
        modelOption: '',
        carTypesOption: '',
        vin: '',
        mileage: '',
        year: '',
        keys: '',
        type: '',
        is_inop: 0,
      },
      carsSelectOptions: [],
      modelsSelectOptions: [],
      carTypesSelectOptions: [],
      pick_up_date: '',
      delivery_date: '',
      myDrivers: [],
      selectedDriver: '',
      originOption: {},
      originSelectOptions: [],

      originSelectedValue: '',
      destinationSelectedValue: '',

      noteDispatch: '',
      noteRelease: '',
      note: '',

      optionsSelect: [],
      values: [],
      key: "",
      enclose: 0,
      isPublic: 1,
      isLoading: false,
      delayTimer: null,
      validateErrorFields: {
        pick_up_date: false,
        delivery_date: false,
        origin: false,
        destination: false,
      },
      carValidateErrorFields: {
        carMaker: false,
        model: false,
        vin: false,
        mileage: false,
        year: false,
        keys: false,
      },
      selectedCar: null,
      tooltip_top: false,

      loading: false,
      errorPDF: '',
      resultPDF: {},

      selectedBillingType: '1',

      carsFormError: false,

      createLoadBtnSpinner: false,

      defaultModelsSelectOptions: [],
      defaultCarsSelectOptions: [],

      pdfWarningMessage: false,
      // note: '',
      isOpenSubscriptionModal: false,
      attachedFiles: [],
      releaseFileError: null,
      isRush: null,
      awaitForRender: false,
      popupOpen: false,
      bubbleUploadPdfOpen: false,
      bubbleAdjustDatesOpen: false,
      bubbleDesirableRateOpen: false,
      bubbleAddVehicleOpen: false,
      bubbleAttachExtrasOpen: false,
      bubbleCorrectAddressOpen: false,
      bubbleAddPaymentTermsOpen: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    const isSubscription = await checkSubscription();
    const {match} = this.props;
    let originSelectedValue = '';

    if(match.params.id) {
      const clientData = await ClientsAPI.GetClient(match.params.id);

      originSelectedValue = {
        data: clientData.data.data,
        id: clientData.data.data.id,
        label: clientData.data.data.name,
        value: clientData.data.data.id,
      }

      console.log('>>> originSelectedValue: ', originSelectedValue)
    }

    const carsData = await CarAPI.GetCarsData();
    const myDrivers = await TransportersAPI.GetMyTransportersData()
    const originData = await ClientsAPI.GetClientsData();
    const modelsData = await CarAPI.GetModelData();

    const modelsSelectOptions = modelsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id, car_maker: item.car_maker_id}
    })

    const carsSelectOptions = carsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id,}
    })

    const optionsSelect = originData.data.data.map((item) => {
      return {value: item.id, label: `${item.name}`, id: item.id, data: item}
    });


    const userData = await UsersAPI.GetLicense()

    let transporters_active = ''
    let transporters_total = ''

    if (userData) {
      // transporters_active = userData.data.data.current_active
      // transporters_total = userData.data.data.current_amount

      transporters_active = userData.data.data ? userData.data.data.current_active : 0
      transporters_total = userData.data.data ? userData.data.data.current_amount : 0
    }

    const isShowCreateLoad = showCreateLoad();

    this.setState({
      myDrivers: myDrivers.data.data,
      defaultOptionsSelect: optionsSelect,
      carTypesSelectOptions: CarTypes,
      originSelectedValue,
      modelsSelectOptions,
      carsSelectOptions,

      defaultModelsSelectOptions: modelsSelectOptions,
      defaultCarsSelectOptions: carsSelectOptions,

      transporters_active,
      transporters_total,
      isOpenSubscriptionModal: !isSubscription,
      awaitForRender: true,
      popupOpen: false,
      bubbleUploadPdfOpen: isShowCreateLoad,
      bubbleAdjustDatesOpen: isShowCreateLoad,
      bubbleDesirableRateOpen: isShowCreateLoad,
      bubbleAddVehicleOpen: isShowCreateLoad,
      bubbleAttachExtrasOpen: isShowCreateLoad,
      bubbleCorrectAddressOpen: isShowCreateLoad,
      bubbleAddPaymentTermsOpen: isShowCreateLoad,
    })
  }

  checkAlreadyCreatedCar = () => {

    const checkDublicate = (arr)=> {
      const set = new Set();
      arr.forEach(item => {
        set.add(item.vin)
      })
      return set.size === arr.length
    }

    const {cars} = this.state;
    const alreadyCreatedCarErrors = []

    cars.forEach((item) => {
      const errorsState = {}
      item.makerOption.id !== null ? errorsState.carMaker = false : errorsState.carMaker = true
      item.modelOption.id !== null ? errorsState.model = false : errorsState.model = true
      const checkVin = checkDublicate(cars)
      item.vin && item.vin.length >= 8 && checkVin ? errorsState.vin = false : errorsState.vin = true
      // item.mileage ? errorsState.mileage = false : errorsState.mileage = true
      // item.year ? errorsState.year = false : errorsState.year = true
      // item.keys ? errorsState.keys = false : errorsState.keys = true
      alreadyCreatedCarErrors.push(errorsState)
    })


    const result = alreadyCreatedCarErrors.map((item, i) => {
      const errorStatus = Object.values(item).every((item) => item === false)
      cars[i].alreadyCreatedErrors = !errorStatus
      return errorStatus ? false : 'error'
    }).filter(Boolean)

    this.setState({cars})

    return result.length ? false : true
  }

  checkCarValidateErrorFields = () => {
    const errorsState = {}
    const {carsFields, cars} = this.state;
    carsFields.makerOption.id ? errorsState.carMaker = false : errorsState.carMaker = true
    carsFields.modelOption.id ? errorsState.model = false : errorsState.model = true

    const checkExistCar = cars.filter((item)=>item.local_car_id !== carsFields.local_car_id)
    const checkVin = checkExistCar.some((item) => item.vin === carsFields.vin)

    carsFields.vin && carsFields.vin.length >= 8 && !checkVin ? errorsState.vin = false : errorsState.vin = true
    // carsFields.mileage ? errorsState.mileage = false : errorsState.mileage = true
    // carsFields.year ? errorsState.year = false : errorsState.year = true
    // carsFields.keys ? errorsState.keys = false : errorsState.keys = true
    const errorStatus = Object.values(errorsState).every((item) => item === false)
    this.setState({carValidateErrorFields: errorsState})
    return errorStatus
  }



  checkValidationError = () => {
    const errorsState = {}
    const {pick_up_date, delivery_date, originSelectedValue, destinationSelectedValue} = this.state;
    pick_up_date ? errorsState.start_date = false : errorsState.pick_up_date = true
    delivery_date ? errorsState.delivery_date = false : errorsState.delivery_date = true
    originSelectedValue ? errorsState.origin = false : errorsState.origin = true
    destinationSelectedValue ? errorsState.destination = false : errorsState.destination = true

    const errorStatus = Object.values(errorsState).every((item) => item === false)
    this.setState({validateErrorFields: errorsState})

    return errorStatus
  }

  checkCarAssign = () => {
    const {cars} = this.state

    if (cars.length > 0) {

      this.setState({
        carsFormError: false,
      })

      return true
    } else {
      this.setState({
        carsForm: true,
        carsFormError: true,
      })

      return false
    }
  }

  submitLoad = async (event, errors, values) => {

    const { history } = this.props
    const {
      cars,
      pick_up_date,
      delivery_date,
      originSelectedValue,
      destinationSelectedValue,
      enclose,
      isPublic,
      selectedDriver,
      selectedBillingType,
      defaultOptionsSelect,
      attachedFiles,
      isRush,
    } = this.state

    const
      validationErrors = this.checkValidationError(),
      carAssign = this.checkCarAssign(),

      deliveryDate = new Date(delivery_date),
      deliveryDateYear = deliveryDate.getFullYear(),
      deliveryDateMonth = deliveryDate.getMonth() + 1 >= 1 && deliveryDate.getMonth() + 1 < 10 ? `0${deliveryDate.getMonth() + 1}` : deliveryDate.getMonth() + 1,
      deliveryDateDay = deliveryDate.getDate() >= 1 && deliveryDate.getDate() < 10 ? `0${deliveryDate.getDate()}` : deliveryDate.getDate(),

      pickUpDate = new Date(pick_up_date),
      pickUpDateYear = pickUpDate.getFullYear(),
      pickUpDateMonth = pickUpDate.getMonth() + 1 >= 1 && pickUpDate.getMonth() + 1 < 10 ? `0${pickUpDate.getMonth() + 1}` : pickUpDate.getMonth() + 1,
      pickUpDateDay = pickUpDate.getDate() >= 1 && pickUpDate.getDate() < 10 ? `0${pickUpDate.getDate()}` : pickUpDate.getDate(),

      checkAlreadyCreatedCar = this.checkAlreadyCreatedCar()

    if (errors.length === 0 && validationErrors && carAssign && checkAlreadyCreatedCar) {

      const checkOrigin = defaultOptionsSelect.filter((item)=>item.label === originSelectedValue.label)
      const checkDestination = defaultOptionsSelect.filter((item)=>item.label === destinationSelectedValue.label)

      this.setState({createLoadBtnSpinner: true})

      // const originCoordinates = await MapAPI.GetCoordinatesByValues({
      //   address: values.originAddress,
      //   city: values.originCity,
      //   state: values.originState,
      //   zip: values.originZip,
      // })
      //
      // const destinationCoordinates = await MapAPI.GetCoordinatesByValues({
      //   address: values.destinationAddress,
      //   city: values.destinationCity,
      //   state: values.destinationState,
      //   zip: values.destinationZip,
      // })

      const milesData = await MapAPI.GetDirections({
        from: `${values.originAddress}`.replace(/ /g, '+'),
        to: `${values.destinationAddress}`.replace(/ /g, '+'),
      })


      console.log('>>> originSelectedValue: ', originSelectedValue)
      console.log('>>> destinationSelectedValue: ', destinationSelectedValue)

      await LoadAPI.CreateLoad({
        load: {
          load_id: values.loadNumber,
          pick_up_date: `${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`,
          delivery_date: `${deliveryDateYear}-${deliveryDateMonth}-${deliveryDateDay}`,
          driver_id: selectedDriver ? JSON.parse(selectedDriver).id : '',
          hauling: values.driverPrice || 0,
          is_enclosed: enclose,
          is_public: isPublic,
          miles: milesData ? milesData.miles : 0,
          is_cod: +selectedBillingType,
          note_dispatch: values.instructionsDispatch,
          note_release: values.instructionsDelivery,
          note: values.comment,
          priority: isRush,
        },
        origin: {
          id: typeof originSelectedValue.id === "number" ? (originSelectedValue.id || '') : '', // originSelectedValue.id || '',
          name: originSelectedValue.label.trim(),
          address: values.originAddress,
          // city: values.originCity,
          // state: values.originState,
          // ip: values.originZip,
          email: values.originEmail,
          phone: values.originPhone,
          is_private: checkOrigin.length ? 0 : 1 ,
          lon: milesData ? milesData.origin.lon: '',
          lat: milesData ? milesData.origin.lat : '',
          // lat: originCoordinates ? originCoordinates[0] : '',
          // lon: originCoordinates ? originCoordinates[1] : '',
        },
        destination: {
          id: typeof destinationSelectedValue.id === "number" ? (destinationSelectedValue.id || '') : '',
          name: destinationSelectedValue.label.trim(),
          address: values.destinationAddress,
          // city: values.destinationCity,
          // state: values.destinationState,
          // zip: values.destinationZip,
          email: values.destinationEmail,
          phone: values.destinationPhone,
          // lat: destinationCoordinates ? destinationCoordinates[0] : '',
          // lon: destinationCoordinates ? destinationCoordinates[1] : '',
          is_private: checkDestination.length ? 0 : 1 ,
          lon: milesData ? milesData.destination.lon: '',
          lat: milesData ? milesData.destination.lat : '',
        }
      }).then((res) => {

        const promises = cars.map((item) => {
          if (res.data) {
            let
              makerId = {},
              modelId = {},
              names = {
                car_maker: item.makerOption.isTitle ? item.makerOption.id : item.makerOption.value,
                car_model: item.makerOption.isTitle ?  item.modelOption.id : item.modelOption.value
              }
            ;

            if (item.makerOption.id && typeof item.makerOption.id === 'number')
              makerId = { car_maker_id: item.makerOption.id }

            if (item.modelOption.id && typeof item.modelOption.id === 'number')
              modelId = { car_model_id: item.modelOption.id }

            return (
              new Promise((resolve, reject) => {
                CarAPI.CreateCars({
                  load_id: res.data.load.id,
                  ...names,
                  ...makerId,
                  ...modelId,
                  // ...item.makerOption.id && typeof item.makerOption.id === 'number' ? {car_maker_id: item.makerOption.id} : {},
                  // ...item.modelOption.id && typeof item.modelOption.id === 'number' ? {car_model_id: item.modelOption.id} : {},
	                // car_maker: item.makerOption.value,
	                // car_model: item.modelOption.value,
	                // ...item.makerOption.id ? {[item.makerOption.isTitle ? 'car_maker' : 'car_maker_id']: item.makerOption.id} : {},
                  // ...item.modelOption.id ? {[item.modelOption.isTitle ? 'car_model' : 'car_model_id']: item.modelOption.id} : {},
                  vin: item.vin,
                  car_type: item.carTypesOption.value,
                  year: item.year,
                  mileage: item.mileage,
                  keys: item.keys,
                  is_inop: item.is_inop,
                })
              })
            )
          }

          return Promise.resolve();
        })

        if (attachedFiles && attachedFiles.length > 0) {
          promises.push(attachedFiles.map((releaseFile) => {
            return new Promise((resolve, reject) => {
              LoadAPI.UploadReleaseFile(res.data.load.id, releaseFile);
            });
          }));
        }

        Promise.all(promises)
        history.push('/loads');

      }).catch((error) => {
        console.log('ERROR', error)
        this.setState({error: true})
      })
    }
  }

  showCarsForm = () => this.setState({carsForm: !this.state.carsForm})

  createCar = () => {
    const {cars, carsFields, defaultModelsSelectOptions, defaultCarsSelectOptions} = this.state;

    const validationErrors = this.checkCarValidateErrorFields()
    if (validationErrors) {
      carsFields.local_car_id = uuidv4()
      cars.push(carsFields)
      this.setState({
        cars,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',
        },
        carsSelectOptions: defaultCarsSelectOptions,
        modelsSelectOptions: defaultModelsSelectOptions,

      })
    }
  }

  deleteCar = (id) => {
    const {cars} = this.state;
    const carsArray = cars.filter((item, i) => id !== i ? item : null)

    this.setState({
      cars: carsArray,
      carsFields: {
        makerOption: '',
        modelOption: '',
        vin: '',
        mileage: '',
        year: '',
        keys: '',
        type: '',
        is_inop: 0,
        carTypesOption: '',
      },

    })
  };

  carFieldsHandler = (e) => {

    if (e.target.name === 'is_inop') {
      this.setState({
        carsFields: {...this.state.carsFields, ...{[e.target.name]: this.state.carsFields.is_inop === 0 ? 1 : 0}}
      })
    } else if (e.target.name === 'year') {
      const string = e.target.value;
      const regexp = string.match(/[^0-9]/gi, '');
      if (regexp === null) {
        this.setState({
          carsFields: {...this.state.carsFields, ...{[e.target.name]: e.target.value}}
        })
      }
    } else if (e.target.name === 'vin') {
      const name = e.target.name
      const string = e.target.value;
      const regexp = string.match(/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm, '');

      if (regexp === null) {
        this.setState({
          carsFields: {...this.state.carsFields, ...{[name]: string}}
        }) 
      } else {
        VehicleAPI.GetVINDecoded(string).then((vinsData) => {
          if (vinsData) {
            this.setState({
              carsFields: {
                modelOption: { value: vinsData.model, label: vinsData.model, id: vinsData.model },
                makerOption: { value: vinsData.make, label: vinsData.make, id: vinsData.make },
                year: vinsData.year,
                vin: vinsData.vin,
                mileage: this.state.carsFields.mileage,
                carTypesOption: { value: vinsData.body, label: vinsData.body },
                keys: this.state.carsFields.keys,
                type: this.state.carsFields.type,
                is_inop: this.state.carsFields.is_inop,
              }
            })
          } else {
            this.setState({
              carsFields: {...this.state.carsFields, ...{[name]: string}}
            }) 
          }
        })
      }
    } else {
      this.setState({
        carsFields: {...this.state.carsFields, ...{[e.target.name]: e.target.value}}
      })
    }
  };

  encloseHandler = (e) => {
    this.setState({enclose: this.state.enclose === 0 ? 1 : 0})
  };

  rushHandler = (e) => {
    this.setState((state) => ({
      ...state,
      isRush: state.isRush && state.isRush.toLowerCase() === 'rush' ? null : 'rush'
    }))
  };

  isPublicHandler = (e) => {
    this.setState((state) => ({
      ...state,
      isPublic: state.isPublic === 1 ? 0 : 1
    }))
  }

	changeMakerOption = async makerOption => {
		const
			{defaultCarsSelectOptions, carsFields} = this.state,
			modelsData = await CarAPI.GetModelData(makerOption.id),
			modelsSelectOptions = modelsData.data.data.map(item => {
				return {value: item.title, label: item.title, id: item.id}
			});

		/*
		let checkModelExist = true;

		if (carsFields.modelOption.id !== null)
			checkModelExist = modelsSelectOptions.filter((item) => item.label === carsFields.modelOption.label).length

    if (!checkModelExist && carsFields.modelOption.id !== null)
      checkModelExist = true
    */

		this.setState({
			carsFields: {...carsFields, ...{makerOption}, modelOption: carsFields.modelOption},
			modelsSelectOptions,
			carsSelectOptions: defaultCarsSelectOptions
		});
	};

	onInputChangeMake = inputValue => {
		let {defaultCarsSelectOptions, delayTimer, carsFields} = this.state;
		const {makerOption} = carsFields;

		if (inputValue !== '' && inputValue.length >= 3) {
			clearTimeout(delayTimer);
			delayTimer = setTimeout(() => {
				const checkRegister = defaultCarsSelectOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
				if (!checkRegister.length)
					checkRegister.push({isTitle: true, value: inputValue, label: inputValue, id: inputValue, data:  (makerOption && makerOption.data) || {}})

				this.setState({isLoading: false, carsSelectOptions: checkRegister });
			}, 100);
			this.setState({isLoading: true, delayTimer});
		} else {
			this.setState({isLoading: false, carsSelectOptions: defaultCarsSelectOptions});
		}
	};


	onChangeMake = async makerOption => {
		const {defaultCarsSelectOptions, carsFields} = this.state;

		if (!makerOption || !makerOption.id) {
			this.setState({carsFields: {...carsFields, ...{makerOption: null}}, carsSelectOptions: defaultCarsSelectOptions});
			return;
		}

		const modelsData = await CarAPI.GetModelData(makerOption.id);

		if (modelsData.data.data) {
			const modelsSelectOptions = modelsData.data.data.map((item) => {
				return {value: item.title, label: item.title, id: item.id}
			});

			let checkModelExist = true;

			if (carsFields.modelOption && carsFields.modelOption.id !== null) {
				checkModelExist = modelsSelectOptions.filter((item) => item.label === carsFields.modelOption.label).length;
			}
			this.setState({
				carsFields: {...carsFields, ...{makerOption}, modelOption: checkModelExist ? carsFields.modelOption : {}},
				modelsSelectOptions,
				carsSelectOptions: defaultCarsSelectOptions,
				modelsCurrentOptions : modelsSelectOptions
			});
		}

		this.setState({carsFields: {...carsFields, ...{makerOption: makerOption}} });
	};

	onInputChangeModel = inputValue => {
		const
			{carsFields, modelsCurrentOptions, modelsSelectOptions} = this.state,
			{modelOption} = carsFields

    if (!modelsCurrentOptions && modelsSelectOptions)
      this.setState({modelsCurrentOptions: modelsSelectOptions});

		if (inputValue !== '' && inputValue.length >= 3) {
			const checkRegister = modelsCurrentOptions ? modelsCurrentOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())) : []
			if (!checkRegister.length)
				checkRegister.push({isTitle: true, value: inputValue, label: inputValue, id: inputValue, data:  (modelOption && modelOption.data) || {}})
			this.setState({isLoading: false, modelsSelectOptions: checkRegister });
		} else if ( inputValue.length === 0) {
			this.setState({isLoading: false, modelsSelectOptions: modelsCurrentOptions });
		}
	};

  onChangeModel = async modelOption => {
	  let carsSelectOptions;
  	if (modelOption && modelOption.car_maker) {
		  const carsData = await CarAPI.GetCarMakerData(modelOption.car_maker);
		  carsSelectOptions = carsData.data.data.map((item) => {
			  return {value: item.title, label: item.title, id: item.id}
		  })
	  } else if (modelOption) {
		  modelOption.isTitle = true;
	  }
	  if (modelOption)
	    this.setState({
	      carsFields: {...this.state.carsFields, ...{modelOption}},
		    ...carsSelectOptions ? carsSelectOptions : {},
	    });
	  else
		  this.setState({
			  carsFields: {...this.state.carsFields, ...{modelOption: ''}},
			  modelsSelectOptions: this.state.defaultModelsSelectOptions
		  });
  };


  changeCarTypes = carTypesOption => {
    this.setState({carsFields: {...this.state.carsFields, ...{carTypesOption}}});
  };

  handlePickupDate = (date) => {
    this.setState({pick_up_date: date});
  }

  handleDeliveryDate = (date) => {
    this.setState({delivery_date: date});
  }

  onInputChange = (inputValue, name) => {
    let { defaultOptionsSelect = [], delayTimer, originSelectedValue, destinationSelectedValue } = this.state
    let dataOptions

    if (name === 'origin') {
      dataOptions = originSelectedValue
      this.setState({
        originKey: inputValue,
        //originSelectedValue: {}
      })
    } else {
      dataOptions = destinationSelectedValue
      this.setState({
        destinationKey: inputValue,
        //destinationSelectedValue: {}
      })
    }

    if (inputValue !== '' && inputValue.length >= 3) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        let isSameName = false
        defaultOptionsSelect = defaultOptionsSelect.filter(i => {
          // if (!i.cityAdded) { i.label = i.label + ' - ' + i.data.city }
          // console.log('>>> iii: ', i)
          if (inputValue.toLowerCase() === i.label.toLowerCase()) { isSameName = true } //
          i.cityAdded = true
          return i
        })
        const checkRegister = defaultOptionsSelect.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))

        // if (!checkRegister.length)
        if (!isSameName) {
          checkRegister.push({value: inputValue, label: inputValue, id: inputValue, data: (dataOptions && dataOptions.data) || {}, cityAdded: true})
        }

        this.setState({
          optionsSelect: checkRegister,
          isLoading: false,
        })
      }, 800)

      this.setState({
        isLoading: true,
        delayTimer
      })
    } else {
      this.setState({
        isLoading: false,
        optionsSelect: []
      })
    }
  }

  onClose = () => {
    this.setState({
      optionsSelect: []
    });
  }

  onChange = (data, name) => {
    console.log('>>> data: ', data, name)
    if (name === 'origin') {
      this.setState({ originSelectedValue: data })
    } else {
      this.setState({ destinationSelectedValue: data })
    }
  }

  selectCar = (carsFields, i) => {
    if (!carsFields) {
      return;
    }

    const {selectedCar,defaultCarsSelectOptions,defaultModelsSelectOptions} = this.state


    if (selectedCar === i) {

      this.setState({
        selectedCar: null,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',
        },

        carsSelectOptions: defaultCarsSelectOptions,
        modelsSelectOptions: defaultModelsSelectOptions
      })
    } else {

      this.setState({
        carsFields,
        selectedCar: i,
        carsForm: true,
      },()=> {
        this.changeMakerOption({
          value: carsFields.makerOption.value,
          label: carsFields.makerOption.label,
          id: carsFields.makerOption.id
        })
      })

    }
  }

  updateCar = () => {
    const {selectedCar, cars, carsFields} = this.state
    const checkCarValidateErrorFields = this.checkCarValidateErrorFields()

    if(checkCarValidateErrorFields){
      const updatedCars = cars.map((item, i) => {
        if (i === selectedCar) {
          return {...item, ...carsFields}
        } else {
          return item
        }
      })

      this.setState({
        cars: updatedCars,
        selectedCar: null,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',
          local_car_id: ''
        },
      })
    }

  }

  uploadPDF = (e, type) => {

    const file = e.target.files[0];

    if (file) {

      this.setState({loadingPDF: true}, async () => {
        await LoadAPI.UploadPDF({
          filePdf: file,
          fileType: type
        }).then((result) => {


          if (result.data) {
            const data = result.data.data
            const carsArray = data.cars.map((item) => {
              return {
                carTypesOption: {value: item.car_type, label: item.car_type},
                makerOption: {value: item.car_maker, label: item.car_maker, id: item.car_maker_id ? item.car_maker_id : item.car_maker},
                modelOption: {value: item.car_model, label: item.car_model, id: item.car_model_id ? item.car_model_id : item.car_model},
                vin: item.vin,
                year: item.year,

                is_inop: item.is_inop ? item.is_inop : 0,
                keys: item.keys ? item.keys : '0',
                mileage: item.mileage ? item.mileage : '0'
              }
            })

            const pick_up_date = new Date(data.pick_up_date)
            const delivery_date = new Date(data.delivery_date)
            const today_date = new Date()

            this.setState({
              loadingPDF: false,
              resultPDF: data,
              cars: carsArray,
              pick_up_date: pick_up_date > today_date  ? new Date(data.pick_up_date.replace(/-/g, "/")) : today_date,
              delivery_date: delivery_date > today_date ? new Date(data.delivery_date.replace(/-/g, "/")) : today_date,

              originSelectedValue: {
                data: data.origin,
                value: data.origin.name,
                label: data.origin.name,
                // id: data.origin.name
              },
              destinationSelectedValue: {
                data: data.destination,
                value: data.destination.name,
                label: data.destination.name,
                // id: data.origin.name
              },
              errorPDF: '',
              pdfWarningMessage: true,
              note: data.note,
              noteDispatch: data.dispatchInstructions,
              noteRelease: data.deliveryInstructions,
            })
          }

          else {
            this.setState({
              loadingPDF: false,
              errorPDF: `We can't parse this file`
            })
          }

        }, result => this.setState({
          loadingPDF: false,
          errorPDF: `We can't parse this file`
        })).catch((error) => {
          console.error("Uploading Load failed: ", error)
          this.setState({
            loadingPDF: false,
            errorPDF: `We can't parse this file`
          });
        });
      });

    } else {
      console.log('file error')
    }
  }

  // noteHandler = (e) => {
  //   this.setState({ note: e.target.value })
  // }

  closeModal = () => {
    this.setState({ isOpenSubscriptionModal: false });
  };

  addFile = async (item) => {
    const selectedFile = item.target.files[0];
    const fileName = selectedFile.name ? selectedFile.name : selectedFile.fileName;

    if (selectedFile) {
      const {attachedFiles} = this.state;

      if (attachedFiles.includes(selectedFile) || attachedFiles.some((s) => s.name === fileName || s.fileName === fileName)) {
        this.setState({ releaseFileError: "File \"" + fileName + "\" already added." });
        return;
      }

      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        this.setState({ releaseFileError: "File \"" + fileName + "\" exceeded maximum allowed file size." });
        return;
      }

      this.setState({attachedFiles: [...attachedFiles, selectedFile], releaseFileError: null}, () => {
        console.log("selected file = ", fileName, JSON.stringify(attachedFiles));
      });
    }
  }

  removeFile = async (item, i) => {
    const {attachedFiles} = this.state;
    const removedFile = attachedFiles.splice(i, 1);
    const fileName = removedFile[0].name ? removedFile[0].name : removedFile[0].fileName;

    this.setState({attachedFiles: [...attachedFiles]}, () => {
      console.log("removed file = " + fileName);
    });
  }

  downloadFile = (item, i) => {
    const {attachedFiles} = this.state;
    const linkedFile = attachedFiles[i];
    const fileName = linkedFile.name ? linkedFile.name : linkedFile.fileName

    console.log("linked file = ", fileName);
  }

  render() {

    const {
      cars,
      carsForm,
      error,
      carsSelectOptions,
      modelsSelectOptions,
      carTypesSelectOptions,
      carsFields,
      pick_up_date,
      delivery_date,
      enclose,
      validateErrorFields,
      carValidateErrorFields,
      selectedCar,
      // note,

      loadingPDF,
      errorPDF,
      resultPDF,

      carsFormError,

      createLoadBtnSpinner,

      pdfWarningMessage,
      isPublic,
      isOpenSubscriptionModal,
      attachedFiles,
      releaseFileError,
      isRush, 
      awaitForRender, 
      popupOpen, 
      bubbleUploadPdfOpen,
      bubbleAdjustDatesOpen,
      bubbleDesirableRateOpen,
      bubbleAddVehicleOpen,
      bubbleAttachExtrasOpen,
      bubbleCorrectAddressOpen,
      bubbleAddPaymentTermsOpen
  } = this.state;
    const normalizedCarsFields = carsFields ? carsFields : {};
    const makerOption = normalizedCarsFields.makerOption ? normalizedCarsFields.makerOption : '';
    const modelOption = normalizedCarsFields && normalizedCarsFields.modelOption ? normalizedCarsFields.modelOption : '';
    const carTypesOption = normalizedCarsFields && normalizedCarsFields.carTypesOption ? normalizedCarsFields.carTypesOption : '';

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <PopupSubscription popupOpen={isOpenSubscriptionModal} closeModal={this.closeModal} />
            <CustomSideBar page={'loads'}/>
            <CustomTopBar/>
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleUploadPdfOpen} 
              speechMode={"showUploadPdf"} 
              closeCallBack={() => 
                this.setState({
                  bubbleUploadPdfOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showAdjustDates"} 
              closeCallBack={() => 
                this.setState({
                  bubbleAdjustDatesOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleDesirableRateOpen && !bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showDesirableRate"} 
              closeCallBack={() => 
                this.setState({
                  bubbleDesirableRateOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleAddVehicleOpen && !bubbleDesirableRateOpen && !bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showAddVehicle"} 
              closeCallBack={() => 
                this.setState({
                  bubbleAddVehicleOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleAttachExtrasOpen && !bubbleAddVehicleOpen && !bubbleDesirableRateOpen && !bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showAttachExtras"} 
              closeCallBack={() => 
                this.setState({
                  bubbleAttachExtrasOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleCorrectAddressOpen && !bubbleAttachExtrasOpen && !bubbleAddVehicleOpen && !bubbleDesirableRateOpen && !bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showCorrectAddress"} 
              closeCallBack={() => 
                this.setState({
                  bubbleCorrectAddressOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleAddPaymentTermsOpen && !bubbleCorrectAddressOpen && !bubbleAttachExtrasOpen && !bubbleAddVehicleOpen && !bubbleDesirableRateOpen && !bubbleAdjustDatesOpen && !bubbleUploadPdfOpen} 
              speechMode={"showAddPaymentTerms"} 
              closeCallBack={() => 
                this.setState({
                  bubbleAddPaymentTermsOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header-loads page-title-box">

                  <div className="main-content-header-left"  style={{ minWidth: '200px' }}>
                    <div className="main-content-header-title page-title">Load</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item"><Link to='/loads'>Loads</Link></li>
                      <li className="breadcrumb-item active">Create load</li>
                    </ol>
                  </div>
                  <div className="main-content-header-right main-content-header-right-load" style={{ minWidth: '130px' }}>
                    {loadingPDF && (<div className="custom-spinner" role="status"/>)}
                    <label className="btn custom-btn" htmlFor="uploadPDF1" style={{marginTop: '25px'}}>
                      <input type="file" onChange={(e) => this.uploadPDF(e, 'acv')} id="uploadPDF1"/>
                      Upload ACV Slip
                    </label>
                    {/* {errorPDF && (<div className="parsePDFerror">{errorPDF}</div>)} */}

                    {/* {pdfWarningMessage &&
                    (<div className="select-car-model-error " >
                      <span className='fas fa-exclamation-triangle' style={{paddingRight: '10px', color: '#ffd90f'}}/>
                      During the import of PDF files, some information may be lost or placed in the wrong place. <br />
                      Please check all the fields before saving loads.
                    </div>)} */}

                  </div>
                  <div className="main-content-header-right main-content-header-right-load" style={{ width: '100%' }}>
                    {errorPDF && (<div className="parsePDFerror">{errorPDF}</div>)}

                    {pdfWarningMessage &&
                    (<div className="select-car-model-error " >
                      <span className='fas fa-exclamation-triangle' style={{paddingRight: '10px', color: '#ffd90f'}}/>
                      During the import of PDF files, some information may be lost or placed in the wrong place. <br />
                      Please check all the fields before saving loads.
                    </div>)}

                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="load">
                    <div className="load-back">
                      <Link to='/loads'><i className="fas fa-arrow-left"/> Back</Link>
                    </div>
                    <AvForm onSubmit={this.submitLoad}>
                      <div className="row">
                        <div className="col-12">
                          <AvField
                            name="loadNumber"
                            label="Load number *"
                            required
                            errorMessage="Invalid load number"
                            maxLength="100"
                            value={resultPDF.load_id || ''}
                            type="text" validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Pick up date *</label>
                            <DatePicker
                              className={validateErrorFields.pick_up_date ? 'form-control form-control-error' : 'form-control'}
                              selected={pick_up_date}
                              onChange={this.handlePickupDate}
                              dateFormat="yyyy-MM-dd"
                              minDate={new Date()}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Delivery date *</label>
                            <DatePicker
                              className={validateErrorFields.delivery_date ? 'form-control form-control-error' : 'form-control'}
                              selected={delivery_date < pick_up_date ? '' : delivery_date}
                              onChange={this.handleDeliveryDate}
                              dateFormat="yyyy-MM-dd"
                              minDate={pick_up_date || new Date()}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Status</label>
                            <div>
                              <div className="load-status load-status-active">New</div>
                            </div>
                          </div>
                        </div>

                        {/*
                        <div className="col-12">

                          {transporters_active > transporters_total ?
                            <AvField
                              type="select"
                              name="select"
                              label='Driver'
                              disabled={true}
                              className="load-select-drivers"
                              onChange={(e) => this.setState({selectedDriver: e.target.value})}>
                              <option selected="selected"/>
                              <option value="">{' '}</option>
                              {myDrivers.map((item) => {
                                return <option value={JSON.stringify(item)}>{item.first_name || ''} {item.last_name || ''}</option>
                              })}
                            </AvField>
                            :
                            <AvField
                              type="select"
                              name="select"
                              label='Driver'
                              className="load-select-drivers"
                              onChange={(e) => this.setState({selectedDriver: e.target.value})}>
                              <option selected="selected"/>
                              <option value="">{' '}</option>
                              {myDrivers.map((item) => {
                                return <option value={JSON.stringify(item)}>{item.first_name || ''} {item.last_name || ''}</option>
                              })}
                            </AvField>
                          }

                        </div>
                        */}

                        <div className="col-12">
                          <AvField
                            name="driverPrice"
                            label="Price ($)"
                            value={resultPDF.hauling ? resultPDF.hauling.toString() : ''}
                            maxLength="100"
                            type="number"/>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Cars to transport *</label>
                            <div className="load-new-model">
                              <div className="load-new-model-header">
                                {/*{cars.map((item, i) => {*/}
                                {/*return (*/}
                                {/*<div*/}
                                {/*key={uuidv4()}*/}
                                {/*onClick={() => this.selectCar(item, i)}*/}
                                {/*className={selectedCar === i ?*/}
                                {/*"load-new-model-header-item load-new-model-header-item-active" : "load-new-model-header-item"}>*/}
                                {/*{item.makerOption.label}*/}
                                {/*<span onClick={() => this.deleteCar(i)}/>*/}
                                {/*</div>*/}
                                {/*)*/}
                                {/*})}*/}
                                {cars.map((item, i) => {
                                  return (
                                    <div
                                      key={uuidv4()}
                                      className={selectedCar === i ?
                                        "load-new-model-header-item load-new-model-header-item-active" : "load-new-model-header-item"}>
                                      <div onClick={() => this.selectCar(item, i)}>
                                        {item.makerOption ? item.makerOption.label : item.car_maker_name}
                                      </div>
                                      <span onClick={() => this.deleteCar(i)}/>

                                      {item.alreadyCreatedErrors && (<p className="load-new-model-header-item-error"/>)}
                                    </div>
                                  )
                                })}

                                <div
                                  className="load-new-model-add btn custom-btn"
                                  onClick={this.showCarsForm}
                                >Add new car
                                </div>
                              </div>
                              <div className={carsForm ? "load-new-model-form load-new-model-form-active" : "load-new-model-form "}>
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Make*</label>
                                      <div className="select-wrap">
                                        <Select
                                          className={carValidateErrorFields.carMaker ? 'custom-select-error-wrap form-control-error' : ''}
                                          value={makerOption}
                                          // onChange={this.changeMakerOption}
                                          onInputChange={this.onInputChangeMake}
                                          onChange={this.onChangeMake}
                                          //onInputChange={(e) => this.inputModel(e, 'origin')}/>
                                          options={carsSelectOptions}
                                          removeSelected={true}
                                          multi={false}
                                          autosize={true}
                                          isClearable
                                          onSelectResetsInput={true}
                                          maxLength="100"
                                          isLoading={this.state.isLoading}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Model*</label>
                                      <div className="select-wrap">
                                        <Select
                                          className={carValidateErrorFields.model ? 'custom-select-error-wrap form-control-error' : ''}
                                          value={modelOption}
                                          onInputChange={this.onInputChangeModel}
                                          onChange={this.onChangeModel}
                                          options={modelsSelectOptions}
                                          maxLength="100"
                                          removeSelected={true}
                                          multi={false}
                                          autosize={true}
                                          isClearable
                                          onSelectResetsInput={true}
                                          isLoading={this.state.isLoading}
                                        />

                                        {/*{Object.keys(modelOption).length == 0 && Object.keys(makerOption).length > 0 &&*/}
                                        {modelOption.id === null &&
                                          (<div className="select-car-model-error " >
                                            <span className='fas fa-exclamation-triangle' style={{paddingRight: '10px', color: '#ffd90f'}}/>
                                            We can't find this model. Please select new from the list</div>)}

                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Vin*
                                        <span className="custom-info" id="TooltipTop1">i</span>
                                        <Tooltip
                                          placement="top"
                                          isOpen={this.state.tooltip_top}
                                          target="TooltipTop1" toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                                          Enter full VIN or last 8 symbols.
                                          VIN can only consist of numbers and letters.
                                          You can't have 2 or more cars with the same VIN in your load
                                        </Tooltip>
                                      </label>
                                      <input
                                        type="text"
                                        className={carValidateErrorFields.vin || !normalizedCarsFields.vin ? "form-control form-control-select-error" : "form-control"}
                                        value={normalizedCarsFields.vin}
                                        maxLength="100"
                                        name="vin"
                                        required
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Mileage</label>
                                      <input
                                        className="form-control"
                                        value={normalizedCarsFields.mileage}
                                        type="number"
                                        name="mileage"
                                        required
                                        maxLength="100"
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Year</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="year"
                                        maxLength="4"
                                        value={normalizedCarsFields.year}
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Keys</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="keys"
                                        required
                                        maxLength="100"
                                        value={normalizedCarsFields.keys}
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Type</label>
                                      <div className="select-wrap">
                                        <Select
                                          value={carTypesOption}
                                          onChange={this.changeCarTypes}
                                          options={carTypesSelectOptions}
                                          maxLength="100"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="load-new-model-checkbox-line">
                                      <div className="custom-control custom-checkbox">
                                        <span className="custom-checkbox-title">INOP</span>
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          name="is_inop"
                                          onChange={(e) => this.carFieldsHandler(e)}
                                          checked={normalizedCarsFields.is_inop !== 0}
                                          value={normalizedCarsFields.is_inop}
                                          id="load-checkbox-1"/>
                                        <label className="custom-control-label" htmlFor="load-checkbox-1"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={'row'}>
                                  <div className="col-8">
                                    <div className="load-new-model-form-footer">
                                      {selectedCar === null ?
                                        <div
                                          className="btn custom-btn"
                                          onClick={this.createCar}>
                                          Submit
                                        </div>
                                        :
                                        <div
                                          className="btn custom-btn"
                                          onClick={this.updateCar}>
                                          Update
                                        </div>
                                      }
                                      <p className="load-new-model-form-info">
                                        Please press submit after you fill car fields
                                      </p>
                                      {carsFormError && (
                                        <span className="errorMsg" style={{textAlign: 'left'}}>
                                          You need to attach car before save load
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="col-4">
                                    <div className="form-group">
                                      <label htmlFor="">Special Note</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="note"
                                        required
                                        maxLength="100"
                                        value={note}
                                        onChange={(e) => this.noteHandler(e)}/>
                                    </div>
                                  </div> */}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Enclose only</label>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="enclose"
                                value={enclose}
                                checked={enclose !== 0}
                                onChange={(e) => this.encloseHandler(e)}
                                id="load-checkbox-2"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-2"/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Rush load</label>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="priority"
                                checked={isRush === "rush"}
                                onChange={this.rushHandler.bind(this)}
                                id="load-checkbox-4"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-4"/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <ReleaseFiles attachedFiles={attachedFiles} addFile={this.addFile} downloadFile={this.downloadFile} removeFile={this.removeFile} />
                          {
                            releaseFileError ? (
                                <span className="errorMsg" style={{textAlign: 'right'}}>{releaseFileError}</span>
                            ) : null
                          }
                          <div className="noteitem" style={{ paddingLeft: 172 }}>Maximum allowed file size is { MAX_FILE_SIZE_MB }Mb.</div>
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <AvField
                            name="comment"
                            label="Comment"
                            errorMessage="Invalid comment"
                            type="textarea"
                            rows="6"
                            value={this.state.note || ''}
                            maxLength={"3777"}
                          />
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Origin *</label>
                            <div className="select-wrap">
                              <Select
                                closeOnSelect={false}
                                options={this.state.optionsSelect}
                                value={this.state.originSelectedValue}
                                removeSelected={true}
                                multi={false}
                                autosize={true}
                                isClearable
                                className={validateErrorFields.origin ? 'custom-select-error-wrap form-control-error' : ''}
                                isLoading={this.state.isLoading}
                                onSelectResetsInput={true}
                                onChange={(e) => this.onChange(e, 'origin')}
                                onInputChange={(e) => this.onInputChange(e, 'origin')}/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            label=" "
                            name="originAddress"
                            required
                            errorMessage="Invalid origin address"
                            maxLength="100"
                            placeholder="address"
                            type="text"
                            value={this.state.originSelectedValue ? this.state.originSelectedValue.data.address : ''}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originEmail"
                            label="Origin email"
                            errorMessage="Invalid origin email"
                            type="email"
                            maxLength="100"
                            value={this.state.originSelectedValue ? this.state.originSelectedValue.data.email : ''}
                          />
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originPhone"
                            label="Origin phone number"
                            errorMessage="Invalid phone"
                            maxLength="100"
                            type="_tel"
                            value={this.state.originSelectedValue ? this.state.originSelectedValue.data.phone : ''}
                          />
                        </div>

                        <div className="col-12">
                          <AvField
                            name="instructionsDispatch"
                            label="Dispatch Instructions"
                            errorMessage="Invalid dispatch instructions"
                            type="textarea"
                            rows="6"
                            value={this.state.noteDispatch || ''}
                            maxLength={"3777"}
                          />
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Destination *</label>
                            <div className="select-wrap">
                              <Select
                                closeOnSelect={false}
                                options={this.state.optionsSelect}
                                value={this.state.destinationSelectedValue}
                                removeSelected={true}
                                multi={false}
                                autosize={true}
                                isClearable
                                className={validateErrorFields.destination ? 'custom-select-error-wrap form-control-error' : ''}
                                isLoading={this.state.isLoading}
                                onSelectResetsInput={true}
                                onChange={(e) => this.onChange(e, 'destination')}
                                onInputChange={(e) => this.onInputChange(e, 'destination')}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                            <AvField
                              label=" "
                              name="destinationAddress"
                              errorMessage="Invalid destination address"
                              maxLength="100"
                              placeholder="address"
                              type="text"
                              value={this.state.destinationSelectedValue ? this.state.destinationSelectedValue.data.address : ''}
                              validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationEmail"
                            label="Destination Email"
                            errorMessage="Invalid destination email"
                            maxLength="100"
                            value={this.state.destinationSelectedValue ? this.state.destinationSelectedValue.data.email : ''}
                            type="email"/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationPhone"
                            label="Destination phone number"
                            className="destinationPhone"
                            value={this.state.destinationSelectedValue ? this.state.destinationSelectedValue.data.phone : ''}
                            errorMessage="Invalid destination phone number"
                            maxLength="100"
                            type="_tel"/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="instructionsDelivery"
                            label="Delivery Instructions"
                            errorMessage="Invalid delivery instructions"
                            type="textarea"
                            value={this.state.noteRelease || ''}
                            maxLength={"3777"}
                            rows="6"
                          />
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <AvField
                            type="select"
                            name="select"
                            label='COD/COP/Billing'
                            className="load-select"
                            onChange={(e) => this.setState({selectedBillingType: e.target.value})}>
                            <option value="1">COD</option>
                            <option value="3">COP</option>
                            <option value="7">Quick Pay</option>
                            <option value="9">Zelle</option>
                            <option value="11">Venmo</option>
                            <option value="13">Cashapp</option>
                            <option value="5">Billing</option>
                          </AvField>
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="is_public" style={{paddingRight: 15}}>
                              Make load available for all transporters
                            </label>
                            <div id="ispublic" className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="is_public"
                                checked={isPublic === 1}
                                onChange={this.isPublicHandler}
                                id="load-checkbox-3"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-3"/>
                            </div>
                          </div>
                        </div>


                        {/*<div className="col-12 m-t-40">*/}
                        {/*<AvField*/}
                        {/*name="managerName"*/}
                        {/*label="Manager name"*/}
                        {/*maxLength="100"*/}
                        {/*type="text"/>*/}
                        {/*</div>*/}
                        {/*<div className="col-12">*/}
                        {/*<AvField*/}
                        {/*name="managerPhoneNumber"*/}
                        {/*label="Manager phone number"*/}
                        {/*maxLength="100"*/}
                        {/*type="tel"/>*/}
                        {/*</div>*/}
                        {/*<div className="col-12">*/}
                        {/*<AvField*/}
                        {/*name="managerEmail"*/}
                        {/*label="Manager email"*/}
                        {/*maxLength="100"*/}
                        {/*type="email"/>*/}
                        {/*</div>*/}
                      </div>

                      <div className="row">
                        {
                          error ? (
                            <div className="col-12">
                              <span className="errorMsg" style={{textAlign: 'right'}}>You have uncorrected fields</span>
                            </div>
                          ) : null
                        }
                        <div className="col-12 button-input">
                          <Link to="/loads">
                            <div className="load-create-cancel">Cancel</div>
                          </Link>
                            {createLoadBtnSpinner && (<div className="custom-spinner" role="status"/>)}
                            <Button
                              className="btn btn-primary w-md custom-btn"
                              disabled={createLoadBtnSpinner}
                              type="submit">
                              Create load
                            </Button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Load);
