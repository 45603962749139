import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import uuidv4 from "uuid";
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import LoadsAPI from "../../../api/loadsAPI";
import ReactPaginate from 'react-paginate';
import { Button } from "reactstrap";
import Select from "react-select";
import TransportersAPI from "../../../api/transportersAPI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CurrencyFormat from 'react-currency-format';
import downloadCsv from "../../../utils/ExportCSV";
import { googleSearch, googleMapSearch, phoneCall, mailTo, openOnlineBol } from '../../../utils/LinkUtils';
// import formatAddress from "../../../utils/AddressParser";

class RowItem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      carData: [],
      commentMode: null,
      priceMode: null,
      priceText: '',
      commentText: '',
      load: props.item
    }
  }

  async toggleRow(e) {
    const data = await LoadsAPI.GetLoadsCars(this.props.item.id)
    const resp = data.data.data
    let carDataText = []
    resp.forEach(el => {
      // carDataText.push((el.year || '') + ' ' + (el.car_maker_name || '') + ' ' + (el.car_model_name || ''))
      carDataText.push(el);
    })
    this.setState({
      open: !this.state.open,
      carData: carDataText
    })
  }

  openPopup = (id) => {
    // this.setState({popupOpen: true, popupItemId: id})
    this.props.openPopup(id)
  }

  handleComment = async (id) => {
    const { commentText } = this.state
    const res = await LoadsAPI.SaveLoadNote({ id, note: commentText })
    if (res && res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ commentMode: null, load: data.data.data })
    } else {
      this.setState({ commentMode: null })
    }
  }

  handlePrice = async (item) => {
    const { priceText } = this.state
    const res = await LoadsAPI.UpdateLoad({
      load: {
        id: item.id,
        hauling: priceText
      },
      origin: {
        id: item.origin_id
      },
      destination: {
        id: item.destination_id
      }
    })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(item.id)
      this.setState({ priceMode: null, load: data.data.data })
    } else {
      this.setState({ priceMode: null })
    }
    this.props.getLoads()
  }

  deleteComment = async (id) => {
    const res = await LoadsAPI.SaveLoadNote({ id, note: '' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ commentMode: null, load: data.data.data })
    } else {
      this.setState({ commentMode: null })
    }
  }

  render() {
    const { commentMode, commentText, priceMode, priceText, load: item } = this.state

    let pickUpDate, pickUpDateDivided, deliveryDate, deliveryDateDivided
    if (item.pick_up_date) {
      pickUpDate = (item.pick_up_date + '').split(' ')
      pickUpDateDivided = pickUpDate[0].split('-')

      deliveryDate = (item.destination_sign_date + '').split(' ')
      deliveryDateDivided = deliveryDate[0].split('-')
    }
    const isOpen = this.state.open

    // const originTitle = formatAddress(item.origin_data.address);
    const originName = item.origin_data.name || '-';
    const originAddress = item.origin_data.address;
    const originPhone = item.origin_data.phone;
    const originEmail = item.origin_data.email;

    if (item.destination_data === null) {
      item.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    // const destinationTitle = formatAddress(item.destination_data.address);
    const destinationName = item.destination_data.name || '-';
    const destinationAddress = item.destination_data.address;
    const destinationPhone = item.destination_data.phone;
    const destinationEmail = item.destination_data.email;

    const clientPresent = item.client_data ? true : false;
    const clientName = clientPresent ? item.client_data.name || '-' : '-';
    const clientAddress = clientPresent ? item.client_data.address : '';
    const clientPhone = clientPresent ? item.client_data.phone : '';
    const clientEmail = clientPresent ? item.client_data.email : '';


    return (
      <tr className={'tr-additional-info tr-first'}>
        <td colSpan={12} className={'td-no-padding'}>
          <table className={'table-no-padding'}>
            <tbody>
                <tr className={'tr-additional-info'}>
                  {/*<td>*/}
                  {/*<div className="custom-control custom-checkbox">*/}
                  {/*<input*/}
                  {/*type="checkbox"*/}
                  {/*className="custom-control-input"*/}
                  {/*id={item.id}*/}
                  {/*onChange={() => this.selectedItem(item.id, i)}*/}
                  {/*checked={!!selectedItem.includes(item.id)}*/}
                  {/*/>*/}
                  {/*<label className="custom-control-label" htmlFor={item.id}/>*/}
                  {/*</div>*/}
                  {/*</td>*/}
                  <td onClick={this.toggleRow.bind(this)} className={'td-is-open-load'}>
                    <img alt="dropdown arrow" src={'/assets/images/dropdown2.png'} className={this.state.open ? 'dropdown fa-rotate-90' : 'dropdown fa-rotate-0'} />
                  </td>
                  <td>{item.load_id || '-'}</td>
                  <td>
                    {pickUpDateDivided[0] === 'null' ? '-' :
                      `${pickUpDateDivided[0] || ''}-${pickUpDateDivided[1] || ''}-${pickUpDateDivided[2] || ''}`
                    }
                  </td>
                  <td>
                    {deliveryDateDivided[0] === 'null' ? '-' :
                      `${deliveryDateDivided[0] || ''}-${deliveryDateDivided[1] || ''}-${deliveryDateDivided[2] || ''}`
                    }
                  </td>
                  <td>{item.driver_id ? `${item.driver_data.first_name} ${item.driver_data.last_name}` : '-'}</td>
                  <td>
                    <div className="people-table-comment">
                      {priceMode === item.id ?
                        (<div className="people-table-comment-form">
                          <input
                            type="text"
                            className="form-control"
                            maxLength="10"
                            onChange={(e) => this.setState({priceText: e.target.value})}
                            value={priceMode ? priceText : item.hauling}
                          />
                          <p onClick={() => this.handlePrice(item)}>Save</p>
                          <span onClick={() => this.setState({priceMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            ${item.hauling || '-'}
                              <div className='people-table-comment-form-btns'>
                                <p onClick={() => this.setState({priceMode: item.id, priceText: item.hauling})}>{'Edit'}</p>
                              </div>
                          </div>
                        )}
                    </div>
                  </td>
                  <td>{item.origin_data.name || '-'}</td>
                  <td>{item.origin_data.address}</td>
                  <td>{item.destination_data.name || '-'}</td>
                  <td>{item.destination_data.address}</td>
                  <td style={{ width: 0 }}>
                    {/* <div className="people-table-comment">
                      {commentMode === item.id ?
                        (<div className="people-table-comment-form">
                          <input
                            type="text"
                            className="form-control"
                            maxLength="10"
                            onChange={(e) => this.setState({commentText: e.target.value})}
                            value={commentMode ? commentText : item.note}
                          />
                          <p onClick={() => this.handleComment(item.id)}>Save</p>
                          <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note || ''}</div>
                            {item.note ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                {'Add'}
                              </p>
                            }
                          </div>
                        )}
                    </div> */}
                  </td>
                  <td>
                    <div className="people-table-btn-group people-table-btn-group-single-delete">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a
                        href={LoadsAPI.GetDownloadPDF(item.id)}
                        className="people-table-btn-group-icon fas fa-file-pdf" title="Download"
                      />
                      <div className="people-table-btn-group-icon fas fa-trash" title="delete" onClick={() => this.openPopup(item.id)}/>
                    </div>
                  </td>
                </tr>
                <tr className={isOpen ? 'openTrRow': 'hiddenTrRow'}>
                  <td className={'td-no-padding ' + isOpen ? 'openTdRow': 'hiddenTdRow'} colSpan={12}>
                    <div className={isOpen ? 'openRow': 'hiddenRow'}>
                    <div className={'carRow'}>
                            <span className="car_detail_box">
                            {this.state.carData.map((car) => {
                              return (
                                <span className="load_details_header" onClick={() => googleSearch((car.year || '') + " " + (car.car_maker_name || '') + " " + (car.car_model_name || '') + " " + (car.car_type ? car.car_type : ''))}>
                                  <strong>{(car.year || '')} {(car.car_maker_name || '')} {(car.car_model_name || '')}</strong> <span className="rowTitle">{(car.car_type ? ('Type: ' + car.car_type) : '')}</span> <span className="rowTitle">{(car.vin ? ('VIN: ' + car.vin) : '')}</span>
                                </span>
                              )
                            })}
                            <span className="load_details-btn" onClick={e => openOnlineBol(item.id)}>Online BOL</span>
                            </span>
                            <div className="rowContainer">
                            <div className="rowOuter" style={{ width: '33%' }}>
                              <ul>
                                <li className="rowSubtitle">ORIGIN</li>
                                <li onClick={() => googleSearch(originName)}><strong>{originName}</strong></li>
                                <li onClick={() => googleMapSearch(originAddress)}>{originAddress}</li>
                                <li onClick={() => phoneCall(originPhone)}>{originPhone ? 'Tel. ' + originPhone : ''}</li>
                                <li onClick={() => mailTo(originEmail, originName)}>{originEmail ? 'Email: ' + originEmail : ''}</li>
                              </ul>
                              {/* {item.note_dispatch ? ( */}
                                <div className="people-table-comment"><br/>
                                <div className="rowSubtitle">DISPATCH INSTRUCTIONS</div>
                      {/* {commentMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="textarea"
                            className="form-control"
                            cols="40"
                            rows="6"
                            maxLength={"3777"}
                            onChange={(e) => this.setState({commentText: e.target.value})}
                            value={commentMode ? commentText : item.note}
                          />
                          <p onClick={() => this.handleComment(item.id)}>Save</p>
                          <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                        </div>)
                        : ( */}
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note_dispatch || 'Not provided.'}</div>
                            {/* {item.note ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                {'Add'}
                              </p>
                            } */}
                          </div>
                        {/* )}} */}
                    </div>
                    {/* ) : null} */}
                            </div>
                            <div className="rowInner"  style={{ width: '33%' }}>
                            <ul>
                                <li className="rowSubtitle">DESTINATION</li>
                                <li onClick={() => googleSearch(destinationName)}><strong>{destinationName}</strong></li>
                                <li onClick={() => googleMapSearch(destinationAddress)}>{destinationAddress}</li>
                                <li onClick={() => phoneCall(destinationPhone)}>{destinationPhone ? 'Tel. ' + destinationPhone : ''}</li>
                                <li onClick={() => mailTo(destinationEmail, destinationName)}>{destinationEmail ? 'Email: ' + destinationEmail : ''}</li>
                              </ul>
                              {/* {item.note_release ? ( */}
                                <div className="people-table-comment"><br/>
                                <div className="rowSubtitle">DELIVERY INSTRUCTIONS</div>
                      {/* {commentMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="text"
                            className="form-control"
                            // maxLength="10"
                            cols="40"
                            rows="6"
                            onChange={(e) => this.setState({commentText: e.target.value})}
                            value={commentMode ? commentText : item.note}
                          />
                          <p onClick={() => this.handleComment(item.id)}>Save</p>
                          <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                        </div>)
                        : ( */}
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note_release || 'Not provided.'}</div>
                            {/* {item.note ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                {'Add'}
                              </p>
                            } */}
                          </div>
                        {/* )} */}
                    </div>
                    {/* ) : null } */}
                    </div>
                            
                              <div className="rowLast" style={{ width: '33%' }}>
                              { clientPresent ?
                                <ul>
                                  <li className="rowSubtitle">CLIENT</li>
                                  <li onClick={() => googleSearch(clientName)}><strong>{clientName}</strong></li>
                                  <li onClick={() => googleMapSearch(clientAddress)}>{clientAddress}</li>
                                  <li onClick={() => phoneCall(clientPhone)}>{clientPhone ? 'Tel. ' + clientPhone : ''}</li>
                                  <li onClick={() => mailTo(clientEmail, clientName)}>{clientEmail ? 'Email: ' + clientEmail : ''}</li>
                                </ul>
                              : null }
                              { clientPresent ? <br/> : null }
                                <div className="people-table-comment">
                                <div className="rowSubtitle">NOTE</div>
                      {commentMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="text"
                            className="form-control"
                            // maxLength="10"
                            cols="40"
                            // rows="6"
                            rows={Math.max(4, Math.ceil((commentText ? commentText.length : 0) / 40))}
                            onChange={(e) => this.setState({commentText: e.target.value})}
                            value={commentMode ? commentText : item.note}
                          />
                          <p onClick={() => this.handleComment(item.id)}>Save</p>
                          <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note || ''}</div>
                            {item.note ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                {'Add'}
                              </p>
                            }
                          </div>
                        )}
                    </div>
                    </div>

                            </div>
                          </div>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>

        </td>
      </tr>
    )
  }
}

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loads: [],
      page: 1,
      initialPage: 0,
      limit: 10,
      delayed: false,
      driver_id: null,
      date_from: '',
      date_to: '',
      total: 0,
      total_sum: 0,
      status: 'history',
      popupOpen: false,
      selectedItem: [],
      transporters: [],
      defaultSelectValue: {label: 'All transporters', value: ''}
    };
  }

  setStateLoads = (data) => {
    this.setState({loads: data.data.data, total: data.data.data_total_count, total_sum: data.data.data_total_sum})
  }

  getLoads = async () => {
    const {page, limit, status} = this.state
    const data = await LoadsAPI.GetLoadsData({page, limit, status})
    this.setStateLoads(data)
  }

  getLoadsWithFilter = async (page) => {
    const { limit, status, delayed, driver_id, startDate, endDate } = this.state
    const data = await LoadsAPI.GetLoadsData({
      page, limit, status,
      ...(delayed ? { delayed: true } : {}),
      ...(driver_id ? { driver_id } : {}),
      ...(startDate ? { date_from: moment(startDate).format('YYYY-MM-DD') } : {}),
      ...(endDate ? { date_to: moment(endDate).format('YYYY-MM-DD') } : {})
    })
    return data
  }

  async componentDidMount() {
    this.getLoads()
    const data = await TransportersAPI.GetTransportersStatWithLoad()
    const transportersStatusFilter = data.data.data.filter(item => item.status !== 'invited')
    const transporters = transportersStatusFilter.map((item) => {
      return {value: item.id, label: `${item.name}`, id: item.id}
    })
    transporters.unshift({label: 'All transporters', value: ''})
    this.setState({transporters})
  }

  selectedItem = (id, i) => {
    const {selectedItem} = this.state;

    const indexId = selectedItem.indexOf(id)

    if (selectedItem.includes(id)) {
      selectedItem.splice(indexId, 1)
    } else {
      selectedItem.push(id)
    }

    this.setState({selectedItem})
  }

  openPopup = (id) => {
    this.setState({popupOpen: true, popupItemId: id})
  }

  deleteLoad = async () => {
    const { popupItemId, page, limit, status } = this.state
    await LoadsAPI.DeleteLoad(popupItemId)
    const data = await LoadsAPI.GetLoadsData({page, limit, status})
    this.setState({loads: data.data.data, total: data.data.data_total_count, total_sum: data.data.data_total_sum, popupOpen: false, popupItemId: ''})
  }

  handlePageChange = async (page) => {
    const data = await this.getLoadsWithFilter(page.selected + 1)
    this.setState({ page: page.selected + 1, loads: data.data.data, total_sum: data.data.data_total_sum, initialPage: page.selected })
  }

  searching = async (value) => {
    let {delayTimer} = this.state
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value,
      status: 'history'
    }

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {
      const data = value.length >= 3
        ? await LoadsAPI.SearchLoad(paginationSearch)
        : await LoadsAPI.GetLoadsData({page: 1, limit: 10, status: 'history'})
      this.setState({loads: data.data.data, total: data.data.data_total_count, total_sum: data.data.data_total_sum})
    }, 1500)

    this.setState({delayTimer})
  }

  handleDelayed = async (e) => {
    const { target: { checked } } = e;
    const { limit, status, driver_id, startDate, endDate } = this.state
    const data = await LoadsAPI.GetLoadsData({
      page: 1, limit, status,
      ...(checked ? { delayed: checked } : {}),
      ...(driver_id ? { driver_id } : {}),
      ...(startDate ? { date_from: moment(startDate).format('YYYY-MM-DD') } : {}),
      ...(endDate ? { date_to: moment(endDate).format('YYYY-MM-DD') } : {})
    })
    this.setState({delayed: !!checked, initialPage: 0})
    this.setStateLoads(data)
  }

  handleDriver = async (value) => {
    const { limit, status, delayed, startDate, endDate } = this.state
    const data = await LoadsAPI.GetLoadsData({
      page: 1, limit, status,
      ...(delayed ? {delayed: true} : {}),
      ...(value.id ? {driver_id: value.id} : {}),
      ...(startDate ? {date_from: moment(startDate).format('YYYY-MM-DD')} : {}),
      ...(endDate ? {date_to: moment(endDate).format('YYYY-MM-DD')} : {})
    })
    this.setState({ driver_id: value.id || null, initialPage: 0 })
    this.setStateLoads(data)
  }

  handleDeliveryDate = async ({startDate, endDate}) => {
    const { limit, status, delayed, driver_id } = this.state
    const data = await LoadsAPI.GetLoadsData({
      page: 1, limit, status,
      ...(delayed ? {delayed: true} : {}),
      ...(startDate ? {date_from: moment(startDate).format('YYYY-MM-DD')} : {}),
      ...(endDate ? {date_to: moment(endDate).format('YYYY-MM-DD')} : {}),
      ...(driver_id ? {driver_id} : {})
    })
    this.setState({startDate, endDate, initialPage: 0})
    this.setStateLoads(data)
  }

  handleExportCSV = async () => {
    const {page, limit, status, delayed, driver_id, startDate, endDate} = this.state
    const data = await LoadsAPI.GetLoadsData({
      page, limit, status,
      ...(delayed ? {delayed: true} : {}),
      ...(driver_id ? {driver_id} : {}),
      ...(startDate ? { date_from: moment(startDate).format('YYYY-MM-DD') } : {}),
      ...(endDate ? { date_to: moment(endDate).format('YYYY-MM-DD') } : {}),
      to_csv: true
    })
    downloadCsv(data.data, 'exportedHistoryLoads')
  }

  setStartDate = (date) => {
    // console.log('>>> setStartDate', date)
    // this.setState({startDate: date})
    this.handleDeliveryDate({startDate: date, endDate: this.state.endDate})
  }

  setEndDate = (date) => {
    // console.log('>>> setEndDate', date)
    // this.setState({endDate: date})
    this.handleDeliveryDate({endDate: date, startDate: this.state.startDate})
  }

  cleanDates () {
    this.handleDeliveryDate({startDate: '', endDate: ''})
  }

  render() {
    const {loads, total_sum, limit, total, popupOpen, transporters, defaultSelectValue, delayed, startDate, endDate} = this.state
    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'history'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">History</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">History</li>
                    </ol>
                    <div className="main-content-header-search">
                      <input
                        className="form-control" type="search" id="example-text-input"
                        onChange={(e) => this.searching(e.target.value)}
                        placeholder="Search by load number, pick up date, delivery date, pick up address, origin address..."
                      />
                    </div>
                  </div>

                  <div className="main-content-header-right">
                    <Link to="/load">
                      <button className="btn custom-btn main-content-header-btn">Create Load</button>
                    </Link>
                  </div>

                </div>

                <div className="dashboard-content">

                  <div className="people-table people-table-history">
                    <div className="people-table-header">
                      <div className="people-table-header-title">My History</div>
                      <ul className="loads-table-header header-first">
                        <li>
                          <div className="people-table-header-filter">
                            <div className="custom-form-control-wrap">
                              <Select
                                className="custom-form-control-select-drivers _filters"
                                defaultValue={defaultSelectValue}
                                onChange={this.handleDriver}
                                options={transporters}
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="loads-table-header header-second">
                              <li className="datepicker-item">
                                <DatePicker
                                  className={'form-control-datepicker-range _filters'}
                                  dateFormat="yyyy-MM-dd"
                                  selected={startDate}
                                  onChange={date => this.setStartDate(date)}
                                  selectsStart
                                  startDate={startDate}
                                  endDate={endDate}
                                  placeholderText="from..."
                                />
                              </li>
                              <li className="datepicker-item">
                                <span className="dates-clean-up fas fa-remove" title="clean" onClick={() => { this.cleanDates()}}/>
                                <DatePicker
                                  className={'form-control-datepicker-range _filters'}
                                  dateFormat="yyyy-MM-dd"
                                  selected={endDate}
                                  onChange={date => this.setEndDate(date)}
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  placeholderText="to..."
                                />
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <input  className={'delayed_checkbox'} defaultChecked={delayed} ref="delayed" type={'checkbox'} onChange={this.handleDelayed}/>  show delayed loads only
                          </div>
                        </li>

                      </ul>
                      <div className="people-table-header-search">{total} search results
                        <div className={'exportHistoryCSV'}>
                          <div type="submit" onClick={this.handleExportCSV}><img className={'export-csv-image'} src="assets/images/exportCSV.png" alt="Download CSV" /></div>
                        </div>
                      </div>
                    </div>
                    <table>
                      <thead>
                      <tr>
                        <th>{''}</th>
                        <th>Load number</th>
                        <th>Pick up date</th>
                        <th>Delivery date</th>
                        <th>Driver</th>
                        <th>Price</th>
                        <th>Origin</th>
                        <th>Origin Address</th>
                        <th>Destination</th>
                        <th>Destination Address</th>
                        <th style={{ width: 0 }}>
                          {/* Make Note */}
                        </th>
                        <th>{''}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {loads.map((item, i) => {
                        return (
                          <RowItem key={uuidv4()} item={item} openPopup={this.openPopup} getLoads={this.getLoads}/>
                        )
                      })}
                      </tbody>
                    </table>

                    <table className={'table-no-padding table-total-sum'}>
                      <tbody>
                      <tr>
                        <td></td>
                        <td colSpan={4}>All time Sum</td>
                        <td>{ total_sum ? (
                          <CurrencyFormat value={total_sum} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        ) : '-' }</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      </tbody>
                    </table>
                    <ReactPaginate
                      forceSelected={this.state.initialPage}
                      forcePage={this.state.initialPage}
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      marginPagesDisplayed={2}
                      containerClassName={'pagination custom-pagination'}
                      subContainerClassName={'pages pagination '}
                      activeClassName={'active'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item disabled'}
                      breakLinkClassName={'page-link disabled'}
                      onPageChange={this.handlePageChange}
                      pageCount={Math.ceil(total / limit)}
                    />

                    <div className="people-table-footer">
                      {/*<button className="btn btn-secondary">Save as pdf</button>*/}
                      {/*<button className="btn custom-btn">Create similar Load</button>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {popupOpen ? (
            <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">Delete load</h5>
                    <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                      The load will be deleted permanently from history and driver's mobile app. <br /> Proceed?
                    </p>
                    <Button className="btn btn-primary w-md custom-btn" onClick={this.deleteLoad}>Yes</Button>
                    <Button className="btn w-md" onClick={() => this.setState({popupOpen: false})}>No</Button>
                  </div>
                </div>
              </div>
            </div>) : null}
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {}
}

const mapDispatchtoProps = dispatch => {
  return {}
}

export default connect(mapStatetoProps, mapDispatchtoProps)(History);
