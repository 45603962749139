import config from '../config';

export function googleSearch(query) {
    window.open("https://google.com/search?q=" + encodeURIComponent(query));
}

export function googleMapSearch(query) {
    window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(query));
}

export function googleMapRoute(origin, destination) {
    window.open("https://www.google.com/maps/dir/" + encodeURIComponent(origin) + "/" + encodeURIComponent(destination));
}

export function phoneCall(number) {
    window.open("tel:" + number, "_self");
}

export function mailTo(email, clientName) {
    const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
    const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

    window.open(`mailto:${email}?subject=Mail from GoForIt Dispatcher&body=Dear ${clientName}, ... ${userName} ${lastName}`, "_self");
}

export function openOnlineBol(loadId) {
    const userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''

    window.open(`${config.serverUrl}/commons/show-photo/${loadId}/${userId}`);
}

export function openTutorialVideo() {
    window.open(`https://www.youtube.com/watch?v=anUQR1KUXWY`);
}

export function openHowToUseTutorial() {
    window.open(`https://goforitapp.com/how-to-use-as-a-dealer-or-broker/`);
}

export function openCarrierApplicationAndroid() {
    window.open(`https://play.google.com/store/apps/details?id=com.goforit&hl=en_US&gl=US&pli=1`);
}

export function openCarrierApplicationIos() {
    window.open(`https://apps.apple.com/us/app/goforit-carrier/id1530917252`);
}