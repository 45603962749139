/* eslint-disable no-undef */
import React, { Component } from "react";
import GoogleMap from "../GoogleMap";
import LoadsAPI from "../../../api/loadsAPI";
import TransportersAPI from "../../../api/transportersAPI";
import { onMapLoad } from "./helpers";
import OpportunitiesAPI from "../../../api/opportunitiesApi";
import { checkSubscription } from "../../../helpers/CheckSubscription";
import { withSnackbar } from "notistack";
import { showNotification } from "../../../helpers/showNotification";

let socket;

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      data: [],
      delayed: props.delayed,
      driverId: props.driverId,
      markers: [],
      markersRelation: [],
      mapReloader: 0,
      opportunities: {},
      isSubscription: false
    };
    this.sendOfferByDriver = this.sendOfferByDriver.bind(this);
  }

  async getData() {
    this.setState({ data: [] });

    const result = await checkSubscription();

    const { data: transporterGeo } = await TransportersAPI.GetGeo();

    const { data } = await LoadsAPI.GetLoadsGeo({
      ...(this.props.delayed ? { delayed: true } : {}),
      ...(this.props.driverId ? { driver_id: this.props.driverId } : {}),
    });
    if (data) {
      this.setState({
        ...this.state,
        data: data.data,
        ...(transporterGeo ? { transporters: transporterGeo.data } : {}),
        isSubscription: result
      });
    }
  }

  async componentDidMount() {
    if (!socket) {
      socket = new WebSocket(
        process.env.REACT_APP_SOCKET_SERVER_URL,
        JSON.parse(localStorage.getItem("userInfo"))
          ? JSON.parse(localStorage.getItem("userInfo")).auth_key
          : ""
      );

      socket.onopen = function (evt) {
        console.log(">>> LOG: socket onopen");
      };
      socket.onclose = function (evt) {
        console.log(">>> LOG: socket onclose");
      };
    }

    await this.getData();
    // this.interval = setInterval(() => { this.getData() }, 1000 * 60 * 10)
  }

  componentWillUnmount() {
    // clearInterval(this.interval)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.delayed !== prevProps.delayed ||
      this.props.driverId !== prevProps.driverId
    ) {
      this.getData();
      // test 2
    }
  }

  async sendOfferByDriver(loadsId, driverId) {
    const _showNotification = (variant, message) => {
      showNotification(message, variant, this.props);
    }
      await loadsId.map(loadId => OpportunitiesAPI.createOfferByOwner(loadId, driverId, _showNotification));
      this.getData();
  }

  render() {
    if (this.state.data.length === 0) {
      // return null;
    }

    return (
      <GoogleMap
        id="myMap"
        options={{ center: { lat: 41, lng: -95 }, zoom: 5 }}
        key={this.state.data.length}
        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        libraries="geometry,drawing,places"
        language="en"
        region="US"
        dataLength={this.state.data.length}
        onMapLoad={onMapLoad(this.state, socket, this.props.setIsOpenToaster, this.sendOfferByDriver)}
      />
    );
  }
}

export default withSnackbar(Map);
