/* eslint-disable no-undef */

import MarkerWithLabel from "markerwithlabel";
import MarkerClusterer from "@googlemaps/markerclustererplus";

const unstackMarkers = (marker, otherMarkers) => {
  const positionKey = marker.startPoint ? "startPoint" : "position";

  let { lat, lon } = marker[positionKey];
  const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
  if (otherMarkers.length !== 0) {
    for (let i = 0; i < otherMarkers.length; i++) {
      const existingMarker = otherMarkers[i];
      const pos = existingMarker.getPosition();

      if (latLng.equals(pos)) {
        const a = 360.0 / otherMarkers.length;
        marker[positionKey].lat =
          pos.lat() + -0.00004 * Math.cos(((+a * (i + 1)) / 180) * Math.PI); // Х
        marker[positionKey].lon =
          pos.lng() + -0.00004 * Math.sin(((+a * (i + 1)) / 180) * Math.PI); // Y
      }
    }
  }
};

const getMarker = (data, map) => {
  const start = {
    lat: parseFloat(data.startPoint.lat),
    lng: parseFloat(data.startPoint.lon),
  };

  const statuses = {
    new: "red",
    assigned: "yellow",
    picked: "black",
    dispatched: "purple",
  };

  let MWithLabel = MarkerWithLabel(google.maps);
  return new MWithLabel({
    position: start,
    map: map,
    icon: {
      url:
        "../assets/images/map/load_" +
        statuses[data.tooltip.status] +
        "_car.svg",
      size: new google.maps.Size(64, 64),
      class: "custom-marker-icon",
    },
    labelContent: data.tooltip.priority || "",
    labelAnchor: new google.maps.Point(46, 20),
    labelClass: data.tooltip.priority ? "label-map-priority" : "",
    labelInBackground: true,
  });
};

const getContent = (data, newDistance = false) => {
  let name;

  ['assigned', "picked"].includes(data.tooltip.status)
      ? name = `${data.tooltip.first_name} ${data.tooltip.last_name}`
      : name = data.tooltip.dispatcher_company
          ? `${data.tooltip.dispatcher_company}`
          : `${data.tooltip.first_name} ${data.tooltip.last_name}`;

  const style = `custom-marker-status custom-marker-status-${data.tooltip.status}`;

  let milesBlock = `<div class="custom-marker-blue">
                Miles: <b>${parseFloat(data.tooltip.miles)}</b>
                <div class="car">Cars: ${data.tooltip.car_count}</div>
            </div>`;

  if (newDistance) {
    milesBlock = `<div class="custom-marker-blue">
                    Miles: <b>${parseFloat(data.tooltip.miles)}</b>
                </div>
                <div class="custom-marker-red">
                    New Miles: <b>${totalDistance}</b>
                    <div class="car">Cars: ${data.tooltip.car_count}</div>
                </div>`;
  }

  let phone;

  ['assigned', "picked"].includes(data.tooltip.status)
      ? phone = `${data.tooltip.phone}`
      : phone = data.tooltip.dispatcher_company
          ? `${data.tooltip.dispatcher_phone}`
          : `${data.tooltip.phone}`;

  let phoneBlock = +phone ? `<div class="custom-marker-phone">Phone:&nbsp;${phone ? phone.replace(/ /g, "\u00A0") : '-'}</div>` : '';

  return `<div class="custom-marker">
            <div class="custom-marker-name">${name ? name.replace(/ /g, "\u00A0") : 'N/A'}</div>
            ${phoneBlock}
            <div class="custom-marker-code">${data.tooltip.load_id ? data.tooltip.load_id.replace(/ /g, "\u00A0") : "-"}</div>
            <div class="${style}">${data.tooltip.status}</div>
            <div class="custom-marker-direction">${data.tooltip.origin.address}
              <br/>${data.tooltip.destination.address}
            </div>
            <div class="custom-marker-blue">Price: <b>$${data.tooltip.hauling}</b></div>
            ${milesBlock}
          </div>`;
};

const getTransporterMarker = (data, map) => {
  const lat = parseFloat(data.position.lat);
  const lng = parseFloat(data.position.lon);

  if (!lat || !lng) return;
  const position = {
    lat: parseFloat(data.position.lat),
    lng: parseFloat(data.position.lon),
  };

  let MWithLabel = MarkerWithLabel(google.maps);

  // console.log(">>> data", data.tooltip.avatar);

  return new MWithLabel({
    position: position,
    map: map,
    icon: {
      url: data.tooltip.avatar,
      size: new google.maps.Size(64, 64),
      // anchor: new google.maps.Point(0, 32),
      class: "custom-marker-icon",
    },

    driver_id: data.tooltip.id,

    labelContent: "", // data.tooltip.avatar ? '' : data.tooltip.last_name.charAt(0) + '.' + data.tooltip.first_name.charAt(0)+ '.',
    labelAnchor: new google.maps.Point(9, 46),
    labelClass: "labels",
    labelInBackground: false,
    duration: 1000,
    easing: "easeOutSine",
  });
};

const getContentCar = (
  loads,
  setIsOpenToaster,
  driver_id,
  sendOfferByDriver,
  isSubscription,
  driver
) => {
  const renderOption = loads.map((load) => {
    const isNewLoad = load.tooltip.status === "new";
    return (
      isNewLoad &&
      `<option value=${load.tooltip.id}>${load.tooltip.load_id}</option>`
    );
  });
  window.sendOffer = function () {
    const loadsId = $("#load-id").val();
    if (!loadsId.length) {
      alert("Please select load firs");
      return;
    }
    sendOfferByDriver(loadsId, driver_id);
  };

  const {
    phone,
    delayed,
    car_count,
    last_name,
    first_name,
    enclosed_trailer,
    dispatcher_phone,
    dispatcher_last_name,
    dispatcher_first_name
  } = driver.tooltip;

  // console.log({driver})

  const name =
    dispatcher_first_name && dispatcher_last_name
      ? `${dispatcher_first_name} ${dispatcher_last_name}`
      : `${first_name} ${last_name}`;

  const mobilePhone =
      dispatcher_first_name && dispatcher_last_name
          ? dispatcher_phone
          : phone;

  return `<div class="custom-marker driver">
           <div class="custom-marker-name-blue">${name ? name.replace(/ /g, "\u00A0") : 'N/A'}</div>
           <div class="custom-marker-phone-blue">Phone:&nbsp;${mobilePhone ? mobilePhone.replace(/ /g, "\u00A0") : '-'}</div>
           <ul class="list">
               <li><span>Driver:</span>${first_name} ${last_name}</li>
               <li class="phone-blue"><span>Phone:</span>${phone || '-'}</li>
               <li><span>Transported:</span>${car_count}</li>
               <li><span>Delays:</span>${delayed}%</li>
               <li><span>Enclosed trailer:</span>${
                 enclosed_trailer ? "Yes" : "No"
               }</li>
           </ul>
           
            <select name="load-select" id="load-id" class="custom-select" multiple="multiple">
               ${renderOption}
            </select>

            
           <div class="button-container">
                ${
                  isSubscription
                    ? '<button id="send-offer-btn" class="button" onclick="window.sendOffer()">Send Offer</button>'
                    : '<button class="button disable" onclick="window.sendOffer()" disabled>Send Offer</button>'
                }
 
<!--           <div class="buttons-container">-->
<!--               <button>Offer sent</button>-->
<!--               <button class="secondary-button">Cancel</button>-->
<!--           </div>-->
           </div>
         </div>`;
};

export const onMapLoad = (
  state,
  socket,
  setIsOpenToaster,
  sendOfferByDriver
) => {
  return (map) => {
    const { transporters, isSubscription } = state;
    const directionsService = new google.maps.DirectionsService(),
      directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        draggable: true,
      });
    let markerCluster = getMarkerCluster([]);
    let markers = state.markers;

    const transporterMarkers = [];

    let activeTransporterId = null;

    let loadMarkers = [];

    let showRouts = {};
    let infoWindows = {};
    let totalDistance = 0;
    const infoWind = new google.maps.InfoWindow({ content: "" });
    const infoWindow = new google.maps.InfoWindow({ content: "" });
    const infoWindowTransporter = new google.maps.InfoWindow({ content: "" });
    let currentMarker = null;
    let lastMarker = null;

    directionsRenderer.addListener("directions_changed", function () {
      const response = directionsRenderer.getDirections();
      computeTotalDistance(response);
      if (response.request.waypoints) {
        const location = response.request.waypoints[0].location;
        const start = {
          lat: parseFloat(location.lat()),
          lng: parseFloat(location.lng()),
        };
        infoWind.close();
        infoWind.setPosition(start);
        infoWind.setContent(getContent(currentMarker, true));
        infoWind.open(map);
      }
    });

    let i = 0,
      newMarkers = [];

    state.data.forEach((marker) => {
      showRouts = { ...showRouts, [marker.tooltip.id]: false };
      infoWindows[marker.tooltip.id] = new google.maps.InfoWindow({
        content: "",
      });
      if (marker.tooltip.driver_id && marker.tooltip.status === "picked") {
        if (!newMarkers[marker.tooltip.driver_id]) {
          newMarkers[marker.tooltip.driver_id] = [i];
        } else {
          newMarkers[marker.tooltip.driver_id].push(i);
        }
      }
      i++;
    });

    // this.setState({ markersRelation: newMarkers })

    //console.log('>>> markersRelation: ', this.state.markersRelation)
    const _this = this;
    // socket.onmessage = function (evt) {
    //   console.log('dataSenderId', data)
    //
    //   const data = JSON.parse(evt.data)
    //   console.log('[SOCKET IN]', data)
    //   if (data.SenderId) {
    //     // data.SenderId = 1537 // TODO TMP
    //     const indexMarker = transporterMarkers.find(el => {
    //       return el.driver_id === data.SenderId
    //     })
    //     if (indexMarker) {
    //       const new_marker_position = new google.maps.LatLng(data.Geo.Lat, data.Geo.Lon)
    //       indexMarker.setPosition(new_marker_position)
    //     }
    //   }
    // }
    socket.onmessage = function (evt) {
      const data = JSON.parse(evt.data);
      if (data.SenderId) {
        const indexArr = newMarkers[data.SenderId];
        // console.log('>>> LOG: index', data.SenderId, index, markers[index])
        // console.log('>>> LOG: this.state.data',  _this.state.markers[index])
        // console.log('>>> LOG: this.state.data',  _this.state.data[index])
        if (indexArr) {
          indexArr.forEach((index) => {
            console.log(">>> index: ", index);
            //  && markers[index]
            // markerCluster.clearMarkers()
            const new_marker_position = new google.maps.LatLng(
              data.Geo.Lat,
              data.Geo.Lon
            );
            console.log(">>> LOG: ", _this.state.markers[index]);
            // markers[index].setPosition(new_marker_position)
            _this.state.markers[index].setPosition(new_marker_position);
            // markers[index].setPosition(new_marker_position)
            // transformMarkerPosition(markers[index])
            attachClickToMarker(
              _this.state.markers[index],
              _this.state.data[index]
            );
            markerCluster.repaint();
          });
        }
      }
    };

    transporters.forEach(async (transporter) => {
      // if (!marker.position.lat || !marker.position.lon) return
      // console.log('>>> === : transporters marker', marker, marker.tooltip.id)
      unstackMarkers(transporter, transporterMarkers);

      const googleMarker = getTransporterMarker(transporter, map);

      if (googleMarker) {
        attachClickToCarMarker(googleMarker, transporter);
        transporterMarkers.push(googleMarker);
      }
    });

    loadMarkers.forEach((marker) => marker.setVisible(true));

    function attachClickToCarMarker(googleMarker, marker) {
      googleMarker.addListener("click", function () {
        if (marker.tooltip.id === activeTransporterId) {
          activeTransporterId = null;
          loadMarkers.forEach((marker) => marker.setVisible(true));
        } else {
          activeTransporterId = marker.tooltip.id;
          loadMarkers.forEach((loadMarker, index) => {
            loadMarker.setVisible(
              loads[index].tooltip.driver_id === activeTransporterId
            );
          });
        }

        const driver = transporters.find(
          (transporter) => transporter.tooltip.id === googleMarker.driver_id
        );

        infoWindowTransporter.close();
        infoWindowTransporter.setContent(
          getContentCar(
            state.data,
            setIsOpenToaster,
            googleMarker.driver_id,
            sendOfferByDriver,
            isSubscription,
            driver
          )
        );
        infoWindowTransporter.open(map, googleMarker);

        map.setZoom(15);
        map.panTo(infoWindowTransporter.position);
        setTimeout(() => {
          $(".custom-select").select2();
        }, 0);
      });
    }

    state.data.forEach((marker) => {
      // console.log('>>> LOG: marker', marker)
      transformMarkerPosition(marker);
      const googleMarker = getMarker(marker, map);
      attachClickToMarker(googleMarker, marker);
      markerCluster.addMarker(googleMarker);
      markers.push(googleMarker);
    });

    function transformMarkerPosition(marker) {
      let {
        startPoint: { lat, lon },
      } = marker;
      const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
      if (markers.length !== 0) {
        for (let i = 0; i < markers.length; i++) {
          const existingMarker = markers[i],
            pos = existingMarker.getPosition();

          if (latLng.equals(pos)) {
            const a = 360.0 / markers.length;
            marker.startPoint.lat =
              pos.lat() + -0.00004 * Math.cos(((+a * (i + 1)) / 180) * Math.PI); // Х
            marker.startPoint.lon =
              pos.lng() + -0.00004 * Math.sin(((+a * (i + 1)) / 180) * Math.PI); // Y
          }
        }
      }
    }

    function attachClickToMarker(googleMarker, marker) {
      // googleMarker.removeListener('click')
      googleMarker.addListener("click", function () {
        infoWindow.close();
        infoWindow.setContent(getContent(marker));
        infoWindow.open(map, googleMarker);
        if (!showRouts[marker.tooltip.id]) {
          routeToMap(marker, true);
        } else {
          infoWind.close();
          showRouts[marker.tooltip.id] = false;
          infoWindow.close();
          //infoWindows[marker.tooltip.id].close()
          directionsRenderer.setMap(null);
          clearShowRouts();
          defaultMarkers();
        }
      });
    }

    function computeTotalDistance(result) {
      totalDistance = 0;
      let myroute = result.routes[0];
      for (let i = 0; i < myroute.legs.length; i++) {
        totalDistance += myroute.legs[i].distance.value;
      }
      totalDistance = (totalDistance / 1000 / 1.60934).toFixed(0);
    }

    function getMarkerCluster(markers) {
      return new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 10,
      });
    }

    function routeToMap(marker, addMarker = false) {
      currentMarker = marker;
      let {
        startPoint: { lat, lon },
      } = marker;
      let {
        endPoint: { lat: endLat, lon: endLon },
      } = marker;
      directionsService.route(
        {
          origin: { lat: parseFloat(lat), lng: parseFloat(lon) },
          destination: { lat: parseFloat(endLat), lng: parseFloat(endLon) },
          travelMode: google.maps.DirectionsTravelMode.DRIVING,
        },
        function (response, status) {
          showRouts[marker.tooltip.id] = true;
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(response);
            if (addMarker) {
              addFinishMarker(endLat, endLon);
            }
          } else {
            defaultMarkers();
            console.log("Directions request failed due to " + status);
          }
        }
      );
    }

    function clearShowRouts() {
      Object.keys(showRouts).forEach((key) => {
        showRouts[key] = false;
      });
    }

    function addFinishMarker(endLat, endLon) {
      const finishMarker = new google.maps.Marker({
        position: { lat: parseFloat(endLat), lng: parseFloat(endLon) },
        map: map,
        icon: {
          url: "../assets/images/finish-flag-64.svg",
          size: new google.maps.Size(64, 64),
          anchor: new google.maps.Point(16, 64),
        },
      });
      markerCluster.clearMarkers();
      markerCluster = getMarkerCluster(
        lastMarker
          ? markers.concat(finishMarker).concat(lastMarker)
          : markers.concat(finishMarker)
      );
      lastMarker = finishMarker;
    }

    function defaultMarkers() {
      markerCluster.clearMarkers();
      markerCluster = getMarkerCluster(markers);
    }

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input"),
      searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve more details for that place.
    searchBox.addListener("places_changed", function () {
      var places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  };
};
