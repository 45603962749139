import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import AUX from '../../hoc/Aux_';
import {Scrollbars} from 'react-custom-scrollbars';
import $ from 'jquery';

const MENU_ENLARGED_WIDTH = 1436

class sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Tab: '',
      SubTab: '',
      MoreTab: '',
      email_menu: false, ui_menu: false, form_menu: false, chart_menu: false,
      table_menu: false, icon_menu: false, map_menu: false, extra_menu: false, pages_menu: false, et_menu: false,
      width: $(window).innerWidth(), height: $(window).innerHeight(),
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  setActiveTab = (tab, subtab, moretab, e) => {
    this.setState({Tab: tab, SubTab: subtab, MoreTab: moretab});
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    const {page} = this.props
    this.setState({Tab: page})

    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
      if ($("body").hasClass("enlarged")) {
        $("#email_menu_hide").hide();
        $("#ui_menu_hide").hide();
        $("#form_menu_hide").hide();
        $("#chart_menu_hide").hide();
        $("#table_menu_hide").hide();
        $("#icon_menu_hide").hide();
        $("#map_menu_hide").hide();
        $("#page_menu_hide").hide();
        $("#extra_menu_hide").hide();
        $("#et_menu_hide").hide();
      } else {
        $("#email_menu_hide").show();
        $("#ui_menu_hide").show();
        $("#form_menu_hide").show();
        $("#chart_menu_hide").show();
        $("#table_menu_hide").show();
        $("#icon_menu_hide").show();
        $("#map_menu_hide").show();
        $("#page_menu_hide").show();
        $("#extra_menu_hide").show();
        $("#et_menu_hide").show();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    // console.log("this.state.width = " + this.state.width);

    if (this.state.width < MENU_ENLARGED_WIDTH) {
      $('body').addClass('enlarged');
    } else {
      $('body').removeClass('enlarged');
    }


    return (
      <AUX>
        <div className="left side-menu">
          <Scrollbars style={{height: 980}}>
            <div>
              <div id="sidebar-menu">
                <ul className="metismenu" id="side-menu">
                  <li>
                    <Link to="/dashboard" className={this.state.Tab === 'dashboard' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-view-dashboard"/>
                      <span> Home </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/loads" className={this.state.Tab === 'loads' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-view-list"/>
                      <span> My Orders </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/history" className={this.state.Tab === 'history' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-history"/>
                      <span> Delivered </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transporters" className={this.state.Tab === 'transporters' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-highway"/>
                      <span> Transporters </span>
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to="/clients" className={this.state.Tab === 'clients' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-briefcase-check"/>
                      <span> Clients </span>
                    </Link>
                  </li>
                  */}
                  <li>
                    <Link to="/settings" className={this.state.Tab === 'settings' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-account-settings"/>
                      <span> Settings </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/subscription" className={this.state.Tab === 'subscription' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-calendar-check"/>
                      <span> Subscription </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/help" className={this.state.Tab === 'help' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-help-circle-outline"/>
                      <span> Help </span>
                    </Link>
                  </li>
                </ul>

              </div>
              <div className="clearfix"/>
            </div>
          </Scrollbars>
        </div>
      </AUX>
    );
  }
}

export default withRouter(sidebar);
