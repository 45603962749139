import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./style.css";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import TransportersAPI from "../../../api/transportersAPI";
import { Button } from "reactstrap";
import LoadsAPI from "../../../api/loadsAPI";
import UsersAPI from "../../../api/userApi";
import PopupSubscription from "../PopupSubscription/PopupSubscription";
import { checkSubscription, showFindTransporter } from "../../../helpers/CheckSubscription";
import InfoPopup from "../InfoPopup/InfoPopup";
import { withSnackbar } from "notistack";
import { showNotification } from "../../../helpers/showNotification";
import SpeechBubble from '../SpeechBubble/SpeechBubble'

const MESSAGE = "Your invite was sent to the driver or his dispatcher";

class Loads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],

      loads: [],
      initialPage: 0,
      pageLoads: 1,
      limitLoads: 10,
      totalLoads: 0,
      statusLoads: "active",

      selectedItem: [],
      page: 1,
      limit: 10,
      total: 0,
      delayTimer: "",
      commentMode: null,
      commentText: "",
      paginationSearch: "",
      popupOpen: false,
      popupSelectLoadOpen: false,
      popupDeleteStatus: "",
      transporters_active: "",
      transporters_total: "",
      isOpenSubscriptionModal: false,
      isOpenPopup: false,
      popupMessage: '',
      awaitForRender: false,
      bubbleTransporterOpen: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  setStateLoads = (data) => {
    this.setState({
      loads: data.data.data,
      totalLoads: data.data.data_total_count,
    });
  };

  handlePageChange = async (page) => {
    const pageLoads = page.selected + 1,
      { limitLoads, statusLoads } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      pageLoads,
      limitLoads,
      statusLoads,
      no_offer: true,
    });
    this.setState({
      page: pageLoads,
      loads: data.data.data,
      initialPage: page.selected,
    });
  };

  async componentDidMount() {
    const { pageLoads, limitLoads, statusLoads } = this.state;
    const dataLoads = await LoadsAPI.GetLoadsData({
      pageLoads,
      limitLoads,
      statusLoads,
      no_offer: true,
    });
    this.setStateLoads(dataLoads);

    const { limit, page } = this.state;
    const data = await TransportersAPI.GetTransportersData({ page, limit });
    const userData = await UsersAPI.GetLicense();

    let transporters_active = "";
    let transporters_total = "";

    if (userData) {
      transporters_active = userData.data.data
        ? userData.data.data.current_active
        : 0;
      transporters_total = userData.data.data
        ? userData.data.data.current_amount
        : 0;
    }

    this.setState({
      awaitForRender: true,
      bubbleTransporterOpen: showFindTransporter(),
      transporters: data.data.data,
      total: data.data.data_total_count,
      transporters_active,
      transporters_total,
    });
  }

  selectedItem = (id, i) => {
    const { selectedItem } = this.state;
    const indexId = selectedItem.indexOf(id);

    if (selectedItem.includes(id)) {
      selectedItem.splice(indexId, 1);
    } else {
      selectedItem.push(id);
    }

    this.setState({ selectedItem });
  };

  // changeTransportersStatus = async (status) => {
  //   const {selectedItem, transporters, page, limit} = this.state
  //   const filteredTransporters = []
  //
  //   if (selectedItem.length) {
  //
  //     selectedItem.filter((elem) => {
  //       transporters.filter((item) => {
  //         if (item.status !== status && elem === item.id) {
  //           filteredTransporters.push(item.id)
  //         }
  //       })
  //     })
  //
  //     const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: filteredTransporters.join(',')})
  //
  //     if (res.status === 200) {
  //       const data = await TransportersAPI.GetTransportersData({page, limit});
  //       this.setState({transporters: data.data.data, total: data.data.data_total_count})
  //
  //     } else {
  //       this.setState({
  //         selectedItem: []
  //       })
  //     }
  //   }
  // }

  handlePageLoadsChange = async (page) => {
    const { limit } = this.state;
    const data = await TransportersAPI.GetTransportersData({
      page: page.selected + 1,
      limit,
    });
    this.setState({ page: page.selected + 1, transporters: data.data.data });
  };

  searching = async (value) => {
    let { delayTimer } = this.state;
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value,
    };

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {
      if (value.length >= 3) {
        const data = await TransportersAPI.SearchTransporters(paginationSearch);
        this.setState({
          transporters: data.data.data,
          total: data.data.data_total_count,
        });
      } else {
        const data = await TransportersAPI.GetTransportersData({
          page: 1,
          limit: 10,
        });
        this.setState({
          transporters: data.data.data,
          total: data.data.data_total_count,
        });
      }
    }, 1500);
    this.setState({ delayTimer, searchValue: value });
  };

  handleComment = async (id) => {
    const { limit, page, commentText } = this.state;

    const res = await TransportersAPI.SaveTransporterComment({
      driver: id,
      comment: commentText,
    });
    if (res.status === 200) {
      const data = await TransportersAPI.GetTransportersData({ page, limit });
      this.setState({
        commentMode: null,
        transporters: data.data.data,
        total: data.data.data_total_count,
      });
    } else {
      this.setState({ commentMode: null });
    }
  };

  deleteComment = async (id) => {
    const { page, limit } = this.state;
    const res = await TransportersAPI.DeleteTransporterComment(id);
    if (res.status === 200) {
      const data = await TransportersAPI.GetTransportersData({ page, limit });
      this.setState({
        commentMode: null,
        transporters: data.data.data,
        total: data.data.data_total_count,
      });
    }
  };

  sentInvite = async (id) => {
    const { searchValue } = this.state;
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: searchValue,
    };
    const res = await TransportersAPI.SendInviteToTransporter(id);
    if (res.status === 200) {
      const data = await TransportersAPI.SearchTransporters(paginationSearch);
      this.setState({
        transporters: data.data.data,
        total: data.data.data_total_count,
      });
    }
  };

  // unlinkTransporters = async () => {
  //   const {selectedItem, page, limit} = this.state
  //   const res = await TransportersAPI.UnlinkTransporter(selectedItem.join(','));
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.GetTransportersData({page, limit});
  //     this.setState({commentMode: null, transporters: data.data.data, total: data.data.data_total_count})
  //   }
  // }

  switchStatus = async (e, id, status) => {
    let { page, limit } = this.state;

    // console.log('>>> LOG: ', status, transporters_active, transporters_total)
    // if (transporters_active < transporters_total) {
    if (status !== 1) {
      // status !== 'assigned'

      // const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})
      const res = await TransportersAPI.FollowTransporter(id);

      if (res.status === 200) {
        /*
          const userData = await UsersAPI.GetLicense()
          if (userData) {
            active_transporters = userData.data.data.current_active
            total_transporters = userData.data.data.current_amount
          }
          */

        const data = await TransportersAPI.GetTransportersData({ page, limit });
        this.setState({
          transporters: data.data.data,
          total: data.data.data_total_count,
          // transporters_active: active_transporters,
          // transporters_total: total_transporters,
        });
      }
      // }
    } else {
      // status === 'active'
      const res = await TransportersAPI.AddBlackListTransporters(id);
      // const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})

      if (res === 200) {
        /*
        const userData = await UsersAPI.GetLicense()
        if (userData) {
          active_transporters = userData.data.data.current_active
          total_transporters = userData.data.data.current_amount
        }
        */
        const data = await TransportersAPI.GetTransportersData({ page, limit });
        this.setState({
          transporters: data.data.data,
          total: data.data.data_total_count,
          // transporters_active: active_transporters,
          // transporters_total: total_transporters,
        });
      }
    }
  };

  openPopup = (id, status) => {
    console.log(">>> openPopup: ", id, status);
    this.setState({
      popupOpen: true,
      popupItemId: id,
      popupDeleteStatus: status,
    });
  };

  openPopupSelectLoad = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    const isSubscription = await checkSubscription();
    isSubscription
      ? this.setState({
          popupSelectLoadOpen: true,
          popupItemId: item.id,
          selectedTransporterName: item.first_name + " " + item.last_name,
        })
      : this.setState({ isOpenSubscriptionModal: true });
  };

  closeModal = () => {
    this.setState({ isOpenSubscriptionModal: false });
  };

  addLoad = async (load_id) => {
    const { popupItemId, page } = this.state;
    console.log(">>> addLoad: ", load_id, popupItemId);
    const result = await TransportersAPI.AssignToTransporter(load_id, popupItemId);
    if(!result) {
      this.setState({
        isOpenPopup: true,
        popupSelectLoadOpen: false,
        popupMessage: 'You have already created offer'
      });
      return;
    }

    this.setState({
      isOpenPopup: true,
      popupSelectLoadOpen: false,
      popupMessage: MESSAGE
    });

    showNotification(MESSAGE, 'success', this.props);

    await this.handlePageChange(page);
  };

  deleteTransporter = async () => {
    const { popupItemId, page, limit, status } = this.state;

    await TransportersAPI.UnfollowTransporter(popupItemId);
    /*
    if (popupDeleteStatus === 'invited') {
      await TransportersAPI.RejectInviteTransporter(popupItemId)
    } else {
      await TransportersAPI.UnfollowTransporter(popupItemId)
    }
    */
    const data = await TransportersAPI.GetTransportersData({
      page,
      limit,
      status,
    });
    this.setState({
      transporters: data.data.data,
      total: data.data.data_total_count,
      popupOpen: false,
      popupItemId: "",
    });
  };

  closePopup = () => {
    this.setState({
      isOpenPopup: false,
      popupMessage: ''
    })
  }

  render() {
    const {
      transporters = [],
      loads,
      limit,
      total,
      popupOpen,
      commentMode,
      commentText,
      isOpenPopup,
      popupMessage,
      popupSelectLoadOpen,
      // transporters_active,
      // transporters_total,
      awaitForRender,
      bubbleTransporterOpen,
    } = this.state;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={"transporters"} />
            <CustomTopBar />
            <InfoPopup
                popupOpen={isOpenPopup}
                popupClose={this.closePopup}
                message={popupMessage}
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleTransporterOpen} 
              speechMode={"findTransporter"}
              closeCallBack={() => 
                this.setState({
                  bubbleTransporterOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">
                      Transporters
                    </div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Transporters</li>
                    </ol>
                    <div className="main-content-header-search main-content-header-search-add">
                      <input
                        className="form-control"
                        type="search"
                        id="example-text-input"
                        maxLength="100"
                        onChange={(e) => this.searching(e.target.value)}
                        placeholder="Search by name, cell, DOT, email"
                      />
                    </div>
                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="people-table people-table-transporter">
                    <div className="people-table-header">
                      <div className="people-table-header-title">
                        My transporters
                      </div>
                      <div className="people-table-header-search">
                        {total} search results
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Cell</th>
                          <th>Carrier</th>
                          <th>Email</th>
                          <th>Comment (private)</th>
                          <th>Available capacity</th>
                          <th>Offer Load</th>
                          <th />
                          {/*<th>{"Block Driver"}</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {transporters.map((item, i) => {
                          return (
                            <tr key={item.id}>
                              {/*<td>*/}
                              {/*<div className="custom-control custom-checkbox">*/}
                              {/*<input*/}
                              {/*type="checkbox"*/}
                              {/*className="custom-control-input"*/}
                              {/*id={item.id}*/}
                              {/*onChange={() => this.selectedItem(item.id, i)}*/}
                              {/*checked={!!selectedItem.includes(item.id)}*/}
                              {/*disabled={item.status === 'unknown' || item.status === 'invited' ? 'disabled' : ''}*/}
                              {/*/>*/}
                              {/*<label className="custom-control-label" htmlFor={item.id}/>*/}
                              {/*</div>*/}
                              {/*</td>*/}
                              <td>
                                {item.first_name} {item.last_name}
                              </td>
                              <td>{item.phone}</td>
                              <td>{item.dot}</td>
                              <td>{item.email}</td>
                              <td>
                                <div className="people-table-comment">
                                  {commentMode === item.id ? (
                                    <div className="people-table-comment-form">
                                      <textarea
                                        onChange={(e) =>
                                          this.setState({
                                            commentText: e.target.value,
                                          })
                                        }
                                        value={
                                          commentMode
                                            ? commentText
                                            : item.transporter_comment
                                        }
                                      />
                                      <p
                                        onClick={() =>
                                          this.handleComment(item.id)
                                        }
                                      >
                                        Save
                                      </p>
                                      <span
                                        onClick={() =>
                                          this.setState({ commentMode: null })
                                        }
                                      >
                                        Cancel
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="people-table-comment-control">
                                      <div className="people-table-comment-text">
                                        {item.transporter_comment}
                                      </div>
                                      {item.transporter_comment ? (
                                        <div className="people-table-comment-form-btns">
                                          <p
                                            onClick={() =>
                                              this.setState({
                                                commentMode: item.id,
                                                commentText:
                                                  item.transporter_comment,
                                              })
                                            }
                                          >
                                            {"Edit"}
                                          </p>
                                          <span
                                            onClick={() =>
                                              this.deleteComment(item.id)
                                            }
                                          >
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <p
                                          onClick={() =>
                                            this.setState({
                                              commentMode: item.id,
                                              commentText:
                                                item.transporter_comment,
                                            })
                                          }
                                        >
                                          {"Add"}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="people-table-capacity">
                                  <span>
                                    {item.car_capacity -
                                      item.assigned_car_count}
                                  </span>{" "}
                                  / <span>{item.car_capacity}</span>
                                </div>
                              </td>
                              <td>
                                <div className="people-table-capacity">
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href={"#"}
                                    onClick={(e) =>
                                      this.openPopupSelectLoad(e, item)
                                    }
                                  >
                                    Offer load
                                  </a>
                                </div>
                              </td>
                              <td>
                                {/*  <div className="people-table-status">*/}
                                {/*    {item.status === "active" && (*/}
                                {/*      <div className="people-table-status-active ">*/}
                                {/*        Active*/}
                                {/*      </div>*/}
                                {/*    )}*/}
                                {/*    {item.status === "assigned" && (*/}
                                {/*      <div className="people-table-status-assigned ">*/}
                                {/*        Assigned*/}
                                {/*      </div>*/}
                                {/*    )}*/}
                                {/*    {item.status === "non_active" && (*/}
                                {/*      <div className="people-table-status-disabled">*/}
                                {/*        Not active*/}
                                {/*      </div>*/}
                                {/*    )}*/}
                                {/*    {item.status === "invited" && (*/}
                                {/*      <div className="people-table-status-alert">*/}
                                {/*        Invited*/}
                                {/*      </div>*/}
                                {/*    )}*/}
                                <div className="action-box">
                                  {item.status === "unknown" && (
                                    <div
                                      className="btn btn-sm custom-btn btn-outline-primary"
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={() => this.sentInvite(item.id)}
                                    >
                                      Add to list
                                    </div>
                                  )}

                                  {item.status !== "unknown" && (
                                    <div
                                      className="people-table-btn-group-icon fas fa-trash trash-btn"
                                      title="delete"
                                      onClick={() =>
                                        this.openPopup(item.id, item.status)
                                      }
                                    />
                                  )}
                                </div>
                                {/*  </div>*/}
                              </td>
                              {/*<td className="trash-container">*/}
                              {/*  <div className="people-table-btn-group-control">*/}
                              {/*    <div className="people-table-btn-group-toggle">*/}
                              {/*      {item.status === "active" ||*/}
                              {/*      item.status === "non_active" ||*/}
                              {/*      item.status === "assigned" ? (*/}
                              {/*        <label*/}
                              {/*          htmlFor={item.id}*/}
                              {/*          title={*/}
                              {/*            transporters_active >=*/}
                              {/*              transporters_total &&*/}
                              {/*            item.status !== "active"*/}
                              {/*              ? "You can't active more transporters"*/}
                              {/*              : "Switcher of status"*/}
                              {/*          }*/}
                              {/*        >*/}
                              {/*          <input*/}
                              {/*            type="checkbox"*/}
                              {/*            id={item.id}*/}
                              {/*            checked={*/}
                              {/*              item.transporter_status === 1*/}
                              {/*            }*/}
                              {/*            onChange={(e) =>*/}
                              {/*              this.switchStatus(*/}
                              {/*                e,*/}
                              {/*                item.id,*/}
                              {/*                item.transporter_status*/}
                              {/*              )*/}
                              {/*            }*/}
                              {/*          />*/}
                              {/*          <span className="people-table-btn-group-toggle-icon" />*/}
                              {/*          <span className="people-table-btn-group-toggle-active">*/}
                              {/*            Unblock*/}
                              {/*          </span>*/}
                              {/*          <span className="people-table-btn-group-toggle-notActive">*/}
                              {/*            Block*/}
                              {/*          </span>*/}
                              {/*        </label>*/}
                              {/*      ) : (*/}
                              {/*        ""*/}
                              {/*      )}*/}
                              {/*    </div>*/}

                              {/*</td>*/}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="people-table-footer">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination custom-pagination"}
                        subContainerClassName={"pages pagination "}
                        activeClassName={"active"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item disabled"}
                        breakLinkClassName={"page-link disabled"}
                        onPageChange={this.handlePageChange}
                        pageCount={Math.ceil(total / limit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)",
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      Delete Transporter
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                      Do you really want to delete this transporter from your
                      list?
                    </p>
                    <Button
                      className="btn btn-primary w-md custom-btn"
                      onClick={this.deleteTransporter}
                    >
                      Delete
                    </Button>
                    <Button
                      className="btn w-md"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <PopupSubscription
            popupOpen={this.state.isOpenSubscriptionModal}
            closeModal={this.closeModal}
          />

          {popupSelectLoadOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)",
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      List of loads
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() =>
                        this.setState({ popupSelectLoadOpen: false })
                      }
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                      {loads.length > 0
                        ? "Offer load to the transporter " +
                          this.state.selectedTransporterName
                        : "No loads found"}
                    </p>
                    {loads.length > 0 ? (
                      <div className="dashboard-content">
                        <div className="people-table table-pop-up">
                          <table>
                            <thead>
                              <tr>
                                <th>Load number</th>
                                <th>Pick up date</th>
                                <th>Delivery date</th>
                                <th>Cars in load</th>
                                <th>Price</th>
                                <th style={{ textAlign: "center" }}>Offer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loads.map((item, i) => {
                                const pickUpDate = (
                                  item.pick_up_date + ""
                                ).split(" ");
                                const pickUpDateDivided =
                                  pickUpDate[0].split("-");
                                const deliveryDate = (
                                  item.delivery_date + ""
                                ).split(" ");
                                const deliveryDateDivided =
                                  deliveryDate[0].split("-");

                                return (
                                  !item.dispatcher_id && (
                                    <tr key={i}>
                                      <td>{item.load_id || "-"}</td>
                                      <td>
                                        {`${pickUpDateDivided[0]}-${pickUpDateDivided[1]}-${pickUpDateDivided[2]}`}
                                      </td>
                                      <td
                                        className={
                                          item.delayed ? "data-warning" : ""
                                        }
                                      >
                                        {`${deliveryDateDivided[0]}-${deliveryDateDivided[1]}-${deliveryDateDivided[2]}`}
                                      </td>
                                      <td>{item.car_count || 0}</td>
                                      <td>
                                        {item.driver_data
                                          ? `${item.driver_data.first_name} ${item.driver_data.last_name}`
                                          : "-"}
                                      </td>
                                      <td>
                                        <div className="send-invite-box">
                                          <div
                                            className="btn btn-sm custom-btn btn-outline-primary"
                                            style={{ whiteSpace: "nowrap" }}
                                            onClick={() =>
                                              item.new_driver_id
                                                ? null
                                                : this.addLoad(item.id)
                                            }
                                          >
                                            Send invite
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </table>

                          <ReactPaginate
                            forceSelected={this.state.initialPage}
                            forcePage={this.state.initialPage}
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination custom-pagination"}
                            subContainerClassName={"pages pagination "}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item disabled"}
                            breakLinkClassName={"page-link disabled"}
                            onPageChange={this.handlePageLoadsChange}
                            pageCount={Math.ceil(total / limit)}
                          />
                        </div>
                      </div>
                    ) : null}
                    <Button
                      className="btn w-md mt-4"
                      onClick={() =>
                        this.setState({ popupSelectLoadOpen: false })
                      }
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </AUX>
    );
  }
}

export default withSnackbar(Loads);
