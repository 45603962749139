import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

import LoadsStat from '../LoadsStat/LoadsStat'
import CompletedLoadsStat from '../CompletedLoadsStat/CompletedLoadsStat'
import TopClientsStat from '../TopClientsStat/TopClientsStat'
import MyDriversStat from '../MyDriversStat/MyDriversStat'

import DashboardAPI from '../../../api/dashboardApi'
import './style.css'

import {getDashboardData} from "./dashboardAction"
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { checkTrial, showPostYourCar } from '../../../helpers/CheckSubscription';

class MainPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      awaitForRender: false,
      popupOpen: false,
      bubbleOpen: false,
      refreshingDashboard: false
    };
  }

  async componentDidMount() {
    const {getDashboardData} = this.props
    // const data = await DashboardAPI.GetDashboardData()
    // if(data && data.data) {
    //   await getDashboardData(data.data.data)
    // }

    const dashboardData = localStorage.getItem('dashboardData')
    if (dashboardData) {
      getDashboardData(JSON.parse(dashboardData))
    }

    this.setState({ refreshingDashboard: true }, () => {
      console.debug("[MainPage] componentDidMount", this.state.refreshingDashboard);
      DashboardAPI.GetDashboardData().then(res => {
        console.debug("[MainPage] GetDashboardData...");
        if (res && res.data) {
          localStorage.setItem('dashboardData', JSON.stringify(res.data.data))
          getDashboardData(res.data.data)
          console.debug("[MainPage] GetDashboardData ", false);
          this.setState({ refreshingDashboard: false });
        }
      }).catch(err => {
        console.error("[MainPage] GetDashboardData", err);
      });
    });

    this.setState({
      awaitForRender: true,
      popupOpen: await checkTrial(),
      bubbleOpen: showPostYourCar(),
    })
  }

  render() {
    const { awaitForRender, popupOpen, bubbleOpen } = this.state
    const { dashboardData } = this.props

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'dashboard'}/>
            <CustomTopBar/>
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleOpen} 
              speechMode={"postYourCar"} 
              closeCallBack={() => 
                this.setState({
                  bubbleOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left main-content-header-left-dashboard">
                    <div>
                      <div className="main-content-header-title page-title">Dashboard</div>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Welcome to GOFORIT for Shippers</li>
                      </ol>
                    </div>
                    <a className="btn custom-btn" href='/load'>Post your car</a>
                  </div>
                  <div className="main-content-header-right">
                    <ul className="main-content-header-statistic">
                      {/*
                      <li className="main-content-header-statistic-item">
                        <div className="main-content-header-statistic-item-value">{dashboardData.licenseCount}</div>
                        <div className="main-content-header-statistic-item-title">License</div>
                        <div className="main-content-header-statistic-item-sup">your current plan</div>
                      </li>
                      */}
                      {/*<li className="main-content-header-statistic-item">*/}
                      {/*  <div className="main-content-header-statistic-item-value">{dashboardData.activeDriverCount}</div>*/}
                      {/*  <div className="main-content-header-statistic-item-title">My Drivers</div>*/}
                      {/*  <div className="main-content-header-statistic-item-sup">in your database <br /> (Active)</div>*/}
                      {/*</li>*/}
                      <li className="main-content-header-statistic-item">
                        <div className="main-content-header-statistic-item-value">{dashboardData.assignedDriverCount}</div>
                        <div className="main-content-header-statistic-item-title">Transporters</div>
                        <div className="main-content-header-statistic-item-sup">assigned to loads</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="dashboard-content dashboard-content-main">
                  {awaitForRender ? (
                    <React.Fragment>
                      <LoadsStat refreshing={this.state.refreshingDashboard} />
                      <CompletedLoadsStat refreshing={this.state.refreshingDashboard} />
                      <TopClientsStat refreshing={this.state.refreshingDashboard} />
                      <MyDriversStat refreshing={this.state.refreshingDashboard} />
                    </React.Fragment>
                    )
                    : (<React.Fragment>
                        <div className="custom-spinner-row">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </React.Fragment>)
                  }
                </div>

              </div>
            </div>


            {popupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Welcome</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                    </div>
                    <div className="modal-body modal-body-welcome">
                      <div className="modal-body-welcome-img">
                        <img src="/assets/images/welcome-popup-img.png" alt=""/>
                      </div>
                      <div className="modal-body-welcome-text">
                        Now you have trial period for 30 days and can use the application without limitations. You can add transporters for free on the Subscription page. After trial period expires payment info will be required
                      </div>

                    </div>
                  </div>
                </div>
              </div>) : null}


          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
    loginpage: state.ui_red.loginpage
  };
}


const mapDispatchToProps = dipatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
      UpdateLogin: () => ({type: actionTypes.LOGINPAGE, value: true}),
      UpdateLoginAgain: () => ({type: actionTypes.LOGINPAGE, value: false})
    },
    dipatch
  ),


});




export default connect(mapStatetoProps, mapDispatchToProps)(MainPage);
