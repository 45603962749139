import React, {Component} from 'react';
import config from '../../../config';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";

import './style.css'
import {AvCheckbox, AvCheckboxGroup, AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap";
import UsersAPI from "../../../api/userApi";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import { USER_ROLE } from "../../../utils/usersRole"

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      driversCount: 0,
      alert: false,
      userData: {},
      comparePass: false,
      cardMsgStatus: false,
      cardMsgText: '',
      userCard: '0000',
      useSame: false,
      disabledFields: false,
      timeZones: null
    };
  }

  async componentDidMount() {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const userCard = await SubscriptionAPI.GetUserCard();
    let last4 = '0000';

    if (userCard) {
      last4 = userCard[0].last4
    }

    const timeZones = await UsersAPI.GetTimeZones()
    // console.log('>>> timeZones: ', timeZones)

    this.setState({
      userData,
      userCard: last4,
      email: userData.email,
      timeZones: timeZones.data.data
    })
  }

  driversDecrement = () => {
    const {driversCount} = this.state

    driversCount > 0 ?
      this.setState({driversCount: this.state.driversCount - 1}) :
      this.setState({driversCount: 0})
  }

  driversIncrement = () => {
    this.setState({driversCount: this.state.driversCount + 1})
  }

  openPopup = () => {
    this.setState({popupOpen: true})
  }

  makePayment = () => {
    console.log('makePayment')
  }

  handleEmailChangeSubmit = async () => {
    const {email} = this.state
    const data = {
      email: email
    }

    const res = await UsersAPI.UpdateUser(data)
    if (res.status === 200) {
      let userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      userData = {...userData, ...res.data.user}
      localStorage.setItem('userInfo', JSON.stringify(userData))
      this.setState({comparePass: false, alert: true, userData}, function () {
        setTimeout(() => {
          window.location.reload();
        }, 4000)
      })
    } else {
      this.setState({accountError: 'Invalid data'})
    }
  }

  handleSubmit = async (event, errors, values) => {
    const {id, email} = this.state.userData
    const {phoneNumber, firstName, lastName, companyName, password, confirmPass, select, notification} = values;

    const data = {
      id,
      email: email,
      phone: phoneNumber,
      user_type: USER_ROLE.dealer,
      first_name: firstName,
      last_name: lastName,
      password: password,
      notify: select,
      company: {
        id: 0,
        name: companyName
      },
      email_notifications: notification === 'email' ? 1 : 0,
    }

    if (password === confirmPass) {
      if (errors.length === 0 && password === confirmPass) {

        const res = await UsersAPI.UpdateUser(data)
        if (res.status === 200) {
          let userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
          userData = {...userData, ...res.data.user}
          localStorage.setItem('userInfo', JSON.stringify(userData))
          this.setState({comparePass: false, alert: true, userData}, function () {
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          })
        } else {
          this.setState({accountError: 'Invalid data'})
        }
      }
    } else {
      this.setState({comparePass: true})
    }
  };

  handleAddressSubmit = async (event, errors, values) => {
    const {id} = this.state.userData
    const {address, city, state, zip, billing_address, billing_city, billing_state, billing_zip, time_zone} = values;

    const data = {
      id,
      company: {
        id: 0,
        address,
        city,
        state,
        zip,
        billing_address,
        billing_city,
        billing_state,
        billing_zip
      },
      time_zone
    }

    if (errors.length === 0) {
      const res = await UsersAPI.UpdateUser(data);
      if (res.status === 200) {
        let userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
        userData = {...userData, ...res.data.user}
        localStorage.setItem('userInfo', JSON.stringify(userData))
        this.setState({comparePass: false, alert: true, userData}, function () {
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        })
      } else {
        this.setState({accountError: 'Invalid data'})
      }
    }
  };

  toggleUseSame = event => {
    this.setState({useSame: !this.state.useSame, disabledFields: !this.state.disabledFields});
    if (event.target.checked) {
      const billingAddress = {
        billing_address: this.state.userData.company.address,
        billing_city: this.state.userData.company.city,
        billing_zip: this.state.userData.company.zip,
        billing_state: this.state.userData.company.state
      };

      this.setState({
        userData: {
          ...this.state.userData, company: {...this.state.userData.company, ...billingAddress}
        }
      });
    }
  };

  handleChangeField = name => event => {
    this.setState({
      userData: {
        ...this.state.userData, company: {...this.state.userData.company, [name]: event.target.value}
      }
    });
  };

  passChangeSubmit = async (event, errors, values) => {

    const {password, confirmPass} = values;

    const data = {
      password: password,
    }

    if (password === confirmPass) {
      if (errors.length === 0 && password === confirmPass) {

        const res = await UsersAPI.UpdateUser(data)
        if (res.status === 200) {
          let userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
          userData = {...userData, ...res.data.user}
          localStorage.setItem('userInfo', JSON.stringify(userData))
          this.setState({comparePass: false, alert: true, userData}, function () {
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          })
        } else {
          this.setState({accountError: 'Invalid data'})
        }
      }
    } else {
      this.setState({comparePass: true})
    }
  }

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleCardChangeSubmit = async (evt) => {
    evt.preventDefault();

    if (this.props.stripe) {
      const data = await this.props.stripe.createToken()
      if (data.token) {
        const res = await SubscriptionAPI.UpdateUserCard({token: data.token.id})
        let text = ''

        if(res.status === 200) {
          text = 'Changed card was successful'
        }

        else {
          text = res.split('/// DUMP ///')[0]
        }

        this.setState({
          cardMsgStatus: true,
          cardMsgText: text
        }, function () {
          setTimeout(() => {
            //window.location.reload();
          }, 2700);
        })
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {

    const {
      comparePass,
      userData = {},
      alert,
      userCard,
      email,
      cardMsgStatus,
      cardMsgText,
      useSame,
      disabledFields,
      timeZones
    } = this.state
    const {company = {}} = userData
    const name = company === null ? '' : company.name

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'settings'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Settings</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Settings</li>
                    </ol>
                  </div>
                  <div className="main-content-header-right main-content-header-right-stretch">
                    <Link to="/transporters">My Transporters</Link>
                  </div>
                </div>
                <div className="dashboard-content">

                  <div className="settings-info">
                    <div className="settings-title">General Information</div>

                    <div className="row">
                      <div className="col-12">
                        <div className="settings-info-email">
                          <div className="form-group">
                            <label htmlFor="">Email</label>
                            <input
                              name="email"
                              className="form-control"
                              required
                              value={email || ''}
                              type="email"
                              onChange={(e) => this.setState({email: e.target.value})}
                              maxLength="100"/>
                          </div>
                          <button className="btn custom-btn" type="submit" onClick={this.handleEmailChangeSubmit}>Confirm and change</button>
                          <p className="settings-info-email-note">Your login username is same as your e-mail address.
                            If you wish to change it - be advised that you need to confirm new e-mail address.</p>
                        </div>
                      </div>
                    </div>


                    {userCard !== '0000' && (
                      <div className="row">
                        <div className="col-12">
                          <div className="settings-info-card">
                            <div className="form-group">
                              <label htmlFor="">Card</label>
                              <div className="settings-info-card-view">
                                <img src="assets/images/chip.svg" alt=""/>
                                <div className="settings-info-card-view-card-name">Your card</div>
                                <div className="settings-info-card-view-card-number">
                                  <p>Card number</p>
                                  <span>****</span>
                                  <span>****</span>
                                  <span>****</span>
                                  <span>{userCard}</span>
                                </div>
                              </div>
                            </div>
                            <div className="settings-info-card-new">

                              <form onSubmit={(e) => this.handleCardChangeSubmit(e)}>
                                    <CardElement
                                      onChange={this.handleChange}
                                      {...createOptions()}
                                    />
                                    <button className="btn custom-btn" type="submit">Confirm and change</button>
                              </form>
                              <p className="settings-info-card-note">Here you can change payment information and add another credit card </p>
                              {cardMsgStatus && (<div className="custom-alert" role="alert" style={{display: 'inline-block'}}>{cardMsgText}</div>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12">
                        <div className="settings-info-card">
                          <AvForm className="addressesForm" onSubmit={this.handleAddressSubmit}>
                            <div className="row">
                              <div className="col-12">
                                { !!timeZones &&
                                (<AvField
                                  type="select"
                                  name="time_zone"
                                  label='Choose your timezone'
                                  value={userData.time_zone}
                                >
                                  {timeZones.map((item, i) => {
                                      return (
                                        <option key={item.name} value={item.name}>{item.name}</option>
                                      )
                                    })
                                  }
                                </AvField>) }
                              </div>
                            </div>
                            <div className="settings-subtitle">Address</div>
                            <div className="row">
                              <div className="col-6">
                                <AvField
                                  name="address"
                                  label="Street"
                                  required
                                  errorMessage="Invalid street"
                                  onChange={this.handleChangeField('address')}
                                  value={company === null || company.address === null ? '' : company.address}
                                  type="text"
                                  maxLength="255"
                                />
                              </div>
                              <div className="col-6">
                                <AvField
                                  name="city"
                                  label="City"
                                  required
                                  errorMessage="Invalid city"
                                  onChange={this.handleChangeField('city')}
                                  value={company === null || company.city === null ? '' : company.city}
                                  type="text"
                                  maxLength="255"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <AvField
                                  name="state"
                                  label="State/Province"
                                  required
                                  errorMessage="Invalid state"
                                  onChange={this.handleChangeField('state')}
                                  value={company === null || company.state === null ? '' : company.state}
                                  type="text"
                                  maxLength="2"
                                />
                              </div>
                              <div className="col-6">
                                <AvField
                                  name="zip"
                                  label="Zip"
                                  required
                                  errorMessage="Invalid zip"
                                  onChange={this.handleChangeField('zip')}
                                  value={company === null || company.zip === null ? '' : company.zip}
                                  type="text"
                                  maxLength="10"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="custom-control custom-checkbox">
                                  <AvCheckboxGroup inline name="useSame">
                                    <AvCheckbox customInput defaultChecked={useSame} onClick={this.toggleUseSame}>
                                      Use the same address for billing
                                    </AvCheckbox>
                                  </AvCheckboxGroup>
                                </div>
                              </div>
                            </div>
                            <div className="settings-subtitle">Billing Address</div>
                            <div className="row">
                              <div className="col-6">
                                <AvField
                                  name="billing_address"
                                  label="Street"
                                  disabled={disabledFields}
                                  required
                                  errorMessage="Invalid street"
                                  onChange={this.handleChangeField('billing_address')}
                                  value={company === null || company.billing_address === null ? '' : company.billing_address}
                                  type="text"
                                  maxLength="255"
                                />
                              </div>
                              <div className="col-6">
                                <AvField
                                  name="billing_city"
                                  label="City"
                                  disabled={disabledFields}
                                  required
                                  errorMessage="Invalid city"
                                  onChange={this.handleChangeField('billing_city')}
                                  value={company === null || company.billing_city === null ? '' : company.billing_city}
                                  type="text"
                                  maxLength="255"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <AvField
                                  name="billing_state"
                                  label="State/Province"
                                  disabled={disabledFields}
                                  required
                                  errorMessage="Invalid state"
                                  onChange={this.handleChangeField('billing_state')}
                                  value={company === null || company.billing_state === null ? '' : company.billing_state}
                                  type="text"
                                  maxLength="2"
                                />
                              </div>
                              <div className="col-6">
                                <AvField
                                  name="billing_zip"
                                  label="Zip"
                                  disabled={disabledFields}
                                  required
                                  errorMessage="Invalid zip"
                                  onChange={this.handleChangeField('billing_zip')}
                                  value={company === null || company.billing_zip === null ? '' : company.billing_zip}
                                  type="text"
                                  maxLength="10"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 button-input">
                                <Button className="btn btn-primary w-md custom-btn" type="submit">Save changes</Button>
                              </div>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </div>

                    <AvForm onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <AvField
                            name="firstName"
                            label="First Name"
                            required
                            errorMessage="Invalid first name"
                            value={userData.first_name}
                            maxLength="100"
                            type="text"/>
                        </div>
                        <div className="col-12">
                          <AvField
                            name="lastName"
                            label="Last Name"
                            required
                            errorMessage="Invalid last name"
                            value={userData.last_name}
                            maxLength="100"
                            type="text"/>
                        </div>
                        <div className="col-12">
                          <AvField
                            name="phoneNumber"
                            label="Phone number"
                            required
                            errorMessage="Invalid phone number"
                            value={userData.phone}
                            maxLength="100"
                            type="text"/>
                        </div>
                        <div className="col-12">
                          <AvField
                            name="companyName"
                            label="Company name"
                            required
                            errorMessage="Invalid company name"
                            value={name}
                            maxLength="100"
                            type="text"/>
                        </div>
                        <div className="col-12">
                          <AvField
                            type="select"
                            name="notification"
                            label='Notify me via email'
                            value={userData.email_notifications === 0 ? 'never' : 'email'}>
                            <option value="never">No</option>
                            <option value="email">Yes</option>
                          </AvField>
                        </div>
                      </div>
                      <div className="settings-info-footer m-b-20">
                        <div className="row">
                          <div className="col-4"/>

                          <div className="col-sm-8 button-input">
                            <div className="settings-info-cancel">Cancel</div>
                            <Button className="btn btn-primary w-md custom-btn" type="submit">Save changes</Button>
                          </div>
                        </div>
                      </div>
                    </AvForm>

                    <AvForm onSubmit={this.passChangeSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <AvField
                            name="password"
                            label="Password"
                            required
                            errorMessage="Invalid password"
                            maxLength="100"
                            type="password"/>
                        </div>
                        <div className="col-12">
                          <AvField
                            className={comparePass ? 'form-control is-invalid' : ''}
                            name="confirmPass"
                            label="Confirm password"
                            required
                            errorMessage="Invalid password"
                            maxLength="100"
                            type="password"/>
                        </div>
                      </div>

                      <div className="settings-info-footer">
                        <div className="row">
                          <div className="col-5">
                            <div className="custom-control custom-checkbox">
                              <AvCheckboxGroup inline name="termsConditions">
                                <AvCheckbox customInput value="termsConditions" defaultChecked={true}>
                                  I agree with
                                  <a
                                    href="http://goforitapp.com/terms-and-conditions/"
                                    className="font-500 text-primary"
                                    style={{paddingLeft: '5px'}}>Terms and conditions</a>
                                </AvCheckbox>
                              </AvCheckboxGroup>
                            </div>
                          </div>
                          <div className="col-7 button-input">
                            <div className="settings-info-cancel">Cancel</div>
                            <Button className="btn btn-primary w-md custom-btn" type="submit">Save changes</Button>
                          </div>
                        </div>
                      </div>
                    </AvForm>


                    {alert ? (<div
                      className="custom-alert"
                      role="alert">You successfully changed your settings</div>) : null}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </main>
      </AUX>
    );
  }
}


const SettingsInjected = injectStripe(Settings)


const Headline = () => {

  return (
    <div>
      <StripeProvider apiKey={config.stripeAPI}>
        <Elements>
          <SettingsInjected/>
        </Elements>
      </StripeProvider>
    </div>
  )

};
export default Headline;
