import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

class PopupSubscription extends Component {
  render() {
    const { popupOpen, closeModal } = this.props;

    return (
      popupOpen && (
        <div
          className="modal fade bs-example-modal-lg custom-modal show "
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          style={{
            paddingRight: "15px",
            display: "block",
            background: "rgba(0,0,0,0.6)",
          }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Upgrade Subscription
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => closeModal()}
                >
                  ×
                </button>
              </div>
              <div className="modal-body modal-body-delete">
                <p className="modal-body-delete-text">
                  Your free trial period expired or payment did not go through.
                  <br />
                  Please make payment to resume service
                </p>
                <Link
                  className="btn btn-primary w-md custom-btn"
                  to="/subscription"
                >
                  Upgrade
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default PopupSubscription;
