import React, { Component } from 'react';

import './style.css';

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this)
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options);
    this.props.onMapLoad(map)
  }

  componentDidMount() {
    if (!window.google) {
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${this.props.apiKey}&libraries=${this.props.libraries}&language=${this.props.language}&region=${this.props.region}`;
      let x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }

  render() {
    return (
      <div>
        <input style={{display: this.props.dataLength ? 'block' : 'none'}} key={this.props.dataLength} id="pac-input" className="controls" type="text" placeholder="Search Box"/>
        <div className="map" id={this.props.id} />
      </div>
    );
  }
}

export default GoogleMap
