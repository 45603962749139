const parseDate = (dateString) => {
  const dateTime = dateString.split(" ");
  const dateOnly = dateTime[0];
  const timeOnly = dateTime[1];

  const temp = dateOnly + "T" + timeOnly;
  return new Date(temp);
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default parseDate;