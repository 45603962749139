import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';

import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";

class Help extends Component {

  // async componentDidMount() {
  //   const data = await DashboardAPI.GetDashboardData()
  // }

  handleHeaderClick = (id) => {
    const button = document.querySelector('[data-toggle="collapse"][data-target="' + id + '"]');
    if (button) {
      button.click();
    }
  };

  render() {
    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'help'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Help</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Help</li>
                    </ol>
                  </div>

                  <div className="main-content-header-right">
                    <a href='http://goforitapp.com/support/' className="btn custom-btn main-content-header-btn" style={{marginTop: '27px'}}>Contact Support</a>
                  </div>

                </div>

                <div className="dashboard-content">

                  <div className="card custom-card-help">
                    <div className="card-body">
                      <div className="custom-card-help-left">
                        <h5>How to use GOFORIT for Shippers?</h5>
                        <p>
                          New generation web application will help you to find transporters near you, send them offers, get counter offers or assign loads directly. Check how many cars each transporter has hauled before and their ratings. Add transporters in favorites so they will see your loads first. No need to pay hefty subscription fees for hard-to-use load boards. Receive notification each time the load status changes. Automatically receive BOL including condition report and inspection. And many more…
                        </p>
                        <p>
                          Here is the how-to-use description of the main features.
                        </p>
                        <p>
                          Home Page: On the top, you see the number of licensed users in your database. Those are drivers
                          you have purchased the Goforit Subscription for. The next item shows a number of active drivers,
                          and the last item shows the number of drivers who have assigned loads at the moment.
                        </p>
                        <p>
                          There are 4 widgets on the home page. First widget shows statistics on how many loads are currently active,
                          delivered or delayed. You can filter this information by selecting specific driver.
                        </p>
                        <p>
                          The second Widget shows completed loads per day and compares it to the last period. You can view weekly or yearly
                          report. The yearly report shows your gross earnings by months.
                        </p>
                        <p>
                          The third section displays the top 10 clients, referring to the Transportation Companies where your drivers deliver cars. The columns show how many cars have been delivered to each specific driver or transportation company and the gross amount of all delivered loads. This tool allows you to analyze your business effectively. In the fourth section, you can see a list of drivers and their load status, whether they have assigned, picked up, or no loads. Additionally, you can view the remaining capacity on their trailers and how many more cars they can accommodate. This helps you quickly find suitable loads for your drivers that can fit in the available space on the trailer.
                        </p>
                      </div>
                      <div className="custom-card-help-right">
                        <img src="assets/images/help-main-img.jpg" alt="app img"/>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="custom-card-help-header">
                        <h5>How to use</h5>
                        <a className="btn custom-btn" href="mailto:na@goforitapp.com?cc=aa@goforitapp.com&subject=Customer%20Support%20for%20Shippers&body=Dear%20GoForIt%21%0D%0A%0D%0AI%20have%20a%20question%20...%0D%0A%0D%0AThank%20You%21">Ask question</a>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header" onClick={() => this.handleHeaderClick("#collapseExample2")}>
                          <div className="help-item-title">Create a new Order (Load)</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample2"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample2">
                          <div className="card custom-card-help">
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>1) On the left side of the screen you can see a dark blue MENU panel. Find and press <strong>My Orders</strong> tab (Mark 1). On <strong>My Orders</strong> screen you will see all your active orders (Loads, requested deliveries).&nbsp; If you don&rsquo;t have any active orders (loads) this page will be empty.</p>
                                <p>2) To create a new Order please press <strong>Create Load</strong> button on the top right corner (Mark 2) and fill in the details on the Order form.</p>
                                <p>3) When you create an Order (load), you will see it on Active Order section as list (Mark 3) and on the map (Mark 4) as a Car icon, located at the same address as the Origin has.</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/Orders-home-1.png" alt="app img"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header" onClick={() => this.handleHeaderClick("#collapseExample3")}>
                          <div className="help-item-title">New Order (Load) form</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample3"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample3">
                          <div className="card custom-card-help">
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>If you need to transport a car or several cars from one location to another, you will need to create an <strong>Order</strong>. <strong>Order</strong> is a term used in GOFORIT app that represents one delivery (up to 11 cars) from one particular address to another. If you need to pickup cars in different locations or deliver to different addresses, you will need to create several <strong>Orders</strong>.</p>
                                <p>1) When you press the <strong>Create Load</strong> button, the Order form will open. Start filling out the form by entering the information about your Order (Mark 1). <strong>Load number</strong> (your personal/corporate ID of the Order/Load). <strong>Pick up date</strong> (date when you want this Order to be picked up by the Transporter). <strong>Delivery Date</strong> &ndash; when you want this Order to be delivered. <strong>Price</strong> &ndash; how much are you ready to pay for the delivery services in this Order. Field <strong>Status</strong>represents current status of this Order.</p>
                                <p>2) Second step is to add cars that you want to deliver. Press <strong>Add new car</strong> button (Mark 2) to start adding cars to your Order. See details below, described in Step 4.</p>
                                <p>3) On the <strong>Comment</strong> section (Mark 3) you can add any addition information you would like to share with your Transporter or Dispatching company.</p>
                                <p>4) When you press the<strong> Add new car</strong> button ( Mark 2), you will see the small form related to a particular car under the<strong> Cars to Transport</strong> section. Fill out the form (fields like <strong>Make</strong>, <strong>Model</strong>, and <strong>VIN</strong> are mandatory.&nbsp;</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/Orders-form-1.png" alt="app img"/>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>5) When you are done, press the <strong>Submit</strong> button to add this car to the Order. If you need to add more cars you can press the <strong>Add new car</strong> button again and fill out the form for the second car. Overall you can add up to 11 cars into 1 Order.</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/Form-adding-cars.png" alt="app img"/>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>6) Finish filling out this form by adding information about <strong>Origin</strong> &ndash; where your Order needs to be picked up (Mark4) and <strong>Destination</strong> &ndash; where your Order needs to be delivered (Mark 6).</p>
                                <p>7) If some of your cars needs to be delivered (or picked up) to different addresses, you will need to create a separate Order for that.</p>
                                <p>8) If you would like your Order to be visible to other Transporters (that will give them opportunity to offer their services to you), please make sure you keep checked option <strong>Make load available for all transporters</strong> (Mark 7) on the bottom of you Order form.</p>
                                <p>9) When you are done with filling out your Order form, please press <strong>Create Load</strong> button on the bottom right corner (Mark 8) to finish with editing and save your order. It will be visibe to other Dispatchers and Transporters.</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/order-form-2.png" alt="app img"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header" onClick={() => this.handleHeaderClick("#collapseExample4")}>
                          <div className="help-item-title">Finding a Transporter</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample4"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample4">
                          <div className="card custom-card-help">
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>There are two ways to find a Transporter for your Order: <strong>Automatic</strong> and <strong>By Invitation</strong>.</p>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <h5>Automatic</h5>
                                <p>
                                  <div>If your Order has a checkmark on the option <strong>Make load available for all transporters</strong>, your Order is visible on the market and somebody from the current GOFORIT users will offer you their services.</div>
                                  <div>When some Transporter offers you a service you will receive a <strong>notification</strong> (Mark 3), an <strong>email</strong>, and you will see that Transporter under your Order, pending your decision.</div>
                                </p>
                                <p>1) Go to <strong>My Orders</strong> (Mark 1). You will see a list of your orders (Mark 2).</p>
                                <p>2) A <strong>purple circle</strong> (Mark 4) will show how many Transporters are waiting for your response.</p>
                                <p>3) Under your order you will see a list of Transporters (Mark 5).</p>
                                <p>4) Use the <strong>Accept</strong>/<strong>Reject</strong> buttons (Mark 6) to either approve this Transporter to deliver your Order or decline his offer.</p>
                                <p>When you accept a Transporter he/she will be automatically assigned to your Order. Now you can call your Transporter to discuss details.</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/Transporter-offer.png" alt="app img"/>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <h5>By Invitation</h5>
                                <p>If you want to invite a particular Transporter to transport your Order, this Transporter needs to register in the GOFORIT FOR TRANSPORTERS mobile app. It&rsquo;s free and the registration is very easy. You can share these links with your Transporters:</p>
                                <ul>
                                  <li>For iPhone: <a href="https://apps.apple.com/us/app/goforit-carrier/id1530917252">link</a></li>
                                  <li>For Android: <a href="https://play.google.com/store/apps/details?id=com.goforit&amp;pcampaignid=web_share">link</a></li>
                                </ul>
                                <p>To add the Transporter go to the <strong>Transporters</strong> tab (Mark 1).</p>
                                <p>1) On the Search field (Mark 2) Start typing your transporter&rsquo;s name (or company name).</p>
                                <p>2) The dynamic search will show you the list of registered Transporters who match the search criteria.</p>
                                <p>3) Locate your Transporter in the list (Mark 3)</p>
                                <p>4) And add him/her to the list of your favorite Transporters by pressing the<strong> Add to list</strong> button for easy access in the future.</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/Adding-transporter.png" alt="app img"/>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>Now, when you go to the <strong>Transporters</strong> tab, you will see the Transporter that you just added to your <strong>Favorite List</strong> (Mark 5) displayed and you can offer him/her to transport your Order.</p>
                                <p>Press the<strong> Offer load</strong> link (Mark 6) to invite your Transporter to the particular Order (Load).</p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/list-of-transporters.png" alt="app img"/>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <p>5) On the pop-up window, locate your Order (Mark 7)</p>
                                <p>6) Press <strong>Send invite</strong> button (Mark 8). The notification will be sent to your Transporter.</p>
                                <p>
                                  As soon as your Transporter accepts your request, he/she will be automatically assigned to your Order (Load) and you will receive a notification.
                                </p>
                                <p>
                                  Now your Order is ready to be Picked Up by your Transporter. You can track the location of your Transporter on the map on the<strong> My Orders</strong> tab. When your Order is picked up, you will be also able to see its location in real-time.
                                </p>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/send-invite-to-transporter.png" alt="app img"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header" onClick={() => this.handleHeaderClick("#collapseExample5")}>
                          <div className="help-item-title">Change password, phone, or address</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample5"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample5">
                          <div className="card custom-card-help">
                            <div className="card-body">
                              <div className="custom-card-help-left">
                                <ol>
                                  <li>Go to <strong>Settings</strong> (Mark 1)</li>
                                  <li>If you need to change an email, type a new email in the field (Mark 2) and press <strong>Confirm and change </strong>(Mark 3). Don&rsquo;t forget to confirm your email address in your email box.</li>
                                  <li>You can also change the address (Mark 4). Don&rsquo;t forget to press <strong>Save changes</strong> (Mark 5)</li>
                                  <li>If you wish to change your password (Mark 6), just type your new password (and confirm password) and press Save changes (Mark 7)</li>
                                </ol>
                              </div>
                              <div className="custom-card-help-right-expanded">
                                <img src="assets/images/settings.png" alt="app img"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Help);
