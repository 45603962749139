export const sortByStatus = loads => {
  let loadsArray = [];
  loads.forEach(load =>
      load.driver_id || load.dispatcher_id
          ? loadsArray.unshift(load)
          : loadsArray.push(load)
  );

  return loadsArray;
};
