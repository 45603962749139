import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from 'qs';
//import auth_key from '../utils/uresKey';

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);


export default class TransportersAPI {

  static async GetGeo(options = {}) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/transporters/geo`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      if(error.request.status !== 200) {
        console.log('error',error)
        return {}
      }
    });
  }

  static async GetTransportersData(pagination) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/my-driver`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async GetMyTransportersData(pagination) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';
    return basicAxios.get(`/dispatchers/my-driver-active`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async SearchTransporters(pagination) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/transporters/search`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async CreateTransporter(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/dealers` ,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
        console.log('error',error)
    });
  }

  static async UpdateTransporters(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/dealers/${data.id}` ,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async AddBlackListTransporters(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/transporters/add-blacklist/${id}`, qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response.status
    }).catch((error) => {
      console.log('error',error)

      return error.status
    });
  }

  static async ChangeStatusOfTransporters(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/dispatchers/switch-transporter-status`, qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response.status
    }).catch((error) => {
        console.log('error',error)

      return error.status
    });
  }

  static async SaveTransporterComment(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/dispatchers/comment/${data.driver}`, qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async DeleteTransporterComment(id, is_my_driver_comment = false) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.delete(`/dispatchers/comment/${id}`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      },
      data: {
        ...(is_my_driver_comment ? { is_my_driver_comment } : {})
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async SendInviteToTransporter(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/invites/invite-transporter-by-dispatcher/${id}`,qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async UnfollowTransporter(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/transporters/remove-favourite/${id}`, qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error
    });
  }

  static async FollowTransporter(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/transporters/add-favourite/${id}`, qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error
    });
  }

  static async UnlinkTransporter(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/invites/unlink-transporter/${id}`, qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error
    });
  }

  static async RejectInviteTransporter(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    //return basicAxios.post(`/invites/reject-invitation/${id}`, qs.stringify(id),{
    return basicAxios.post(`/invites/delete-invitation-by-dispatcher/${id}`, qs.stringify(id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key,
        transporter_id: id
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error
    });
  }

  static async GetTransportersStatWithLoad(pagination) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    //dispatchers/my-driver-with-load

    return basicAxios.get(`/dispatchers/my-driver-with-load`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async AssignToTransporter(load_id, driver_id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/opportunities/${load_id}/create-offer-by-owner/${driver_id}`, qs.stringify(driver_id),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }
}


