import config from "../config";
import axios from "axios";
import CryptoJS from "crypto-js";

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

const params = () => {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : ''
    return {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
    }
}

const SUCCESS_MESSAGE = "Your offer was sent successfully";
const ERROR_MESSAGE = "You have already created offer";

export default class OpportunitiesAPI {
    static getOffers(load_id) {
        return basicAxios.get(`/opportunities/${load_id}/offers`,  {
            params: {
                ...params(),
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static async confirmOfferByOwner (offer_id, _showNotification, MESSAGE) {
        return basicAxios.put(`/opportunities/${offer_id}/confirm-offer-by-owner`, null,{
            params: {
                ...params(),
            }
        }).then((response) => {
            _showNotification('success', MESSAGE)
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static rejectOfferByOwner (offer_id) {
        return basicAxios.put(`/opportunities/${offer_id}/reject-offer-by-owner`, null,{
            params: {
                ...params(),
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static async confirmOfferByDriver (offer_id) {
        return basicAxios.put(`/opportunities/${offer_id}/confirm-offer-by-driver`, null,{
            params: {
                ...params(),
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static async rejectOfferByDriver (offer_id) {
        return basicAxios.put(`/opportunities/${offer_id}/reject-offer-by-driver`, null,{
            params: {
                ...params(),
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static async createOfferByOwner (load_id, driver_id, _showNotification) {
        return basicAxios.put(`/opportunities/${load_id}/create-offer-by-owner/${driver_id}`, null,{
            params: {
                ...params(),
            }
        }).then((response) => {
            _showNotification('success', SUCCESS_MESSAGE)
            return response.data
        }).catch((error) => {
            _showNotification('error', ERROR_MESSAGE)
            throw error
        })
    }

    static async deleteOffer (offer_id) {
        return basicAxios.delete(`/opportunities/${offer_id}`, {
            params: {
                ...params(),
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            throw error
        })
    }

    static getOffer(load_id) {
        return basicAxios.get(`/opportunities/${load_id}/offers`,  {
          params: {
            ...params(),
          }
        }).then((response) => {
          return response.data
        }).catch((error) => {
          throw error
        })
    }
}
