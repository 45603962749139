import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';

import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import SubscriptionAPI, { SUBSCRIPTION_DAYS_MONTH, SUBSCRIPTION_PRICE } from '../../../api/subscriptionAPI'

import './style.css';
import config from "../../../config";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class Subscription extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      errorMessage: '',
      driversCount: 1, // was 0
      currentDrivers: 0,
      price: SUBSCRIPTION_PRICE,
      subscribeStatus: 'new',
      subscriptionStart: '',
      subscriptionEnd: '',
      upgradePrice: '',
      warningMsg: '',
      disabled: false,
      buttonPayStatus: ''
    };
  }

  async componentDidMount() {
    const data = await SubscriptionAPI.GetSubscribeData();

    if (data.data.status === 200) {
      const dayOff = Date.now() / 1000 - new Date(data.data.data.current_period_start).getTime();
      const daySubscriveLeft = Math.ceil(dayOff / (60 * 60 * 24));

      this.setState({
        driversCount: data.data.data.current_amount,
        currentDrivers: data.data.data.current_amount,
        subscribeStatus: 'old',
        upgradeDate: daySubscriveLeft,
        subscriptionStart: data.data.data.current_period_start_date,
        subscriptionEnd: data.data.data.current_period_end_date,
        errorMsg: '',
        buttonPayStatus: ''
      })
    } else {
      if (data.data.status === 500 && data.data.data) {
        const dayOff = Date.now() / 1000 - new Date(data.data.data.current_period_start).getTime();
        const daySubscriveLeft = Math.ceil(dayOff / (60 * 60 * 24));

        this.setState({
          driversCount: data.data.data.current_amount,
          currentDrivers: data.data.data.current_amount,
          subscribeStatus: 'old',
          upgradeDate: daySubscriveLeft,
          subscriptionStart: data.data.data.current_period_start_date,
          subscriptionEnd: data.data.data.current_period_end_date,
          errorMsg: '',
          warningMsg: 'Your current subscription was paused due to non-payment',
          disabled: true,
          buttonPayStatus: ''
        })
      } else {
        this.setState({
          subscribeStatus: 'new',
          buttonPayStatus: ''
        })
      }
    }
  }

  // ERROR
  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  // CLICK ON POPUP BTN PAY
  handleSubmit = async (evt) => {
    evt.preventDefault();
    const { driversCount, subscribeStatus } = this.state
    this.setState({ buttonPayStatus: 'disabled' } )

    if (this.props.stripe) {

      const timeOut = (subscribeStatus, warningMsg = null) => {
        clearTimeout(this.timeOutPopup)
        this.timeOutPopup = setTimeout(() => this.setState({
          popupOpen: false,
          driversCount,
          subscribeStatus: subscribeStatus,
          paymentStatus: '',
          buttonPayStatus: '',
          ...( warningMsg || warningMsg === '' ? { warningMsg } : {} )
        }), 2500)
      }

      if (subscribeStatus === 'new') {
        const data = await this.props.stripe.createToken()
        if (data.token) {
          await SubscriptionAPI.Subscribe({token: data.token.id, amount: driversCount}).then((result) => {
            const {data} = result;
            if (result.status === 200) {
              if (data.status !== 500) {
                this.setState({paymentStatus: 'success', warningMsg: ''})
                timeOut('old')
              } else {
                this.setState({
                  paymentStatus: 'rejected',
                  errorMsg: result.message,
                  warningMsg: 'The transaction is pending, contact your financial institution to finalize payment',
                  disabled: true,
                });
                timeOut('old')
              }
            } else {
              this.setState({
                paymentStatus: 'rejected',
                errorMsg: result.message,
                warningMsg: 'The transaction did not go through'
              });
              timeOut('new', '')
            }
          }, (error) => {
            this.setState({paymentStatus: 'rejected', errorMsg: error.response})
            timeOut('new')
          })
        } else {
          console.log("Error");
          this.setState({paymentStatus: 'rejected', buttonPayStatus: ''})
        }
      } else if (subscribeStatus === 'old') {
        await SubscriptionAPI.UpdateSubscribe({amount: driversCount}).then((result) => {
          const {data, status, message} = result;
          if (status === 200 && data.status !== 500) {
            this.setState({paymentStatus: 'success'})
            timeOut('old')
          } else {
            this.setState({paymentStatus: 'rejected', errorMsg: message});
            timeOut('new')
          }
        }, () => {
          this.setState({paymentStatus: 'rejected'});
          timeOut('old')
        })
      } else if (subscribeStatus === 'cancel') {
        console.log(1111)
        await SubscriptionAPI.DeleteSubscribe().then((result) => {
          const status = result ? 'success' : 'rejected';
          this.setState({paymentStatus: status, warningMsg: ''});
          timeOut('old')
        })
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.")
      this.setState({ buttonPayStatus: '' })
    }
  };

  submitHandler = () => {
    const {driversCount} = this.state;

    if (+driversCount === 0) {
      this.setState({
        popupOpen: true,
        subscribeStatus: 'cancel',
        buttonPayStatus: ''
      })
    }
    else {
      this.setState({
        popupOpen: true,
        buttonPayStatus: ''
      })
    }
  };

  driversDecrement = () => {
    const {driversCount, currentDrivers, upgradeDate, price, disabled} = this.state;

    if (disabled) return;

    const countedPrice = (currentDrivers * price) - currentDrivers * (price / SUBSCRIPTION_DAYS_MONTH) * upgradeDate;
    const upgradePrice = (price * driversCount) - countedPrice;

    driversCount > 0 ?
      this.setState({driversCount: this.state.driversCount - 1,upgradePrice}) :
      this.setState({driversCount: 0,upgradePrice})
  };

  driversIncrement = () => {
    const {driversCount, currentDrivers, upgradeDate, price, disabled} = this.state;

    if (disabled) return;

    const countedPrice = (currentDrivers * price) - currentDrivers * (price / SUBSCRIPTION_DAYS_MONTH) * upgradeDate;
    const upgradePrice = (price * driversCount) - countedPrice;
    this.setState({
      driversCount: +this.state.driversCount + 1 > 10000 ? 10000 : +this.state.driversCount + 1,
      upgradePrice
    })
  };

  cancelSubscription = () => {
    this.setState({
      popupOpen: true,
      subscribeStatus: 'cancel'
    })
  };

  changeDrivers = (e) =>{

    const {driversCount, currentDrivers, upgradeDate, price} = this.state;
    const countedPrice = (currentDrivers * price) - currentDrivers * (price / SUBSCRIPTION_DAYS_MONTH) * upgradeDate;
    const upgradePrice = (price * driversCount) - countedPrice;

    let { value } = e.target;
    let numberPattern = /\d+/g ;

    if(!value.length || value === '0') {
      value = '0'
    } else {
      value = +value.match(numberPattern)[0]
    }

    this.setState({
      driversCount: e.target.value > 10000 ? 10000 : value,
      upgradePrice
    })
  };


  render() {
    const {
      popupOpen,
      driversCount,
      currentDrivers,
      price,
      subscribeStatus,
      subscriptionEnd,
      upgradePrice,
      paymentStatus,
      errorMsg,
      warningMsg,
      disabled
    } = this.state;

    const dateEnd = (subscriptionEnd + '').split(' ')
    const subscriptionEndFormated = dateEnd[0]
    const subscriptionDate = new Date()
    subscriptionDate.setDate(new Date().getDate() + SUBSCRIPTION_DAYS_MONTH)
    const pickUpDate = new Date(subscriptionDate)
    const pickUpDateYear = subscriptionDate.getFullYear()
    const pickUpDateMonth = pickUpDate.getMonth() + 1 >= 1 && pickUpDate.getMonth() + 1 < 10 ? `0${pickUpDate.getMonth() + 1}` : pickUpDate.getMonth() + 1;
    const pickUpDateDay = pickUpDate.getDate() >= 1 && pickUpDate.getDate() < 10 ? `0${pickUpDate.getDate()}` : pickUpDate.getDate();

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'subscription'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Subscription</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Subscription</li>
                    </ol>
                  </div>
                </div>
                <div className="dashboard-content">

                  <div className="subscription-info">
                    <p>
                      As a first-time user you are automatically granted with {SUBSCRIPTION_DAYS_MONTH} days free trial period after which payment is required to keep service.
                      Payment is securely processed through the safe online payment processing system STRIPE.
                      Our license program enables dealers to invite transporters to your loads and track your transportations.
                      Dealers can add any number of transporters to the purchased plan. The monthly subscription fee for the dealer is <span>${SUBSCRIPTION_PRICE}/month</span>.
                      As of now, you can subscribe for up to two months of services.
                    </p>
                    <div className="subscription-info-cancel"
                         onClick={this.cancelSubscription}>Cancel payments
                    </div>
                  </div>


                  <div className="user-plan">
                    <div className="user-plan-row">
                      <div className="user-plan-col">
                        <div className="user-plan-info">
                          <h4>Our flexible plans</h4>
                          <p>
                            Dealer license period - 1 month. <br/>
                            Pay only ${SUBSCRIPTION_PRICE}/mon and have unlimited transportation every month.*
                          </p>
                          <ul className="user-plan-info-list">
                            <li><i className="fas fa-check"/>Create unlimited loads quantity</li>
                            <li><i className="fas fa-check"/>Assign loads to your drivers</li>
                            <li><i className="fas fa-check"/>See status of your loads in real time</li>
                          </ul>

                          <div className="user-plan-info-drivers">
                            {
                              // <p>Purchase your subscription (months)</p>
                            }
                            <div className="user-plan-info-drivers-count">
                              { /*
                              <div className="input-group">
                                <div
                                  className="input-group-append count-minus"
                                  style={disabled ? {cursor: 'default'} : {}}
                                  onClick={this.driversDecrement}>
                                  <span className="input-group-text">
                                    <i className="mdi mdi-minus"/>
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  min="0"
                                  className="form-control"
                                  maxLength="10000"
                                  disabled={disabled}
                                  onChange={(e)=>this.changeDrivers(e)}
                                  value={driversCount}/>
                                <span className='hideControl'/>
                                <div
                                  className="input-group-append count-plus"
                                  style={disabled ? {cursor: 'default'} : {}}
                                  onClick={this.driversIncrement}>
                                  <span className="input-group-text">
                                    <i className="mdi mdi-plus"/>
                                  </span>
                                </div>
                              </div>
                              */ }
                              {currentDrivers === +driversCount ?
                                <button
                                  className="btn custom-btn custom-btn-blocked"
                                  disabled={disabled}
                                  style={{marginLeft: '10px'}}>Upgrade</button> : null}
                              {currentDrivers < +driversCount ?
                                <button
                                  onClick={this.submitHandler}
                                  disabled={disabled}
                                  className="btn custom-btn"
                                  style={{marginLeft: '10px'}}>Upgrade</button> : null}
                              {currentDrivers > +driversCount ?
                                <button
                                  onClick={this.submitHandler}
                                  disabled={disabled}
                                  className="btn custom-btn"
                                  style={{marginLeft: '10px'}}>Downgrade</button> : null}
                            </div>
                          </div>
                          {warningMsg && subscribeStatus === 'old' &&
                          (<div className="user-plan-info-sup error">{warningMsg}</div>)
                          }
                          <div className="user-plan-info-sup">
                            *All sales are final. We do not provide refunds for already paid licenses.
                            You can cancel your subscription plan anytime. However, your license remains active till the end of the billing period that you already paid for.
                          </div>
                        </div>
                      </div>
                      <div className="user-plan-col">
                        <div className="user-plan-view">
                          <div className="user-plan-view-title">GOFORIT SUBSCRIPTION</div>
                          <ul className="user-plan-view-list">
                            <li><p>Your monthly payment (${SUBSCRIPTION_PRICE} x {driversCount} month)</p><span>${driversCount * price}</span></li>
                            <li><p>Tax</p><span>$0</span></li>
                            <li><p>TOTAL</p><span>${currentDrivers >= driversCount ? 0 : upgradePrice || driversCount * price}</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-40 text-center font-14">
                    <p>Have a question? <a href='http://goforitapp.com/support/' className="font-500"> Support</a></p>
                  </div>
                </div>
              </div>
            </div>

            {popupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Payment</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                    </div>
                    <div className="modal-body">

                      <div className="stripeForm">
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                          {subscribeStatus === 'new' && (<label>
                            <CardElement
                              onChange={this.handleChange}
                              {...createOptions()}
                            />
                          </label>)}

                          <div className="error" role="alert">
                            {this.state.errorMessage}
                          </div>

                          {subscribeStatus === 'new' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are purchasing a license for </p>
                                  <span><b>{1}</b> month</span>
                                </div>
                                <div className="payment-content-item">
                                  <p>The license will be valid until</p>
                                  <span><b> {`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b></span>
                                </div>
                                <div className="payment-content-item">
                                  <p>Your monthly payment is</p>
                                  <span><b>${driversCount * SUBSCRIPTION_PRICE}</b></span>
                                </div>
                                <div className="payment-content-footer">
                                  <p>This subscription will automatically renew each month. You can cancel it at any time.</p>
                                  <button disabled={this.state.buttonPayStatus} className={this.state.buttonPayStatus === 'disabled' ? 'custom-btn-blocked' : ''}>Pay</button>
                                </div>
                              </div>
                              {warningMsg &&
                              (<div className="error" role="alert">{warningMsg}</div>)
                              }
                              {/*<p className="stripeFormText">*/}
                              {/*You are purchasing licenses for <b>{driversCount}</b> drivers.*/}
                              {/*This license will be valid until<b> {`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b>.*/}
                              {/*Your monthly payment is <b>{driversCount * SUBSCRIPTION_PRICE}$</b>. You can buy additional licenses whenever you need by*/}
                              {/*</p>*/}
                              {/*<button>Pay</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your payment was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your payment was unsuccessful</div>)
                              }
                            </React.Fragment>
                          )}

                          {/*upgrade*/}
                          {subscribeStatus === 'old' && driversCount > currentDrivers ?
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>Your are purchasing an additional license for </p>
                                  <span><b>{driversCount}</b> driver/s</span>
                                </div>
                                <div className="payment-content-item">
                                  <p>The license will be valid until</p>
                                  <span><b>{`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b></span>
                                </div>
                                <div className="payment-content-item">
                                  <p>Your today’s payment is</p>
                                  <span><b>{upgradePrice || driversCount * price}$</b></span>
                                </div>
                                <div className="payment-content-footer">
                                  <p>Your monthly payment will be calculated based on the total amount of licenses</p>
                                  <button disabled={this.state.buttonPayStatus} className={this.state.buttonPayStatus === 'disabled' ? 'custom-btn-blocked' : ''}>Pay</button>
                                </div>
                              </div>

                              {/*<p className="stripeFormText">*/}
                              {/*You are purchasing an additional license for <b>{driversCount}</b> driver/s.*/}
                              {/*The license will be valid until <b>{subscriptionEndFormated}</b>.*/}
                              {/*Your today’s payment is <b>{upgradePrice}$</b>.*/}
                              {/*Your monthly payment will be calculated based on the total amount of licenses.*/}
                              {/*</p>*/}
                              {/*<button>Pay</button>*/}

                              {warningMsg &&
                              (<div className="error" role="alert">{warningMsg}</div>)
                              }

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your change was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your change was unsuccessful</div>)
                              }

                            </React.Fragment>
                            : ''}

                          {/*downgrade*/}
                          {subscribeStatus === 'old' && driversCount !== 0 && driversCount < currentDrivers && (
                            <React.Fragment>
                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are downgrading the license by decreasing the number of available drivers in your account.
                                    The plan will be updated on <span><b>{subscriptionEndFormated}</b></span> automatically,
                                    you can use your current amount of drivers until the end of the billing period</p>

                                </div>
                                <div className="payment-content-footer">
                                  {/*<p>Your monthly payment will be calculated based on the total amount of licenses</p>*/}
                                  <button>Confirm</button>
                                </div>
                              </div>


                              {/*<p className="stripeFormText">*/}
                              {/*You are downgrading the license by decreasing the number of available drivers in your account.*/}
                              {/*The plan will be updated on <b>{subscriptionEndFormated}</b> automatically,*/}
                              {/*you can use your current amount of drivers until the end of the billing period.*/}
                              {/*</p>*/}
                              {/*<button>Confirm</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your change was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">{errorMsg} Your change was unsuccessful</div>)
                              }

                            </React.Fragment>)}

                          {/*Cancel*/}
                          {subscribeStatus === 'cancel' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are canceling the monthly subscription.
                                    Starting <span><b>{subscriptionEndFormated}</b></span> you will have no ability to
                                    activate drivers or assign loads.
                                    To be able to use the app with all the features, you will need to purchase a license</p>

                                </div>
                                <div className="payment-content-footer">
                                  {/*<p>Your monthly payment will be calculated based on the total amount of licenses</p>*/}
                                  <button>Confirm</button>
                                </div>
                              </div>

                              {/*<p className="stripeFormText">*/}
                              {/*You are canceling the monthly subscription.*/}
                              {/*Starting <b>{subscriptionEndFormated}</b> you will have no ability to activate drivers or assign loads.*/}
                              {/*To be able to use the app with all the features, you will need to purchase a license.*/}
                              {/*</p>*/}
                              {/*<button>Confirm</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your cancel was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your cancel was unsuccessful</div>)
                              }

                            </React.Fragment>)}

                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>) : null}
          </div>
        </main>
      </AUX>
    );
  }
}


const SubscriptionInjected = injectStripe(Subscription)


const Headline = () => {

  return (
    <div>
      <StripeProvider apiKey={config.stripeAPI}>
        <Elements>
          <SubscriptionInjected/>
        </Elements>
      </StripeProvider>
    </div>
  )

};
export default Headline;
