import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { openHowToUseTutorial, openCarrierApplicationAndroid, openCarrierApplicationIos } from "../../../utils/LinkUtils";
import "./style.css";

class SpeechBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatcherProStatus: undefined,
      awaitForRender: false,
      popupOpen: true,
      subscriptionStatus: "",
    };
  }

  async componentDidMount() {}

  render() {
    const { popupOpen } = this.state;
    const { speechMode } = this.props;

    return (
      <AUX>
        {this.props.enabled ? (
          <div
          className={
            popupOpen
              ? "speech-bubble_" + speechMode + " speech-bubble-expand"
              : "speech-bubble_" + speechMode + " speech-bubble-shrink"
          }
        >
          <button
            className="button-speech-bubble"
            name="button-speech-bubble"
            title="Close"
            aria-label="button-speech-bubble"
            onClick={this.onClose}
          >
            <i className="mdi dripicons-cross" />
          </button>
          {speechMode === "postYourCar" && (
            <div className="speech-content">
              <h2 className="speech-title">
                Using GoForIt for the first time?
              </h2>
              <h5 className="speech-text">
                <Link to="/load" className="active speech-link">
                  <span> Post your car </span>
                </Link>
                by tapping vehicle information or upload auction pickup slip/gate pass.
              </h5>
              <h5 className="speech-text">
                Or check our{" "}
                <button
                  className="howtouse-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with GoForIt for Shippers"
                  aria-label="button-speech-bubble"
                  onClick={() => openHowToUseTutorial()}
                >
                  How To Use page
                </button>{" "}
                to learn more about GOFORIT for Shippers.
              </h5>
            </div>
          )}
          {speechMode === "showUploadPdf" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Upload PDF of Auction pick up release or gate pass to create transport order instantly.
              </h5>
            </div>
          )}
          {speechMode === "showAdjustDates" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Make sure to adjust pick up and delivery dates correctly.
              </h5>
            </div>
          )}
          {speechMode === "showDesirableRate" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Indicate desirable transportation cost. Note you might receive higher offers form carriers.
              </h5>
            </div>
          )}
          {speechMode === "showAddVehicle" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Add vehicle manually or it will be automatically imported from PDF release, Indicate if you Prefer enclosed trailer.
              </h5>
            </div>
          )}
          {speechMode === "showAttachExtras" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Indicate important instructions or upload vehicle release form. Note this will not be visible for carriers until you approve the offer.
              </h5>
            </div>
          )}
          {speechMode === "showCorrectAddress" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Make sure pick up and delivery addresses are correct.
              </h5>
            </div>
          )}
          {speechMode === "showAddPaymentTerms" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Add payment terms. Note if you are a dealer you can only indicated COD or COP orders.
              </h5>
            </div>
          )}
          {speechMode === "findTransporter" && (
            <div className="speech-content">
              <h5 className="speech-text">
                On this page you will see all the Transporters that you work with. 
                If you need to offer one of your Orders to particular Transporter, he/she needs to be registered in GOFORIT for Transporters app. 
                Then you will be able to find him/her through our Search field.
              </h5>
              <h5 className="speech-text">
                This is the links for our GOFORIT for Transporters app:{" "}
                <button
                  className="howtouse-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with GoForIt for Shippers"
                  aria-label="button-speech-bubble"
                  onClick={() => openCarrierApplicationAndroid()}
                ><i className="mdi mdi-android" /></button>{" "}
                or
                <button
                  className="howtouse-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with GoForIt for Shippers"
                  aria-label="button-speech-bubble"
                  onClick={() => openCarrierApplicationIos()}
                ><i className="mdi mdi-apple" /></button>.
              </h5>
              <h5 className="speech-text">
                If you don’t have any Transporters you know, you can choose from GOFORIT transporters in your region. You can do that on a Active Orders screen by choosing Transporter on the map.
              </h5>
              <h5 className="speech-text">
              In case of questions please learn more on our<button
                  className="howtouse-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with GoForIt for Shippers"
                  aria-label="button-speech-bubble"
                  onClick={() => openHowToUseTutorial()}
                >How-to-use page</button>.
              </h5>
            </div>
          )}
          {speechMode === "loadCreated" && (
            <div className="speech-content">
              <h5 className="speech-text">
                On this page you will see your orders and available Transporters near your Origin. You can offer your Order to some Transporter you see on the Map or Invite any Transporters on the 
                <Link to="/transporters" className="active speech-link">
                  <span> Transporters Page </span>
                </Link>.
                If you made your Order visible to everyone, you may receive invite from one of our transporters. In case of questions please use our 
                <button
                  className="howtouse-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with GoForIt for Shippers"
                  aria-label="button-speech-bubble"
                  onClick={() => openHowToUseTutorial()}
                >How-to-use page</button>.
              </h5>
            </div>
          )}
        </div>
        ) : null}
      </AUX>
    );
  }

  onClose = () => {
    const { closeCallBack } = this.props;

    this.setState(
      {
        popupOpen: false,
      },
      () => {
        if (closeCallBack) {
          closeCallBack();
        }
      }
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeechBubble);
