import UsersAPI from "../api/userApi";

export const checkSubscription = async () => {
    const userData = await UsersAPI.GetLicense()

    if(!userData.data.data){
        return false;
    }

    const status = userData.data.data.status;

    return status === 'active' || status === "trial";
}

export const isTrial = async () => {
    const userData = await UsersAPI.GetLicense()

    if(!userData.data.data){
        return false;
    }

    const status = userData.data.data.status;

    return status === "trial";
}

export const checkTrial = async () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userTrial = localStorage.getItem('userTrial') ? JSON.parse(localStorage.getItem('userTrial')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false; 

    if (today < userLimit && !userTrial.popup) {
      localStorage.setItem('userTrial', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    UsersAPI.GetUser().then((response) => { 
      if (response.data.data) {
        localStorage.setItem('userInfo', JSON.stringify(response.data.user))
      }
    });

    return isTrialMode;
}

export const showPostYourCar = () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userShowPostYourCar = localStorage.getItem('userShowPostYourCar') ? JSON.parse(localStorage.getItem('userShowPostYourCar')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false;

    if (today < userLimit && !userShowPostYourCar.popup) {
      localStorage.setItem('userShowPostYourCar', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    return isTrialMode;
}

export const showCreateLoad = () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userShowCreateLoad = localStorage.getItem('userShowCreateLoad') ? JSON.parse(localStorage.getItem('userShowCreateLoad')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false;

    if (today < userLimit && !userShowCreateLoad.popup) {
      localStorage.setItem('userShowCreateLoad', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    return isTrialMode;
}

export const showFindTransporter = () => {
  const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
  const userShowFindTransporter = localStorage.getItem('userShowFindTransporter') ? JSON.parse(localStorage.getItem('userShowFindTransporter')) : ''

  const today = Date.now()
  const userSinginDate = new Date(userData.date_add)
  const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

  let isTrialMode = false;

  if (today < userLimit && !userShowFindTransporter.popup) {
    localStorage.setItem('userShowFindTransporter', JSON.stringify({popup: true}))
    isTrialMode = true
  }

  return isTrialMode;
}

export const showLoadCreated = (numberOfLoads) => {
  const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
  const userShowLoadCreated = localStorage.getItem('userShowLoadCreated') ? JSON.parse(localStorage.getItem('userShowLoadCreated')) : ''

  const today = Date.now()
  const userSinginDate = new Date(userData.date_add)
  const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

  let isTrialMode = false;

  console.info("userShowLoadCreated", today < userLimit, !userShowLoadCreated.popup, numberOfLoads === 9, numberOfLoads)

  if (today < userLimit && !userShowLoadCreated.popup && numberOfLoads === 1) {
    localStorage.setItem('userShowLoadCreated', JSON.stringify({popup: true}))
    isTrialMode = true
  }

  return isTrialMode;
}